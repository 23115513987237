import success from "@Assets/animation/success.json";
import Button from "@Atom/Button";
import Card from "@Atom/Card";
import Modal from "@Atom/Modal";
import Images from "@Theme/Images";
import Lottie from "lottie-react";
import { useNavigate } from "react-router-dom";
import Styles from "./style.module.scss";

export default function PublishModal({ show, isLoading, isSuccess }) {
  const navigate = useNavigate();

  // const handleClose = () => {
  //   if (pathname?.includes('admin')) {
  //     navigate('/admin/class')
  //   } else {
  //     navigate('/classes/new')
  //   }
  // }

  return (
    <Modal isOpen={show}>
      <Card className={Styles.container}>
        {isSuccess ? (
          <div className={Styles.animation}>
            <Lottie
              animationData={success}
              loop={true}
              className={Styles.success}
            />
          </div>
        ) : (
          <div className={Styles.animation}>
            <video autoPlay muted loop src={Images.PUBLISH_COURSE_LOADING} />
          </div>
        )}
        <div className={Styles.text}>
          {isSuccess ? (
            <h4>
              Kelas Berhasil Di<span>publish</span>
            </h4>
          ) : (
            <h4>
              Sedang Proses Mem-<span>publish</span> Kelas
            </h4>
          )}
          {isSuccess ? (
            <p>
              Kelas telah berhasil di-<span>publish</span> dan telah aktif untuk
              digunakan
            </p>
          ) : (
            <p>
              Mohon tunggu hingga proses <span>publish</span> selesai
            </p>
          )}
        </div>

        <Button
          text={"Tutup"}
          disabled={isLoading}
          onClick={() => {
            navigate("/instructor");
          }}
        />
      </Card>
    </Modal>
  );
}
