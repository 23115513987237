import Styles from './style.module.scss'
import useWindowSize from '@Hooks/useWindowSize'

export default function SectionTabs({
  tabs = [],
  titleDenom,
  activeTabId,
  setActiveTabId,
  numberOnly = false,
  onAdd = () => { }
}) {

  //  const tabs = [
  //   {
  //     id:'',
  //   }
  //  ]

  const { width } = useWindowSize()
  return (
    <div className={Styles.container}>
      {
        tabs?.map((t, tI) => (
          <button
            key={tI}
            className={`${Styles.tab} ${t?.id === activeTabId ? Styles.active : ''}`}
            onClick={() => setActiveTabId(t?.id)}
          >
            {((!numberOnly && width > 768 && tabs?.length < 7 ) || activeTabId === t?.id) && titleDenom} {tI + 1}
          </button>
        ))
      }
      {/* <button
        className={`${Styles.tab} ${Styles.add}`}
        onClick={onAdd}
      >
        <Icon icon={'plus'} size={12} color={'#9360A8'} />
        <span> {((!numberOnly && width > 768 && tabs?.length < 11 )) && titleDenom}</span>
      </button> */}
    </div>
  )
}