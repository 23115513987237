import React, { useEffect, useState } from "react";
import Styles from "./style.module.scss";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import LoadingContent from "@Molecule/Loading";
import Button from "@Atom/Button";
import Images from "@Theme/Images";
import InputText from "@Atom/InputText";
import InputTextArea from "@Atom/InputTextArea";
import { fileBaseUrl } from "@Config/index";
import FileIcon from "@Assets/Icon/document.svg";
import LinkIcon from "@Assets/Icon/link-square-primary.svg";
import {
  updateInstructorClassProgressABP,
} from "@Services/instructor";
import FileUploadSuccess from "@Molecule/FileUploadSuccessModal";
import Modal from "@Atom/Modal";
export default function ABPDetailLayout({ data, isLoading }) {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [dataToMap, setDataToMap] = useState([]);
  useEffect(() => {
    setDataToMap(data);
  }, [data]);
  const params = useParams();
  const [successModal, setSuccessModal] = useState(false);

  //   navigate("", { state, replace: true });

  const handleChange = (index, value, name, parent) => {
    const temp = [...dataToMap];
    if (parent) {
      temp[index][parent][name] = value;
    } else {
      temp[index][name] = value;
    }

    setDataToMap(temp);
  };

  const handleSubmit = async (materialData,index) => {
    const payload = {
      feedback: materialData.feedback,
      score: materialData.materialScore,
    };

    try {
      await updateInstructorClassProgressABP(
        params.userSheetId,
        params.sessionNum,
        materialData.materialNum,
        payload
      );
      setSuccessModal(index);
      navigate("", { state, replace: true });
    } catch (error) {
      console.log(error);
      setSuccessModal(false);
    }
  };

  return (
    <div className={Styles.container}>
      <div className={Styles.backButton}>
        <Button
          onClick={() => navigate(-1)}
          text={"kembali"}
          startIcon={"chevron-left"}
          variant="text"
        />
      </div>
      <div className={Styles.header}>
        <p>Penilaian Asesmen Berbasis Proyek</p>
      </div>
      <div className={Styles.body}>
        <div className={Styles.bodyHead}>
          <p>Nama Murid</p>
          <p>{state.name}</p>
        </div>
        {isLoading ? (
          <LoadingContent />
        ) : (
          <div className={Styles.cardWrapper}>
            {dataToMap?.map((el, idx) =>
              el.answers.length ? (
                <div className={Styles.card} key={idx}>
                  <div className={Styles.cardTitle}>
                    <p>Asesmen Proyek {idx + 1}</p>
                  </div>
                  <div className={Styles.cardBody}>
                    <div className={Styles.leftSection}>
                      {el.answers.map((file, Fidx) => (
                        <div
                          className={` ${
                            el.answers.length === 1 ? Styles.contentWrapper : ""
                          } ${el.answers.length === 2 ? Styles.multiple : ""} ${
                            el.answers.length > 2 ? Styles.moreThan2 : ""
                          }`}
                          key={Fidx}
                        >
                          <div className={Styles.file}>
                            <div className={Styles.iconWrapper}>
                              <img
                                src={file.type === "URL" ? LinkIcon : FileIcon}
                                alt="file-icon"
                                className={Styles.icon}
                              />
                            </div>
                            <p>{file.value}</p>
                          </div>
                          <Button
                            variant="outlined"
                            text="Lihat File"
                            className={Styles.buttonFile}
                            onClick={() =>
                              file.type === "URL"
                                ? window.open(
                                    file.value.includes("https")
                                      ? file.value
                                      : "https://" + file.value
                                  )
                                : window.open(fileBaseUrl + file.value)
                            }
                          />
                        </div>
                      ))}
                    </div>
                    <div className={Styles.rightSection}>
                      <InputTextArea
                        labelValue="Feedback"
                        required
                        placeholder="Feedback"
                        value={el.feedback}
                        onChange={(e) =>
                          handleChange(idx, e.target.value, "feedback")
                        }
                      />
                      <div className={Styles.cardAction}>
                        <div className={Styles.score}>
                          <p>Skor</p>
                          <InputText
                            labelValue=""
                            placeholder="0-100"
                            value={
                              el.materialScore === -1 ? "" : el.materialScore
                            }
                            onChange={(e) => {
                              if (
                                +e.target.value &&
                                +e.target.value < 101 &&
                                !String(e.target.value).includes(".")
                              ) {
                                handleChange(
                                  idx,
                                  e.target.value,
                                  "materialScore"
                                );
                              } else if (e.target.value === "") {
                                handleChange(idx, "", "materialScore");
                              }
                            }}
                            // disabled={!tpmState[idx]?.isSync}
                          />
                        </div>
                        <Button
                          variant="contained"
                          text="Simpan"
                          style={{ height: "42px" }}
                          onClick={() => handleSubmit(el,idx + 1)}
                          disabled={
                            el.feedback === "" ||
                            el.materialScore === "" ||
                            el.materialScore === -1
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className={Styles.card} key={idx}>
                  <div className={Styles.cardTitle} type="empty">
                    <p>Asesmen Proyek {idx + 1}</p>
                  </div>
                  <div className={Styles.cardBodyEmpty}>
                    <img src={Images.DOC_ICON} alt="empty-icon" />
                    <p>Belum ada tugas yang dikumpulkan peserta</p>
                  </div>
                </div>
              )
            )}
          </div>
        )}
      </div>
      <Modal isOpen={successModal}>
        <FileUploadSuccess
          headText="Nilai Berhasil Disimpan"
          text={`Nilai Asesmen Berbasis Proyek ${successModal} Telah Berhasil Disimpan`}
          onClose={() => setSuccessModal(false)}
        />
      </Modal>
    </div>
  );
}
