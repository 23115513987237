import React, { useMemo, useState } from "react";
import Styles from "./style.module.scss";
import Breadcrumbs from "@Atom/Breadcrumbs";
import useQuery from "@Hooks/useQuery";
import StudentDetailHeader from "@Molecule/StudentDetailHeader";
import Button from "@Atom/Button";
import Table from "@Molecule/Table";
import { useNavigate } from "react-router-dom";

export default function AdminStudentClassDetail({ data, loading }) {
  const query = useQuery();
  const grade = query.get("grades");
  const academicYear = query.get("academicYear");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const navigate = useNavigate();

  const template = useMemo(() => {
    return {
      data: data?.usersheets
        ? data?.usersheets?.map((el) => ({
            ...el,
            id: el?.userID,
            name: el?.subjectName,
            score1: el?.scores[0]?.totalScore,
            score2: el?.scores[1]?.totalScore,
            score3: el?.scores[2]?.totalScore,
            score4: el?.scores[3]?.totalScore,
          }))
        : [],
      columns: [
        {
          name: "",
          title: "No",
          isNumber: true,
        },
        {
          name: "name",
          title: "Nama Mapel",
          width: "220px",
        },
        {
          name: "score1",
          title: "Rata-Rata Nilai Bagian 1",
          align: "right",
          renderData: (row) => <span>{row?.score1}</span>,
          width: "180px",
        },
        {
          name: "score2",
          title: "Rata-Rata Nilai Bagian 2",
          align: "right",
          renderData: (row) => <span>{row?.score2}</span>,
          width: "180px",
        },
        {
          name: "scoreSemester1",
          title: "Rata-Rata Nilai Semester 1",
          align: "right",
          renderData: (row) => <span>{row?.scoreSemester1}</span>,
          width: "180px",
        },
        {
          name: "score3",
          title: "Rata-Rata Nilai Bagian 3",
          align: "right",
          renderData: (row) => <span>{row?.score3}</span>,
          width: "180px",
        },
        {
          name: "score4",
          title: "Rata-Rata Nilai Bagian 4",
          align: "right",
          renderData: (row) => <span>{row?.score4}</span>,
          width: "180px",
        },
        {
          name: "scoreSemester2",
          title: "Rata-Rata Nilai Semester 2",
          align: "right",
          renderData: (row) => <span>{row?.scoreSemester2}</span>,
          width: "180px",
        },
        {
          name: "endScore",
          title: "Nilai Akhir",
          align: "right",
          renderData: (row) => <span>{row?.endScore}</span>,
          width: "100px",
          position: "sticky",
          right: "109px",
        },
        {
          name: "",
          title: "Nilai Perubahan",
          align: "right",
          renderData: (row) => <span>-</span>,
          width: "100px",
          position: "sticky",
          right: 0,
        },
      ],
    };
  }, [data]);

  const semester = useMemo(() => {
    return new Date(data?.period?.secondSemester.startDate) < new Date()
      ? 2
      : 1;
  }, [data?.period]);

  const disabledButton = useMemo(() => {
    if (semester === 1) {
      return !data?.submitStatus?.firstSemester;
    } else {
      return !data?.submitStatus?.secondSemester;
    }
  }, [data, semester]);

  return (
    <div className={Styles.container}>
      <Breadcrumbs
        data={[
          { title: `Daftar Siswa Tahun ${academicYear}` },
          { title: grade },
          { title: "Detail Siswa" },
        ]}
      />
      <StudentDetailHeader data={data} />
      <div className={Styles.contentHeader}>
        <p>Nilai Siswa Tahun Ajaran {academicYear}</p>
        <Button
          text={`Terbitkan Rapor Sem.${semester}`}
          startIcon={disabledButton ? "lock" : ""}
          disabled={disabledButton}
          onClick={() =>
            navigate(`/student-report/${data._id}/${academicYear}`)
          }
        />
      </div>
      <div className={Styles.tableWrapper}>
        <Table
          data={template?.data}
          totalData={5}
          totalPage={Math.ceil(5 / limit) || 0}
          columns={template?.columns}
          page={page}
          setPage={setPage}
          className={`${Styles.table}`}
          limit={limit}
          setLimit={setLimit}
          withPagination
          isLoading={loading}
          isSticky={true}
        />
      </div>
    </div>
  );
}
