import React, { useEffect, useState } from "react";
import Styles from "./styles.module.scss";
import Images from "@Theme/Images";
import MultipleChoiceCard from "@Molecule/MultipleChoiceCard";
import { userCourseStore } from "store";
import { useMemo } from "react";

export default function QuizSandbox() {
  const [questionNumber, setQuestionNumber] = useState(0);
  const [slideIn, setSlideIn] = useState(false);
  const { courseData, fetchUserSheet } = userCourseStore();
  //   console.log(store);
  const [selectedAnswer, setSelectedAnswer] = useState([]);


  useEffect(() => {
    fetchUserSheet("6721e23e7aaa45321a698028");
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dataToMap = useMemo(() => {
    return courseData?.sessions[0]?.materials[2].quizzes || [];
  }, [courseData]);


  return (
    <div className={Styles.container}>
      <div className={Styles.leftSection}>
        <div className={Styles.header}>
          <img src={Images.THS_LOGO} alt="" />
          <p>Asesmen Sumatif Teori</p>
        </div>
        <div className={Styles.questionContainer}>
          {dataToMap.map((el, idx) => (
            <div
              className={`${Styles.questionWrapper} ${
                idx === questionNumber ? Styles.show : Styles.hide
              }`}
              slideIn={String(!slideIn)}
              key={idx}
              visibility={idx === questionNumber ? "show" : "hide"}
            >
              <MultipleChoiceCard
                number={idx + 1}
                data={el}
                setSelected={(num) => {
                  const temp = [...selectedAnswer];
                  temp[idx] = num;
                  setSelectedAnswer(temp);
                }}
                selected={selectedAnswer[idx]}
              />
            </div>
          ))}
        </div>
      </div>
      <div className={Styles.rightSection}>
        <div className={Styles.questionListContainer}>
          <p>
            Soal {questionNumber + 1}/{dataToMap.length}
          </p>
          <div className={Styles.numberWrapper}>
            {Array.from("testong12312312312123123123123123121231231").map(
              (el, idx) => (
                <div
                  className={Styles.questionNumber}
                  key={idx}
                  type={
                    questionNumber === idx
                      ? "active"
                      : typeof selectedAnswer[idx] === "number" ||
                        typeof selectedAnswer[idx] === "string"
                      ? "finish"
                      : "false"
                  }
                  onClick={() => {
                    setQuestionNumber(idx);
                    setSlideIn(questionNumber > idx);
                  }}
                >
                  <p>{idx + 1}</p>
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
