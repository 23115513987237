import React, { useEffect, useMemo, useState } from "react";
import Styles from "./style.module.scss";
import DoodleGradientCard from "@Atom/DoodleGradientCard";
import { decryptStorageData } from "@Helpers/encryptDecrypt";
import { useLocation, useNavigate } from "react-router-dom";
import { getAcademicYearList } from "@Services/admin";
import Select from "@Atom/Select";
import Lottie from "lottie-react";
import loadingClass from "@Assets/animation/loadingClass.json";
import ClassImage from "@Theme/ClassImage";
import Icon from "@Atom/Icon";
import useQuery from "@Hooks/useQuery";
import ClassCard from "@Atom/ClassCard";

function filterBySchoolLevel(grades, level) {
  if (level === "SD") {
    return grades.filter((g) => g.grade >= 1 && g.grade <= 6);
  } else if (level === "SMP") {
    return grades.filter((g) => g.grade >= 7 && g.grade <= 9);
  } else if (level === "SMA") {
    return grades.filter((g) => g.grade >= 10 && g.grade <= 12);
  } else {
    return null; // Return an empty array if the level is invalid
  }
}

export default function GradeByYearList({ data, isLoading }) {
  const user = decryptStorageData("user", localStorage);
  const navigate = useNavigate();
  const query = useQuery();
  const yearsQuery = query.get("years");
  const [academicYearOption, setAcademicYearOption] = useState([]);
  const { pathname } = useLocation();
  const [academicYear, setAcademicYear] = useState("");
  const dataToShow = useMemo(() => {
    return data?.map((el) => ({
      ...el,
      gradeText: el.grade,
      grade: el.grade.split(" ").slice(-1)[0],
    }));
  }, [data]);

  useEffect(() => {
    setAcademicYear(yearsQuery || "");
  }, [yearsQuery]);

  const getAcademicYearOption = async () => {
    try {
      const res = await getAcademicYearList();
      setAcademicYearOption(res);

      setAcademicYear(yearsQuery ? yearsQuery : res[0]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAcademicYearOption();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    navigate(`${pathname}?s=1&years=${academicYear}`, {
      replace: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [academicYear]);

  return (
    <div className={Styles.container}>
      <div className={Styles.wrapper}>
        <DoodleGradientCard className={Styles.header}>
          <div className={Styles.title}>
            <h3>Selamat datang {<span>{user?.name}</span>}</h3>
            <span>Berikut adalah daftar mata pelajaran yang tersedia</span>
          </div>
          {academicYearOption.length > 0 && (
            <Select
              value={academicYear}
              setValue={setAcademicYear}
              options={academicYearOption}
              position="bottom"
              className={Styles.select}
            />
          )}
          {/* <Button
            variant="outlined-neutral"
            text={"Tambah Siswa"}
            startIcon={"add"}
            onClick={() => navigate("add")}
          /> */}
        </DoodleGradientCard>
        <section className={Styles.bodyWrapper}>
          {isLoading || yearsQuery !== academicYear ? (
            <div className={Styles.loadingContainer}>
              <Lottie animationData={loadingClass} loop={true} />
              <p>Memuat Kontent</p>
            </div>
          ) : (
            <div className={Styles.cardByScoolLevelWrapper}>
              {filterBySchoolLevel(dataToShow, "SMA").length > 0 && (
                <CardSchoolByLevel
                  data={filterBySchoolLevel(dataToShow, "SMA")}
                  title="Sekolah Menengah Atas"
                  schoolLevel="SMA"
                />
              )}
              {filterBySchoolLevel(dataToShow, "SMP").length > 0 && (
                <CardSchoolByLevel
                  data={filterBySchoolLevel(dataToShow, "SMP")}
                  title="Sekolah Menengah Pertama"
                  schoolLevel="SMP"
                />
              )}
              {filterBySchoolLevel(dataToShow, "SD").length > 0 && (
                <CardSchoolByLevel
                  data={filterBySchoolLevel(dataToShow, "SD")}
                  title="Sekolah Dasar"
                  schoolLevel="SD"
                />
              )}
            </div>
          )}
        </section>
      </div>
    </div>
  );
}

const CardSchoolByLevel = ({ data, title, schoolLevel }) => {
  const [showned, setShowned] = useState(true);
  const navigate = useNavigate();
  const query = useQuery();
  const yearsQuery = query.get("years");

  return (
    <div className={Styles.cardByScoolLevel}>
      <div className={Styles.headerCardByScoolLevel}>
        <div className={Styles.logo}>
          <img src={ClassImage(schoolLevel)} alt="" />
        </div>
        <div className={Styles.text}>
          <p>
            {title} ({schoolLevel})
          </p>
        </div>
        <div className={Styles.icon} showned={String(showned)}>
          <Icon
            icon={"arrow-up1"}
            size={24}
            onClick={() => setShowned(!showned)}
          />
        </div>
      </div>
      <div className={Styles.cardWrapper} showned={String(showned)}>
        {data.map((el, idx) => (
          <ClassCard
            onClick={() =>
              navigate(
                "classes?t=1&years=" +
                  yearsQuery +
                  "&grade=" +
                  el.gradeText +
                  "&s=1"
              )
            }
            // overlayText={el?.grade+ " " +  schoolLevel}
            overlayText={"Kelas " + el?.grade}
            label={"Jumlah Mapel"}
            value={el.classesCount + " Mata Pelajaran"}
            grade={el?.grade || 1}
          />
        ))}
      </div>
    </div>
  );
};
