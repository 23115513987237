import ClassByGradeLayout from "@Organism/StudentDashboard/ClassByGrade";
// import MyClass from "@Organism/StudentDashboard/MyClass";
import { getClassByGradeStudent } from "@Services/userCourse";
import React, { Suspense } from "react";
import { Await, defer, useLoaderData } from "react-router-dom";

export function loader({ params }) {
  const id = params.id;
  const getClassData = getClassByGradeStudent(id);

  return defer({
    getClassData,
  });
}

export default function StudentClassByGradePage() {
  const { getClassData } = useLoaderData();
  //   return <ClassByGradeLayout />;
  return (
    <Suspense fallback={<ClassByGradeLayout data={[]} isLoading />}>
      <Await
        resolve={getClassData}
        errorElement={
          <div style={{ height: "100vh" }}>
            <p>error...</p>
          </div>
        }
      >
        {(value) => <ClassByGradeLayout data={value.data} />}
      </Await>
    </Suspense>
  );
}
