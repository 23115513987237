import React, { useCallback, useEffect, useState } from "react";
import AdminDetailClass from "@Organism/ClassDetail";
import StudentListAdmin from "@Organism/StudentList";
import useQuery from "@Hooks/useQuery";
import Styles from "./style.module.scss";
import Breadcrumbs from "@Atom/Breadcrumbs";
import Button from "@Atom/Button";

export default function AdminClassDetail({ dataClass, isLoading }) {
  const [tab, setTab] = useState(1);
  const query = useQuery();
  const tabQuery = query.get("t") || 1;

  useEffect(() => {
    setTab(+tabQuery);
  }, [tabQuery]);

  const renderComponent = useCallback(() => {
    switch (tab) {
      case 1:
        return <AdminDetailClass data={dataClass} />;
      case 2:
        return <StudentListAdmin isAdmin/>;
      default:
        break;
    }
  }, [dataClass, tab]);
  return (
    <div className={Styles.container}>
      <Breadcrumbs
        data={[
          { title: "Daftar Mapel" },
          { title:"Tahun Ajaran " + dataClass?.academicYear },
          { title: dataClass?.grades },
          { title: dataClass?.subjectName },
        ]}
      />
      <div className={Styles.tabHeaderWrapper}>
        <Button
          variant="tab"
          text={"Detail Kelas"}
          isActive={tab === 1}
          onClick={() => setTab(1)}
        />
        <Button
          variant="tab"
          text={"Daftar Murid"}
          isActive={tab === 2}
          onClick={() => setTab(2)}
        />
      </div>
      {renderComponent()}
    </div>
  );
}
