import React, { useMemo } from "react";
import Styles from "./style.module.scss";
import ImgCertificateRapor from "@Assets/Images/certificate-rapor.png";
import Button from "@Atom/Button";
import IconArrowCircleRight from "@Assets/Icon/arrow-circle-right.svg";
import { userCourseStore } from "store";
import { useNavigate } from "react-router-dom";
import { fileBaseUrl } from "@Config/index";
export default function ReportLayout({ isScoreEnd = false }) {
  const { userSheetData: data } = userCourseStore();
  const isPublishedSMTI = data?.reports.smtI
  const isPublishedSMTII = data?.reports.smtII
  const navigate = useNavigate();
  const dataToShow = useMemo(() => {
    const { sessions } = data;
    return (isScoreEnd ? sessions : sessions?.slice(0, 2)) || [];
  }, [data, isScoreEnd]);

  return (
    <div className={Styles.containerLayoutReport}>
      <div className={Styles.header}>
        <div className={Styles.left}>
          <h3>{isScoreEnd ? "Nilai Akhir" : "Nilai Rata-Rata Semester 1"}</h3>
          {isScoreEnd && isPublishedSMTI && <span>Pencapaian kompetensi:</span>}
          <p>
            {isScoreEnd && !isPublishedSMTII
              ? "Nilai akhir kamu belum ada untuk sekarang ini, cek nanti lagi ya!"
              : !isScoreEnd && !isPublishedSMTI
              ? "Nilai rata-rata kamu di semester 1 belum ada untuk sekarang ini, cek nanti lagi ya!"
              : data?.competency?.[isScoreEnd ? "smtII" : "smtI"]}
          </p>
        </div>

        {(isScoreEnd && isPublishedSMTII) || (!isScoreEnd && isPublishedSMTI) ? (
          <div
            className={Styles.score}
            score={data?.average?.[isScoreEnd ? "smtII" : "smtI"]}
          />
        ) : (
          <img src={ImgCertificateRapor} alt="certificate-img" />
        )}
      </div>
      {((isScoreEnd && isPublishedSMTII) || (!isScoreEnd && isPublishedSMTI)) && (
        <div className={Styles.rapor}>
          <img src={ImgCertificateRapor} alt="certificate-img" />
          <div className={Styles.text}>
            <h4>{`Rapor Semester ${isScoreEnd ? 2 : 1}`}</h4>
            <span>
              {`Kamu sudah dapat melihat detail rapor kamu untuk semester ${
                isScoreEnd ? 2 : 1
              }`}
            </span>
          </div>
          <Button
            text={"Lihat Rapor Semester " + (isScoreEnd ? "2" : "1")}
            onClick={() =>
              window.open(fileBaseUrl + data.reports?.[isScoreEnd ? "smtII" : "smtI"])
            }
          />
        </div>
      )}

      <h4>Ringkasan Nilai Asesmen</h4>
      {dataToShow.map((s, idxSession) =>
        s.materials
          .filter((sm) => sm?.materialType !== "MATERIAL")
          .map((m, idx) => (
            <div className={Styles.classes} key={idx}>
              <div className={Styles.score}>
                <strong>{m?.materialScore < 0 ? "-" : m?.materialScore}</strong>
              </div>
              <div className={Styles.text}>
                <h4>
                  {m?.materialType === "AST"
                    ? `Asesmen Sumatif Teori ${idx + 1}  • ${"  "}  Bagian ${
                        idxSession + 1
                      }`
                    : `Asesmen Berbasis Proyek ${idx + 1}  •  Bagian ${
                        idxSession + 1
                      }`}
                </h4>
                {m?.materialType === "AST" && (
                  <span>
                    {`Pilihan Ganda:
                    ${
                      m?.quizzes?.filter((q) => q.quizType === "Pilihan Ganda")
                        ?.length
                    }
                  Soal`}
                  </span>
                )}
              </div>
              <div className={Styles.remarkAndDetail}>
                <div
                  className={`${Styles.isScores}  ${
                    m?.materialScore > 0 && Styles.alreadyScore
                  } `}
                >
                  <span>
                    {m?.materialScore > 0 ? "Dinilai" : "Menunggu Penilaian"}
                  </span>
                </div>
                <img
                  src={IconArrowCircleRight}
                  role="button"
                  alt="chevron-right"
                  onClick={() =>
                    navigate(`../chapter${idxSession + 1}-material${idx + 1}`, {
                      relative: "path",
                    })
                  }
                />
              </div>
            </div>
          ))
      )}
      <div className={Styles.buttonBetween}>
        <Button
          text={"Sebelumnya"}
          variant="outlined"
          className={Styles.btn}
          startIcon={"chevron-left"}
        />
        <Button
          text={"Kembali ke Daftar Kelas"}
          variant="outlined"
          className={Styles.btn}
          endIcon={"chevron-right"}
        />
      </div>
    </div>
  );
}
