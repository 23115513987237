import Icon from "@Atom/Icon";
import Text from "@Atom/Text";
import Images from "@Theme/Images";
import { useEffect, useMemo, useRef, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Styles from "./style.module.scss";
import useOutsideClick from "@Hooks/useOutsideClick";
import useWindowSize from "@Hooks/useWindowSize";

export default function ClassesSidebar({ userSheet, data }) {
  // const [progress, setProgress] = useState("50%");
  const { materialCode } = useParams();
  const { pathname } = useLocation();
  const path = pathname.split("/").pop();
  const { width } = useWindowSize();
  const navigate = useNavigate();

  const [sidebarData, setSidebarData] = useState([]);

  const progress = useMemo(() => {
    let percentage = Math.round(
      (data.completedMaterials / data.allMaterials) * 100
    );
    if (percentage > 100) {
      percentage = 100;
    }
    return percentage + "%";
  }, [data.completedMaterials, data.allMaterials]);

  const defaultSideBar = useMemo(() => {
    return [
      {
        title: "Class Introduction",
        child: [
          {
            title: "Aturan Kelas",
            icon: "rule",
            active: true,
            code: `rules`,
          },
        ],
      },
      {
        title: "Materi Mapel Semester 1",
        child: [
          ...data.sessions.slice(0, 2).map((c, sIndex) => ({
            title: `Bagian ${sIndex + 1} :${c.sessionDescription}`,
            icon: "folder",
            active: true,
            showned: pathname?.includes(`chapter${c.sessionNum}`)
              ? true
              : false,
            index: sIndex,
            subChild: c?.materials?.map((subChild, mIndex) => ({
              type: subChild.materialType,
              title:
                subChild.materialType !== "ABP"
                  ? subChild.materialType === "AST"
                    ? "Asesmen Teori"
                    : subChild.title
                  : "Asesmen Proyek",
              code: `chapter${c.sessionNum}-material${mIndex + 1}?t=${
                data.title
              }`,
              isComplete:
                data?.sessions[c.sessionNum - 1]?.materials[mIndex]
                  ?.isCompleted,
            })),
          })),
        ],
        additional: {
          title: "Nilai Semester 1",
          icon: "award",
          code: `report1`,
          active: true,
          isCourse: false,
        },
        isCourses: true,
      },
      {
        title: "Materi Mapel Semester 2",
        child: data.sessions.slice(2, 4).map((c, sIndex) => ({
          title: `Bagian ${sIndex + 3} :${c.sessionDescription}`,
          index: sIndex + 2,
          icon: "folder",
          active: true,
          showned: pathname?.includes(`chapter${c.sessionNum}`) ? true : false,
          subChild: c?.materials?.map((subChild, mIndex) => ({
            type: subChild.materialType,
            title:
              subChild.materialType !== "ABP"
                ? subChild.materialType === "AST"
                  ? "Asesmen Teori"
                  : subChild.title
                : "Asesmen Proyek",
            code: `chapter${c.sessionNum}-material${mIndex + 1}?t=${
              data.title
            }`,
            isComplete:
              data?.sessions[c.sessionNum - 1]?.materials[mIndex]?.isCompleted,
          })),

          // [
          //   {
          //     type: "material",
          //     title: "Testing materi",
          //     active: true,
          //   },
          //   {
          //     type: "quiz",
          //     title: "Testing kuis",
          //     active: false,
          //   },
          //   {
          //     type: "tpm",
          //     title: "Testing tpm",
          //     active: false,
          //   },
          // ],
        })),
        additional: {
          title: "Nilai Akhir",
          icon: "award",
          code: `report2`,
          active: true,
          isCourse: false,
        },
        isCourses: true,
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSheet]);

  useEffect(() => {
    setSidebarData(defaultSideBar);
  }, [defaultSideBar]);

  const [showSidebar, setShowSidebar] = useState(true);

  const handleClickSessions = (index) => {
    const temp = [...sidebarData];
    if (index < 2) {
      if (temp[1]["child"][index]["active"]) {
        temp[1]["child"][index]["showned"] =
          !temp[1]["child"][index]["showned"];
      }
    } else {
      if (temp[2]["child"][index - 2]["active"]) {
        temp[2]["child"][index - 2]["showned"] =
          !temp[2]["child"][index - 2]["showned"];
      }
    }
    setSidebarData(temp);
  };
  const boxRef = useRef();
  const boxOutsideClick = useOutsideClick(boxRef);

  useEffect(() => {
    if (boxOutsideClick && showSidebar && width <= 768) {
      setShowSidebar(false);
    }
  }, [boxOutsideClick, showSidebar, width]);

  return (
    <div className={Styles.container} ref={boxRef}>
      {width <= 768 && (
        <button
          className={Styles.showButton}
          onClick={() => setShowSidebar(!showSidebar)}
        >
          <Icon icon={showSidebar ? "cross" : "burger-icon"} size={20} />
        </button>
      )}
      <div className={Styles.sidebarContainer} is-showed={`${showSidebar}`}>
        <div className={Styles.logoSection}>
          <div className={Styles.logoWrapper} onClick={() => navigate("/")}>
            <img src={Images.THS_LOGO} alt="logo" />
          </div>

          {width <= 768 && (
            <button onClick={() => setShowSidebar(!showSidebar)}>
              <Icon icon={showSidebar ? "cross" : "burger-icon"} size={20} />
            </button>
          )}
        </div>
        <div className={Styles.progressSection}>
          <div className={Styles.progressWrapper}>
            <div className={Styles.progressComponent}>
              <div className={Styles.progressBase} />
              <div className={Styles.progress} style={{ width: progress }} />
            </div>
            <p className={Styles.progressText}>{progress}</p>
          </div>
          <div className={Styles.progressDesc}>
            <Icon icon={"book"} size={18} color={"#9360A8"} />
            <p>{`${data?.completedMaterials} / ${data?.allMaterials} Materi Selesai`}</p>
          </div>
        </div>
        <div className={Styles.mainSection}>
          {sidebarData.map((el, idx) => (
            <div className={Styles.mainWrapper} key={idx}>
              <div>
                <Text color={"#616161"} width={"medium"} size={"s"}>
                  {el.title}
                </Text>
              </div>
              {el.isCourses ? (
                <div className={Styles.childWrapper}>
                  {el.child.map((child, cIndex) => (
                    <div key={cIndex}>
                      <div
                        className={Styles.child}
                        is-selected={`${child.showned}`}
                        is-active={`${child.active}`}
                        onClick={() => handleClickSessions(child.index)}
                      >
                        <Icon
                          icon={
                            !child.subChild
                              ? child.icon
                              : child.showned
                              ? "open-folder"
                              : "folder"
                          }
                          size={18}
                        />
                        <p className={Styles.sessionsTitle}>{child.title}</p>
                        {!child.active ? (
                          <Icon
                            icon={"info-circle-2"}
                            size={16}
                            className={Styles.tooltip}
                          />
                        ) : (
                          <Icon
                            icon={"keyboard_arrow_down"}
                            size={16}
                            className={Styles.tooltip}
                            is-rotate={`${child.showned}`}
                          />
                        )}
                      </div>

                      <div
                        className={Styles.subChildWrapper}
                        is-showned={`${child.showned}`}
                      >
                        {child?.subChild?.map((subChild, sIndex) => (
                          <Link
                            className={Styles.subChild}
                            key={sIndex}
                            is-active={`${
                              materialCode === subChild.code.split("?")[0]
                            }`}
                            to={subChild.code}
                            onClick={() => {
                              width <= 768 && setShowSidebar(false);
                            }}
                          >
                            <Icon
                              icon={
                                subChild.type === "MATERIAL"
                                  ? "video"
                                  : subChild.type === "AST"
                                  ? "playlist-add-check"
                                  : "book_2"
                              }
                              size={18}
                            />
                            <div className={Styles.subChildTitle}>
                              <p>{subChild.title}</p>
                            </div>
                            {subChild.isComplete && (
                              <div className={Styles.subChildSuccessIcon}>
                                <Icon
                                  icon={"check-circle"}
                                  size={16}
                                  color={"#5D5CBD"}
                                />
                              </div>
                            )}
                          </Link>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className={Styles.childWrapper}>
                  {el.child.map((child, cIndex) => (
                    <Link
                      key={cIndex}
                      className={Styles.child}
                      is-selected={`${child.code.split("?")[0] === path}`}
                      is-active={`${child.active}`}
                      to={child.active ? child.code : "#"}
                      onClick={() => {
                        width <= 768 && setShowSidebar(false);
                      }}
                    >
                      <Icon icon={child.icon} size={18} />

                      <p>{child.title}</p>
                      {!child.active && (
                        <Icon
                          icon={"info-circle-2"}
                          size={16}
                          className={Styles.tooltip}
                        />
                      )}
                    </Link>
                  ))}
                </div>
              )}
              {el.additional && (
                <div className={Styles.childWrapper}>
                  <Link
                    className={Styles.child}
                    is-selected={`${el.additional.code.split("?")[0] === path}`}
                    is-active={`${el.child.active}`}
                    to={el.additional.active ? el.additional.code : "#"}
                    onClick={() => {
                      width <= 768 && setShowSidebar(false);
                    }}
                  >
                    <Icon icon={el.additional.icon} size={18} />

                    <p>{el.additional.title}</p>
                    {!el.additional.active && (
                      <Icon
                        icon={"info-circle-2"}
                        size={16}
                        className={Styles.tooltip}
                      />
                    )}
                  </Link>
                </div>
              )}
            </div>
          ))}
        </div>
        {width >= 768 && (
          <div
            className={Styles.chevron}
            onClick={() => setShowSidebar(!showSidebar)}
          >
            <Icon
              icon={"chevron-right"}
              size={16}
              color={"#26255B"}
              is-rotate={`${showSidebar}`}
              className={Styles.chevronIcon}
            />
          </div>
        )}
      </div>
      {width >= 768 && (
        <div className={Styles.margin} is-showed={`${showSidebar}`} />
      )}
    </div>
  );
}
