import Button from "@Atom/Button";
import FormErrorMessage from "@Atom/FormErrorMessage";
import Modal from "@Atom/Modal";
import { makeRandomString } from "@Helpers/makeRandomString";
import { omitKeys } from "@Helpers/omitKeys";
import { useDebounce } from "@Hooks/useDebounce";
import ModalSaveToDraft from "@Molecule/ModalSaveToDraft";
import Stepper from "@Molecule/Stepper";
import {
  createClass,
  getRegulations,
} from "@Services/course";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CurriculumsSection from "./Curriculums";
import DetailsSection from "./Details";
import ReviewSection from "./Review";
import RulesSection from "./Rules";
import Styles from "./style.module.scss";
import PublishModal from "@Molecule/PublishModal";
import { translateError } from "@Helpers/translateError";
import ModalError from "@Molecule/ModalError";
import moment from "moment";

function getTotalDays(startDate, endDate) {
  const start = new Date(startDate);
  const end = new Date(endDate);
  start.setHours(0, 0, 0, 0); // set start date to 00:00:00
  end.setHours(0, 0, 0, 0); // set end date to 00:00:00
  const oneDay = 1000 * 60 * 60 * 24; // milliseconds in a day
  const diffInTime = end.getTime() - start.getTime();
  const diffInDays = Math.round(diffInTime / oneDay);
  return diffInDays;
}

export default function CreataeClassWithTypeLayout({ type }) {
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState("");
  const [showExit, setShowExit] = useState(false);

  const { pathname } = useLocation();
  // const user = decryptStorageData("user", localStorage);
  const [errorSchedule, setErrorSchedule] = useState(false);

  const [showPublish, setShowPublish] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const [error, setError] = useState("");
  const [missed, setMissed] = useState("");

  useEffect(() => {
    if (missed) {
      setTimeout(() => {
        setMissed("");
      }, 5000);
    }
  }, [missed]);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError("");
      }, 5000);
    }
  }, [error, setError]);

  const handleClickStep = (newStep) => {
    setActiveStep(newStep);
  };

  const defaultDetails = localStorage?.getItem("details")
    ? JSON?.parse(localStorage?.getItem("details"))
    : {};

  const [details, setDetails] = useState({
    thumbnail: null,
    video: defaultDetails?.video || "",
    name: defaultDetails?.name || "",
    classSubject: defaultDetails?.classSubject || "",
    grade: defaultDetails?.grade || "",
    // mainObjective: defaultDetails?.mainObjective,
    description: defaultDetails?.description,
    learningGoals: defaultDetails?.learningGoals || "",
    startAcademicYear: defaultDetails?.startAcademicYear || "",
    endAcademicYear: defaultDetails?.endAcademicYear || "",
    // skipVideo: defaultDetails?.skipVideo ? true : false,
    // unorderedWatch: defaultDetails?.unorderedWatch ? true : false,
    // doQuizFromStart: defaultDetails?.doQuizFromStart ? true : false,
  });

  const defaultRules = localStorage.getItem("rule")
    ? JSON?.parse(localStorage?.getItem("rule"))
    : {
        id: "",
        selectedTemplate: "",
        content: "",
        templateList: [],
      };

  const [rule, setRule] = useState(defaultRules);

  const loadAllTemplate = async () => {
    try {
      const { data } = await getRegulations();
      setRule((prev) => ({ ...prev, templateList: data }));
    } catch (error) {
      console.log(error);
    }
  };

  const handleSaveRules = async (callback) => {
    await callback();
    loadAllTemplate();
  };

  useEffect(() => {
    loadAllTemplate();
  }, []);

  const defaultCurriculums = localStorage.getItem("curriculums")
    ? JSON?.parse(localStorage?.getItem("curriculums"))
    : {};

  const [curriculums, setCurriculums] = useState({
    schedules: defaultCurriculums?.schedules?.length
      ? defaultCurriculums?.schedules?.map((obj) => {
          return {
            ...obj,
            id: obj?.id,
            address: obj?.address,
            addressMaps: obj?.address,
            longitude: obj?.longitude,
            latitude: obj?.latitude,
            link: obj?.link,
            startDate: obj?.startDate,
            endDate: obj?.endDate,
            startTime: obj?.startTime,
            finishTime: obj?.finishTime,
          };
        })
      : [
          {
            id: 1,
            address: "",
            addressMaps: "",
            longitude: "",
            latitude: "",
            link: "",
            startDate: "",
            endDate: "",
            startTime: "",
            finishTime: "",
          },
        ],
    sessions: defaultCurriculums?.sessions?.length
      ? defaultCurriculums?.sessions?.map((obj) => {
          return {
            ...obj,
            assignmentGuide: null,
            // assignmentGuide: obj?.assignmentGuide && typeof obj?.assignmentGuide === 'string' ? dataURLtoFile(obj?.assignmentGuide, `assignmentGuide${obj?.id}`) : '',
            subjects: obj?.subjects?.length
              ? obj?.subjects?.map((s) => {
                  return {
                    ...s,
                    // file: s?.file ? dataURLtoFile(s?.file, `file${s?.id}`) : ''
                    file:
                      type === "VIDEO"
                        ? typeof s?.file === "string"
                          ? s.file
                          : ""
                        : null,
                  };
                })
              : [],
          };
        })
      : [
          {
            id: 1,
            title: "",
            description: "",
            introduction: "",
            subjects: [
              {
                file: "",
                id: "1",
                popupQuiz: { quiz: "", isCorrect: true, active: false },
                title: "",
                type: "MATTER",
              },
            ],
          },
          {
            id: 2,
            title: "",
            description: "",
            introduction: "",
            subjects: [
              {
                file: "",
                id: "1",
                popupQuiz: { quiz: "", isCorrect: true, active: false },
                title: "",
                type: "MATTER",
              },
            ],
          },
          {
            id: 3,
            title: "",
            description: "",
            introduction: "",
            subjects: [
              {
                file: "",
                id: "1",
                popupQuiz: { quiz: "", isCorrect: true, active: false },
                title: "",
                type: "MATTER",
              },
            ],
          },
          {
            id: 4,
            title: "",
            description: "",
            introduction: "",
            subjects: [
              {
                file: "",
                id: "1",
                popupQuiz: { quiz: "", isCorrect: true, active: false },
                title: "",
                type: "MATTER",
              },
            ],
          },
        ],
  });

  const [activeSessionTabId, setActiveSessionTabId] = useState("");

  const activeSessionIndex = useMemo(() => {
    const i = curriculums?.sessions?.findIndex(
      (obj) => obj?.id === activeSessionTabId
    );
    if (i !== -1) {
      return i;
    }
    return 0;
  }, [activeSessionTabId, curriculums?.sessions]);

  // const defaultQuestions = localStorage?.getItem("questions")
  //   ? JSON?.parse(localStorage?.getItem("questions"))?.map((obj) => {
  //       return {
  //         ...obj,
  //         id: obj?.id,
  //         type: obj?.type,
  //         question: obj?.question,
  //         options: obj?.options?.map((o) => {
  //           return {
  //             ...o,
  //             id: o?.id,
  //             option: o?.option,
  //             isCorrect: o?.isCorrect,
  //           };
  //         }),
  //       };
  //     })
  //   : [
  //       {
  //         id: 1,
  //         type: "PILIHAN GANDA",
  //         question: "",
  //         options: [
  //           {
  //             id: 1,
  //             option: "",
  //             isCorrect: true,
  //           },
  //           {
  //             id: 2,
  //             option: "",
  //             isCorrect: false,
  //           },
  //           {
  //             id: 3,
  //             option: "",
  //             isCorrect: false,
  //           },
  //           {
  //             id: 4,
  //             option: "",
  //             isCorrect: false,
  //           },
  //         ],
  //       },
  //       {
  //         id: 2,
  //         type: "PILIHAN GANDA",
  //         question: "",
  //         options: [
  //           {
  //             id: 1,
  //             option: "",
  //             isCorrect: true,
  //           },
  //           {
  //             id: 2,
  //             option: "",
  //             isCorrect: false,
  //           },
  //           {
  //             id: 3,
  //             option: "",
  //             isCorrect: false,
  //           },
  //           {
  //             id: 4,
  //             option: "",
  //             isCorrect: false,
  //           },
  //         ],
  //       },
  //     ];

  // const [questions, setQuestions] = useState(defaultQuestions);

  // const defaultSkillTest = localStorage?.getItem("skillTest")
  //   ? JSON.parse(localStorage.getItem("skillTest"))
  //   : {};

  // const [skillTest, setSkillTest] = useState({
  //   guide: defaultSkillTest?.guide
  //     ? dataURLtoFile(defaultSkillTest?.guide, "guide")
  //     : null,
  //   description: defaultSkillTest?.description,
  // });


  const summary = useMemo(() => {
    return {
      thumbnail: details?.thumbnail,
      orientationVideo: details?.video,
      competencyAspect: details?.competencyAspects,
      generalInfo: {
        className: details?.name,
        classCategory: details?.category,
        classType: type,
        participantType: details?.participantType,
        level: details?.level,
        instructorName: defaultDetails?.instructorName,
        instructorID: defaultDetails?.instructorID,
        language: details?.language,
        mainObjective: details?.mainObjective,
        description: details?.description,
        generalPurpose: details?.generalPurpose,
        specificPurpose: details?.specificPurpose,
        tools: details?.tools,
      },
      schedules: curriculums?.schedules,
      sessions: curriculums?.sessions,
      // questions: questions,
      // skillTest: skillTest,
      // price: price,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    curriculums?.schedules,
    curriculums?.sessions,
    defaultDetails?.instructorID,
    defaultDetails?.instructorName,
    details?.category,
    details?.description,
    details?.generalPurpose,
    details?.language,
    details?.level,
    details?.mainObjective,
    details?.name,
    details?.participantType,
    details?.specificPurpose,
    details?.thumbnail,
    details?.tools,
    details?.video,
    // questions,
    // skillTest,
    type,
    details.competencyAspects,
    rule,
  ]);

  const disableNext = useCallback(
    (stepToCheck = 0) => {
      if (isSubmitting) {
        return "Masih dalam proses penerbitan kelas";
      } else {
        switch (stepToCheck) {
          case 0:
            if (!details?.thumbnail) {
              return "Wajib melampirkan thumbnail";
            }
            if (!details?.video) {
              return "Wajib melampirkan video";
            }
            if (!details?.grade) {
              return "Level Kelas kelas wajib diisi";
            }
            if (!details?.startAcademicYear) {
              return "Tahun Ajaran wajib diisi";
            }
            if (!details.classSubject) {
              return "Kategori mata pelajaran wajib diisi";
            }
            if (!details.learningGoals) {
              return "Kategori mata pelajaran wajib diisi";
            }

            // if (!details?.generalPurpose) {
            //   return "Tujuan umum kelas wajib diisi";
            // }
            // if (
            //   !details?.specificPurpose?.filter((obj) => obj?.value)?.length
            // ) {
            //   return "Tujuan khusus kelas wajib diisi";
            // }
            // if (!details?.tools?.filter(obj => obj?.value)?.length) {
            //   return 'Tools kelas wajib diisi'
            // }

            if (details?.participantType === "PRAKERJA") {
              if (!details?.kkniTitle) {
                return "Judul KKNI kelas wajib diisi";
              }

              for (let i = 0; i < details?.competencyUnits?.length; i++) {
                const competencyUnit = details?.competencyUnits[i];
                if (!competencyUnit?.code) {
                  return "Kode unit kompetensi wajib diisi";
                }
                if (!competencyUnit?.title) {
                  return "Judul unit kompetensi wajib diisi";
                }
              }
              for (let i = 0; i < details?.competencyAspects?.length; i++) {
                const competencyAspect = details?.competencyAspects[i];
                if (!competencyAspect?.name) {
                  return "Tipe aspek kompetensi wajib diisi";
                }
                if (!competencyAspect?.description) {
                  return "Deskripsi aspek kompetensi wajib diisi";
                }
              }
            }

            for (let i = 0; i < details?.targetParticipants?.length; i++) {
              const targetParticipant = details?.targetParticipants[i];
              if (!targetParticipant?.name) {
                return "Sasaran peserta wajib diisi";
              }
            }
            return "";
          case 1:
            return !rule.content ? "Template Aturan Kelas wajib diisi" : "";
          case 2:
            if (type !== "VIDEO") {
              for (let i = 0; i < curriculums?.schedules?.length; i++) {
                const schedule = curriculums?.schedules[i];

                // if (type === "WEBINAR") {
                //   if (!schedule?.link) {
                //     return `Link zoom kelas Jadwal Batch ${i + 1} wajib diisi`;
                //   }
                // }

                if (type === "OFFLINE") {
                  if (!schedule?.address) {
                    return `Alamat kelas Jadwal Batch ${i + 1} wajib diisi`;
                  }
                  if (!schedule?.longitude || !schedule?.latitude) {
                    return `Pinpoint lokasi kelas Jadwal Batch ${
                      i + 1
                    } wajib diset`;
                  }
                }

                if (!schedule?.startDate) {
                  return `Tanggal mulai kelas Jadwal Batch ${
                    i + 1
                  } wajib diisi`;
                }
                if (!schedule?.endDate) {
                  return `Tanggal selesai kelas Jadwal Batch ${
                    i + 1
                  } wajib diisi`;
                }
                if (!schedule?.startTime) {
                  return `Jam mulai kelas Jadwal Batch ${i + 1} wajib diisi`;
                }
                if (!schedule?.finishTime) {
                  return `Jam selesai kelas Jadwal Batch ${i + 1} wajib diisi`;
                }
              }
            }

            for (let i = 0; i < curriculums?.sessions?.length; i++) {
              const session = curriculums?.sessions[i];
              if (!session?.title) {
                return `Judul Kelas Sesi ${i + 1} wajib diisi`;
              }
              if (!session?.introduction) {
                return `Video Perkenalan Kelas Sesi ${i + 1} wajib diisi`;
              }
              if (!session?.description) {
                return `Deskripsi Kelas Sesi ${i + 1} wajib diisi`;
              }

              for (let j = 0; j < session.subjects.length; j++) {
                const subject = session.subjects[j];
                if (subject.type === "ABP") {
                  if (!subject?.file) {
                    return `File ABP Sesi ${i + 1} wajib dilampirkan`;
                  }
                  if (!subject?.description) {
                    return `Deskripsi ABP Sesi ${i + 1} wajib dilampirkan`;
                  }
                }
                if (subject.type === "MATTER") {
                  if (!subject?.title) {
                    return `Judul Materi ${j + 1} Sesi ${i + 1} wajib diisi`;
                  }
                  if (!subject.file) {
                    return `Video materi Sesi ${i + 1} wajib diisi`;
                  }
                }
                if (subject.type === "QUIZ") {
                  if (!subject?.title) {
                    return `Judul AST Sesi ${i + 1} wajib diisi`;
                  }
                }

                // if (type === 'VIDEO') {
                //   if (i > 0
                //     ||
                //     (i === 0 && j > 2)
                //   ) {
                //     if (!subject?.popupQuiz?.quiz) {
                //       return `Popup Quiz Kelas Sesi ${i + 1} wajib diisi`
                //     }
                //     if (!subject?.popupQuiz?.isCorrect === '') {
                //       return `Jawaban Popup Quiz Kelas Sesi ${i + 1} wajib dipilih`
                //     }
                //   }

                // }
              }
              // if (type !== "OFFLINE" && i < 2) {
              //   if (!session?.assignmentGuide) {
              //     return `Panduan TPM Kelas Sesi ${i + 1} wajib dilampirkan`;
              //   }
              //   if (!session?.assignmentDescription) {
              //     return `Deskripsi TPM Kelas Sesi ${i + 1} wajib dilampirkan`;
              //   }
              // }
            }

            return "";
          // case 3:
          //   for (let i = 0; i < questions?.length; i++) {
          //     const question = questions[i];
          //     if (!question?.question) {
          //       return `Soal ${i + 1} tidak boleh kosong`;
          //     }

          //     for (let j = 0; j < question?.options?.length; j++) {
          //       const option = question?.options[j];
          //       if (!option?.option) {
          //         return `Opsi Kuis ${i + 1} wajib dilengkapi`;
          //       }
          //     }
          //   }
          //   return "";
          default:
            return "";
        }
        // return "";
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      curriculums?.schedules,
      curriculums?.sessions,
      details,
      isSubmitting,
      pathname,
      // price,
      // questions,
      rule,
      // skillTest?.description,
      // skillTest?.guide,
      type,
    ]
  );

  const steps = useMemo(() => {
    return [
      {
        title: "Detail Kelas",
        isCompleted: !disableNext(0),
      },
      {
        title: "Aturan Kelas",
        isCompleted: !disableNext(1),
        disabled: disableNext(0) ? true : false,
      },
      {
        title: "Kurikulum",
        isCompleted: !disableNext(2),
        disabled: disableNext(0) || disableNext(1) ? true : false,
      },
      // {
      //   title: "Bank Soal",
      //   isCompleted: !disableNext(3),
      //   disabled:
      //     disableNext(0) || disableNext(1) || disableNext(2) ? true : false,
      // },
      {
        title: "Review",
        isCompleted: false,
        disabled:
          disableNext(0) || disableNext(1) || disableNext(2) ? true : false,
      },
    ];
  }, [disableNext]);

  useEffect(() => {
    if (!activeStep) {
      setActiveStep(steps[0]?.title);
    }
  }, [activeStep, steps]);

  const activeIndex = useMemo(() => {
    return steps?.findIndex((obj) => obj?.title === activeStep);
  }, [activeStep, steps]);

  const renewDefault = useCallback(() => {
    if (!isSuccess) {
      const detailsCopy = omitKeys(
        [
          "thumbnail",
          // 'video'
        ],
        { ...details }
      );

      localStorage.setItem("details", JSON.stringify(detailsCopy));

      // rule
      localStorage.setItem("rule", JSON.stringify(rule));

      // curriculums
      const curriculumsCopy = {
        ...curriculums,
        sessions: curriculums?.sessions?.map((obj) => {
          return {
            ...omitKeys(["assignmentGuide"], obj),
            subjects: obj?.subjects?.map((sb) => {
              return omitKeys(
                [type !== "VIDEO" ? "file" : "-", "supportingFile"],
                sb
              );
            }),
          };
        }),
      };

      localStorage.setItem("curriculums", JSON.stringify(curriculumsCopy));

      // // questions
      // localStorage.setItem("questions", JSON.stringify(questions));

      // // skillTest
      // const skillTestCopy = omitKeys(["guide"], { ...skillTest });

      // localStorage.setItem("skillTest", JSON.stringify(skillTestCopy));

      // if (skillTest?.guide) {
      //   getBase64(skillTest?.guide)
      //     .then(data => skillTestCopy.guide = data)
      //     .then(() => {
      //       try {
      //         localStorage.setItem('skillTest', JSON.stringify(skillTestCopy))
      //       } catch (err) {
      //         console.log(err, 'error on saving Panduan Uji Keterampilan')
      //       }
      //     })
      // }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    curriculums,
    details,
    // price,
    // questions,
    rule.content,
    // skillTest,
    type,
    isSuccess,
    rule,
  ]);

  const [trigger, setTrigger] = useState("");
  const debouncedTrigger = useDebounce(trigger, 500);

  useEffect(() => {
    setTrigger(makeRandomString(5));
  }, [summary]);

  useEffect(() => {
    renewDefault();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedTrigger]);

  const clearStorage = () => {
    localStorage.removeItem("details");
    localStorage.removeItem("rule");
    localStorage.removeItem("curriculums");
    localStorage.removeItem("questions");
    localStorage.removeItem("skillTest");
    localStorage.removeItem("price");
  };

  // eslint-disable-next-line no-unused-vars
  const handleSend = useCallback(async () => {
    const formData = new FormData();
    formData?.append("platform", type?.toUpperCase());
    // if (type !== 'VIDEO') {
    //   formData?.append('files', details?.video)
    //   formData?.append('types', 'INTRO')
    // } else {
    // }
    formData?.append("introURL", details?.video);
    formData?.append("thumbnail", details?.thumbnail);
    formData?.append("subjectName", details?.classSubject);

    formData?.append("grades", details?.grade);
    formData?.append("generalPurpose", details?.learningGoals);
    formData?.append(
      "academicYear",
      `${details.startAcademicYear}-${details.endAcademicYear}`
    );

    // if (!details?.tools?.filter(obj => obj?.value)?.length) {
    //   formData?.append(`classDetail[learningTools][0]`, '')
    // }

    const configurations = [];
    if (details?.skipVideo) {
      configurations?.push(
        "Peserta diizinkan untuk mempercepat video pembelajaran"
      );
    }
    if (details?.unorderedWatch) {
      configurations?.push(
        "Peserta diizinkan untuk menonton video secara tidak berurutan"
      );
    }
    if (details?.doQuizFromStart) {
      configurations?.push(
        "Peserta diizinkan untuk mengerjakan kuis sebelum materi selesai"
      );
    }

    for (let i = 0; i < configurations.length; i++) {
      formData?.append(`classDetail[configurations][${i}]`, configurations[i]);
    }

    formData?.append("regulationHTML", rule.content);

    if (type !== "VIDEO") {
      for (let i = 0; i < curriculums?.schedules?.length; i++) {
        if (type === "OFFLINE") {
          formData?.append(
            `schedule[${i}][locationAddress]`,
            curriculums?.schedules[i]?.address
          );
          formData?.append(
            `schedule[${i}][long]`,
            curriculums?.schedules[i]?.longitude
          );
          formData?.append(
            `schedule[${i}][lat]`,
            curriculums?.schedules[i]?.latitude
          );
        }
        // if (type === "WEBINAR") {
        //   formData?.append(
        //     `schedule[${i}][zoomLink]`,
        //     curriculums?.schedules[i]?.link
        //   );
        // }

        formData?.append(
          `schedule[${i}][startDate]`,
          curriculums?.schedules[i]?.startDate
        );
        formData?.append(
          `schedule[${i}][endDate]`,
          curriculums?.schedules[i]?.endDate
        );
        formData?.append(
          `schedule[${i}][startHour]`,
          curriculums?.schedules[i]?.startTime
        );
        formData?.append(
          `schedule[${i}][endHour]`,
          curriculums?.schedules[i]?.finishTime
        );
      }
    }

    formData?.append("sessionCount", curriculums.sessions.length);

    for (let i = 0; i < curriculums?.sessions?.length; i++) {
      formData?.append(
        `sessions[${i}][title]`,
        curriculums?.sessions[i]?.title
      );
      formData?.append(
        `sessions[${i}][description]`,
        curriculums?.sessions[i]?.description
      );
      formData?.append(
        `sessions[${i}][videoURL]`,
        curriculums?.sessions[i]?.introduction
      );
      if (type === "WEBINAR") {
        formData?.append(
          `sessions[${i}][meetURL]`,
          curriculums?.sessions[i]?.meetURL
        );
      }

      const subjects = curriculums?.sessions[i]?.subjects;

      for (let j = 0; j < subjects.length; j++) {
        if (subjects[j].type === "QUIZ") {
          // formData?.append(`sessions[${i}][materials][${j}][type]`, "QUIZ");
          formData?.append(
            `sessions[${i}][materials][${j}][title]`,
            subjects[j]?.title
          );
          formData?.append(
            `sessions[${i}][materials][${j}][materialType]`,
            "AST"
          );

          const questions = subjects[j]?.questions;
          for (let k = 0; k < questions.length; k++) {
            formData?.append(
              `sessions[${i}][materials][${j}][quizzes][${k}][type]`,
              questions[k]?.type
            );
            formData?.append(
              `sessions[${i}][materials][${j}][quizzes][${k}][contentHTML]`,
              questions[k]?.question
            );

            if (questions[k]?.image && questions[k]?.image?.type) {
              formData?.append(
                `sessions_${i + 1}_ast_${j + 1}_quiz_${k + 1}`,
                questions[k]?.image
              );
            }
            if (questions[k]?.type === "Essay") {
              formData?.append(
                `sessions[${i}][materials][${j}][quizzes][${k}][score]`,
                questions[k].score
              );
            } else {
              const answerOptions = questions[k]?.options;
              for (let l = 0; l < answerOptions.length; l++) {
                formData?.append(
                  `sessions[${i}][materials][${j}][quizzes][${k}][options][${l}][optionValue]`,
                  answerOptions[l]?.option
                );
                if (answerOptions[l]?.image && answerOptions[l]?.image?.type) {
                  formData?.append(
                    `sessions_${i + 1}_ast_${j + 1}_quiz_${k + 1}_answer_${
                      l + 1
                    }`,
                    answerOptions[l]?.image
                  );
                }

                if (answerOptions[l]?.isCorrect) {
                  formData?.append(
                    `sessions[${i}][materials][${j}][quizzes][${k}][options][${l}][isCorrect]`,
                    answerOptions[l]?.isCorrect ? 1 : 0
                  );
                }
              }
            }
          }
        } else {
          formData?.append(
            `sessions[${i}][materials][${j}][title]`,
            subjects[j]?.title || "No Title"
          );
          if (subjects[j]?.description) {
            formData?.append(
              `sessions[${i}][materials][${j}][description]`,
              subjects[j]?.description
            );
          }

          if (subjects[j]?.popupQuiz?.active) {
            formData?.append(
              `sessions[${i}][materials][${j}][popUpQuiz][active]`,
              subjects[j]?.popupQuiz?.active
            );
            formData?.append(
              `sessions[${i}][materials][${j}][popUpQuiz][isCorrect]`,
              subjects[j]?.popupQuiz?.isCorrect
            );
            formData?.append(
              `sessions[${i}][materials][${j}][popUpQuiz][question]`,
              subjects[j]?.popupQuiz?.quiz
            );
          }

          if (subjects[j]?.file) {
            if (subjects[j]?.file?.type) {
              if (subjects[j].type === "ABP") {
                formData?.append(
                  `sessions_${i + 1}_abp_${j + 1}`,
                  subjects[j]?.file
                );
              } else {
                formData?.append(
                  `sessions_${i + 1}_material_${j + 1}`,
                  subjects[j]?.file
                );
              }
              // formData?.append("types", `S${i + 1} M${j + 1}`);
            } else {
              formData?.append(
                `sessions[${i}][materials][${j}][content][url]`,
                subjects[j]?.file
              );
            }
          }
          if (subjects[j]?.supportingFile) {
            formData?.append(
              `sessions_${i + 1}_additional_${j + 1}`,
              subjects[j]?.supportingFile
            );
            // formData?.append("types", `S${i + 1} M${j + 1} A`);
          }

          if (type === "VIDEO" && subjects[j]?.popupQuiz?.quiz) {
            formData?.append(
              `sessions[${i}][materials][${j}][popupQuizLabel]`,
              subjects[j]?.popupQuiz?.quiz
            );
            formData?.append(
              `sessions[${i}][materials][${j}][popupQuizAnswer]`,
              subjects[j]?.popupQuiz?.isCorrect || false
            );
          }
        }
        // formData?.append(
        //   `sessions[${i}][materials][${j}][type]`,
        //   "MATTER"
        // );
        // formData?.append(`sessions[${i}][materials][${j}][title]`, subjects[j]?.title)
      }
    }

    // for (let i = 0; i < questions.length; i++) {
    //   formData?.append(`questionBank[${i}][type]`, questions[i]?.type);
    //   formData?.append(`questionBank[${i}][label]`, questions[i]?.question);

    //   const answerOptions = questions[i]?.options;

    //   for (let j = 0; j < answerOptions.length; j++) {
    //     formData?.append(
    //       `questionBank[${i}][options][${j}][value]`,
    //       answerOptions[j]?.option
    //     );
    //     formData?.append(
    //       `questionBank[${i}][options][${j}][isCorrectAnswer]`,
    //       answerOptions[j]?.isCorrect
    //     );
    //   }
    // }

    try {
      setIsSubmitting(true);
      setShowPublish(true);
      await createClass(formData, type === "VIDEO");
      localStorage.removeItem("details");
      localStorage.removeItem("rule");
      localStorage.removeItem("curriculums");
      localStorage.removeItem("questions");
      localStorage.removeItem("skillTest");
      localStorage.removeItem("price");
      setIsSuccess(true);
      setIsSubmitting(false);
    } catch (err) {
      setIsSubmitting(false);
      setShowPublish(false);
      setError(
        translateError(err?.response.data.error) || "Something went wrong"
      );
      console.log(err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    curriculums?.schedules,
    curriculums?.sessions,
    details?.category,
    details?.competencyAspects,
    details?.competencyUnits,
    details?.description,
    details?.doQuizFromStart,
    details?.generalPurpose,
    details?.instructorID,
    details?.kkniTitle,
    details?.language,
    details?.level,
    details?.name,
    details?.participantType,
    details?.skipVideo,
    details?.specificPurpose,
    details?.targetParticipants,
    details?.thumbnail,
    details?.tools,
    details?.unorderedWatch,
    details?.video,
    // price,
    // questions,
    rule,
    // skillTest?.description,
    // skillTest?.guide,
    type,
    // certificate,
  ]);

  const [asyncSchedule, setAsyncSchedule] = useState([]);
  useEffect(() => {
    const findAsynchronousSessions = () => {
      let async = [];
      for (let i = 0; i < curriculums.schedules.length; i++) {
        const schedule = curriculums.schedules[i];
        const totalDays =
          getTotalDays(schedule.startDate, schedule.endDate) + 1;
        if (totalDays !== curriculums?.sessions?.length) {
          async.push({ ...schedule, batch: i + 1, totalDays });
        }
      }
      setAsyncSchedule(async);
    };
    findAsynchronousSessions();
  }, [curriculums?.schedules, curriculums?.sessions?.length]);

  const content = useMemo(() => {
    switch (activeStep) {
      case steps[0]?.title:
        return (
          <DetailsSection
            form={details}
            setForm={setDetails}
            // instructors={instructors}
            // isLoadingSync={isLoadingSync}
            type={type}
            // certificate={certificate}
            // setCertificate={setCertificate}
          />
        );
      case steps[1]?.title:
        return (
          <RulesSection
            rule={rule}
            setRule={setRule}
            handleSubmit={handleSaveRules}
          />
        );
      case steps[2]?.title:
        return (
          <CurriculumsSection
            form={curriculums}
            setForm={setCurriculums}
            type={type}
            activeSessionTabId={activeSessionTabId}
            setActiveSessionTabId={setActiveSessionTabId}
            activeIndex={activeSessionIndex}
          />
        );
      // case steps[3]?.title:
      //   return (
      //     <QuestionsSection questions={questions} setQuestions={setQuestions} />
      //   );
      case steps[3]?.title:
        return (
          <ReviewSection type={type} summary={summary} details={details} />
        );
      default:
        return <div></div>;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    activeSessionIndex,
    activeSessionTabId,
    activeStep,
    curriculums,
    details,
    // instructors,
    // isLoadingSync,
    rule,
    steps,
    summary,
    type,
    // certificate,
  ]);

  return (
    <div className={Styles.container}>
      <div className={Styles.stepperWrapper}>
        <Stepper
          arr={steps?.map((obj) => {
            return {
              ...obj,
              isActive: obj?.title === activeStep,
              onClick: () => handleClickStep(obj?.title),
            };
          })}
        />
      </div>
      <div className={Styles.contentWrapper}>
        <div>
          <Button
            variant="text"
            text={"Keluar"}
            startIcon={"chevron-left"}
            iconSize={12}
            onClick={() => setShowExit(true)}
          />
        </div>
        <div className={Styles.content}>
          {content}
          <FormErrorMessage message={missed || error} />
          <div className={Styles.contentActions}>
            <Button
              text={"Kembali"}
              variant="text"
              startIcon={"chevron-left"}
              iconSize={"10px"}
              onClick={() => {
                if (activeStep === steps[0]?.title) {
                  navigate(-1);
                } else {
                  const index = steps?.findIndex((obj) => {
                    return obj?.title === activeStep;
                  });
                  setActiveStep(steps[index - 1]?.title);
                }
              }}
            />
            <Button
              text={
                steps?.findIndex((obj) => {
                  return obj?.title === activeStep;
                }) !==
                steps?.length - 1
                  ? "Selanjutnya"
                  : isSubmitting
                  ? "Sedang menerbitkan kelas"
                  : "Terbitkan Kelas"
              }
              disabled={isSubmitting}
              endIcon={"chevron-right"}
              iconSize={"10px"}
              onClick={() => {
                if (
                  activeIndex === 2 &&
                  activeSessionIndex !== curriculums?.sessions?.length - 1
                ) {
                  if (
                    disableNext(activeIndex).includes(
                      `Sesi ${activeSessionIndex + 1}`
                    )
                  ) {
                    setMissed(disableNext(activeIndex));
                  } else {
                    setActiveSessionTabId(
                      curriculums?.sessions[activeSessionIndex + 1]?.id
                    );
                  }
                } else if (activeIndex === 2 && !disableNext(activeIndex)) {
                  if (asyncSchedule.length > 0 && type !== "VIDEO") {
                    setErrorSchedule(true);
                  } else {
                    const index = steps?.findIndex((obj) => {
                      return obj?.title === activeStep;
                    });
                    if (index !== steps?.length - 1) {
                      setActiveStep(steps[index + 1]?.title);
                    } else {
                      handleSend();
                    }
                  }
                } else if (!disableNext(activeIndex)) {
                  const index = steps?.findIndex((obj) => {
                    return obj?.title === activeStep;
                  });
                  if (index !== steps?.length - 1) {
                    setActiveStep(steps[index + 1]?.title);
                  } else {
                    handleSend();
                  }
                } else {
                  setMissed(disableNext(activeIndex));
                }
              }}
            />
          </div>
        </div>

        <div />
      </div>
      {showExit && (
        <Modal isOpen={showExit}>
          <ModalSaveToDraft
            onClickButtonRemove={() => {
              clearStorage();
              navigate(-1);
            }}
            onClickCloseModal={() => setShowExit(false)}
            OnClickSaveToDraft={() => navigate(-1)}
          />
        </Modal>
      )}

      {errorSchedule && (
        <Modal isOpen={errorSchedule}>
          <ModalError
            onClose={() => setErrorSchedule(false)}
            title={"Jumlah Tanggal dan Jumlah Batch yang Ada Tidak Sesuai"}
            content={
              <div className={Styles.errorScheduleModalContainer}>
                <p>Jumlah Sesi : {curriculums.sessions.length}</p>
                <div className={Styles.errorScheduleModalList}>
                  {asyncSchedule.map((el, idx) => (
                    <p className={Styles.errorModalList}>
                      Jumlah Tanggal{" "}
                      <span className={Styles.batch}>Batch {el.batch}</span> :{" "}
                      {el.totalDays || 0} Hari{" "}
                      <span className={Styles.date}>
                        ({moment(el.startDate).format("DD MMM YYYY")} -{" "}
                        {moment(el.endDate).format("DD MMM YYYY")})
                      </span>{" "}
                    </p>
                  ))}
                </div>
              </div>
            }
          />
        </Modal>
      )}

      {showPublish && (
        <PublishModal
          show={showPublish}
          isLoading={isSubmitting}
          isSuccess={isSuccess}
        />
      )}
    </div>
  );
}
