import AdminStudentClassDetail from "@Organism/Admin/StudentDetail";
import { fetchStudentClassDetail } from "@Services/admin";
import React, { Suspense } from "react";
import { Await, defer, useLoaderData } from "react-router-dom";

export async function loader(route) {
  const params = new URLSearchParams(route?.request?.url);
  const { studentId: id } = route.params;
  const grade = params?.get("grades") || "";
  const academicYear = params?.get("academicYear") || "";

  const getStudentDetail = fetchStudentClassDetail(academicYear, grade, id);
  return defer({
    getStudentDetail,
  });
}

export default function StudentByYearDetailPage() {
  const { getStudentDetail } = useLoaderData();
  //   console.log(getStudentDetail);

  return (
    <Suspense
      fallback={
        <AdminStudentClassDetail data={[]} isLoading />
      }
    >
      <Await
        resolve={getStudentDetail}
        errorElement={
          <div style={{ height: "100vh" }}>
            <p>error...</p>
          </div>
        }
      >
        {(value) => <AdminStudentClassDetail data={value} />}
      </Await>
    </Suspense>
  );
}
