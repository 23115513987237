import React, { useMemo, useState } from "react";
import Styles from "./style.module.scss";
import DoodleGradientCard from "@Atom/DoodleGradientCard";
import Images from "@Theme/Images";
import Button from "@Atom/Button";
import { fileBaseUrl } from "@Config/index";
import { capitalizeWords } from "@Helpers/capitallizeWords";
import iconPrakerja from "@Assets/Images/prakerja.png";
import CheckBox from "@Atom/Checkbox";
import Searchbar from "@Atom/Searchbar";
import { useNavigate } from "react-router-dom";
import RadioButton from "@Atom/RadioButton";

const filterOption = [
  {
    type: "Tipe Kelas",
    value: "platform",
    options: [
      { type: "Pembelajaran Mandiri (Video)", value: "VIDEO" },
      { type: "Kelas Webinar (Online)", value: "WEBINAR" },
      { type: "Kelas Tatap Muka (Offline)", value: "OFFLINE" },
    ],
  },
  {
    type: "Kategori Kelas",
    value: "category",
    options: [
      { type: "Semua kategori", value: "all" },
      { type: "Sales", value: "Sales" },
      { type: "IT/Software", value: "IT/Software" },
      { type: "Jurnalistik", value: "Jurnalistik" },
      { type: "Manajemen", value: "Manajemen" },
      { type: "Bisnis", value: "Bisnis" },
      { type: "Communication", value: "Communication" },
      { type: "Design", value: "Design" },
      { type: "Language", value: "Language" },
      { type: "Seni/Kerajinan", value: "Seni/Kerajinan" },
      { type: "Lifestyle", value: "Lifestyle" },
      { type: "Marketing", value: "Marketing" },
      { type: "SDM", value: "SDM" },
    ],
  },

  {
    type: "Jenis Kelas",
    value: "type",
    // options: ["GENERAL", "PRAKERJA"],
    options: [
      { type: "Reguler", value: "GENERAL" },
      { type: "Prakerja", value: "PRAKERJA" },
    ],
    isRadio: true,
  },
  {
    value: "level",
    type: "Level Kelas",
    // options: ["BEGINNER", "INTERMEDIATE", "EXPERT"],
    options: [
      { type: "Beginner", value: "BEGINNER" },
      { type: "Intermediate", value: "INTERMEDIATE" },
      { type: "Expert", value: "EXPERT" },
    ],
  },
];

// const filter = {
//   platform: [
//     "Pembelajaran Mandiri (Video)",
//     "Kelas Webinar (Online)",
//     "Kelas Tatap Muka (Offline)",
//   ],
//   category: [
//     "Semua kategori",
//     "Sales",
//     "IT/Software",
//     "Jurnalistik",
//     "Manajemen",
//     "Bisnis",
//     "Communication",
//     "Design",
//     "Language",
//     "Seni/Kerajinan",
//     "Lifestyle",
//     "Marketing",
//     "SDM",
//   ],
//   type: ["GENERAL", "PRAKERJA"],
//   level: ["BEGINNER", "INTERMEDIATE", "EXPERT"],
// };

export default function AllCoursePage({ data, isLoading = false }) {
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState({
    platform: [],
    category: [],
    type: "",
    level: [],
  });

  const handleChangeFilter = (name, value) => {
    const temp = { ...filter };
    if (temp[name].includes(value)) {
      temp[name] = temp[name].filter((el) => el !== value);
    } else {
      temp[name] = [...temp[name], value];
    }
    setFilter(temp);
  };

  const filterSearch = useMemo(() => {
    if (data) {
      return data.filter((el) =>
        el.title.toLowerCase().includes(search.toLowerCase())
      );
    }
    return [];
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const filterData = useMemo(() => {
    let dataToShow = [...filterSearch];
    if (filter.platform.length > 0) {
      dataToShow = dataToShow.filter((item) =>
        filter.platform.includes(item.platform)
      );
    }
    if (filter.category.length > 0 && !filter.category.includes("all")) {
      dataToShow = dataToShow.filter((item) =>
        filter.category.includes(item.category)
      );
    }
    if (filter.level.length > 0) {
      dataToShow = dataToShow.filter((item) =>
        filter.level.includes(item.level)
      );
    }
    if (filter.type) {
      dataToShow = dataToShow.filter((item) => item.type === filter.type);
    }
    return dataToShow;
  }, [filter, filterSearch]);

  //   const targetElement = useRef();
  //   const scrollingTop = (event) => {
  //     const elmnt = targetElement;
  //     elmnt.current.scrollIntoView({
  //       behavior: "smooth",
  //       block: "center",
  //       inline: "start",
  //     });
  //   };

  //   useEffect(() => {
  //     scrollingTop();
  //   }, [filterData]);

  return (
    <div className={Styles.container}>
      <div className={Styles.headWrapper}>
        <div className={Styles.back}>
          <Button
            text={"Kembali"}
            variant="text"
            startIcon={"chevron-left"}
            onClick={() => navigate(-1)}
          />
        </div>
        <DoodleGradientCard className={Styles.headerSection}>
          <div className={Styles.leftSection}>
            <p>Semua Kelas di Skills.id</p>
            <p>Temukan dan pilih kelasmu</p>
          </div>
          <div className={Styles.rightSection}>
            <img src={Images.MOCKUP_IMAGE} alt="" />
          </div>
        </DoodleGradientCard>
      </div>
      <div className={Styles.content}>
        <div className={Styles.contentWrapper}>
          <div className={Styles.filterSection}>
            <div className={Styles.filterHead}>
              <p>Filter Pencarianmu</p>
              <Button
                text={"Hapus Filter"}
                variant="text"
                onClick={() =>
                  setFilter({
                    platform: [],
                    category: [],
                    type: "",
                    level: [],
                  })
                }
              />
            </div>
            <div className={Styles.filterCardWrapper}>
              {filterOption.map((el, idx) => (
                <div className={Styles.filterCard} key={idx}>
                  <p>{el.type}</p>
                  <div className={Styles.filterOptions}>
                    {el.options.map((option, idx) => (
                      <div className={Styles.option} key={idx}>
                        {el?.isRadio ? (
                          <RadioButton
                            isSelected={filter[el.value] === option.value}
                            onClick={() =>
                              setFilter((prev) => ({
                                ...prev,
                                type: option.value,
                              }))
                            }
                          />
                        ) : (
                          <CheckBox
                            isChecked={filter[el.value].includes(option.value)}
                            onClick={() =>
                              handleChangeFilter(el.value, option.value)
                            }
                          />
                        )}
                        <p>{option.type}</p>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className={Styles.itemsWrapper}>
            <div className={Styles.itemHead}>
              <Searchbar value={search} setValue={setSearch} />
              <div>
                <p>Urutkan Berdasarkan :</p>
              </div>
            </div>
            {!isLoading ? (
              <DataMapping data={filterData} />
            ) : (
              <div>
                <p>loading...</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

const DataMapping = ({ data }) => {
  return (
    <div className={Styles.wrapperCard}>
      {data?.map((item, idx) => (
        <a
          href={`course/${item.title
            ?.split(" ")
            .join("-")
            .split("/")
            .join("-")}/${item._id}`}
          style={{ width: "max-content" }}
          key={idx}
        >
          <div className={Styles.card}>
            <div className={Styles.image}>
              <img
                src={
                  item.asset.thumbnail.imageURL.includes("https")
                    ? item.asset.thumbnail.imageURL
                    : fileBaseUrl + item.asset.thumbnail.imageURL
                }
                alt="thumbnail-images"
                // onError={() => setIsImageError(true)}
              />
              <div className={Styles.imgText}>
                <span>Kelas Terlaris</span>
              </div>
            </div>
            <div className={Styles.description}>
              <div className={Styles.class}>
                <div>
                  <span>
                    {capitalizeWords(item?.platform) === "Offline"
                      ? capitalizeWords("Kelas " + item?.platform)
                      : capitalizeWords(item?.platform)}
                  </span>
                </div>

                <div>
                  <img src={iconPrakerja} alt="prakerja" />
                </div>
              </div>
              <div className={Styles.title}>
                <p>{item?.title}</p>
                <p>Oleh {item?.instructor?.name}</p>
              </div>
              <div className={Styles.price}>
                <p>Rp {item?.price?.total?.toLocaleString("id")}</p>
              </div>
            </div>
          </div>
        </a>
      ))}
    </div>
  );
};
