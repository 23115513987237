import ListStudentLayout from "@Organism/Admin/ListStudent";
import { fetchAllStudent } from "@Services/admin";
import React, { Suspense } from "react";
import { Await, defer, useLoaderData } from "react-router-dom";

export async function loader(route) {
  const params = new URLSearchParams(route?.request?.url);
  const page = params?.get("page") || 1;
  const limit = params?.get("limit") || 10;
  const progress = params?.get("progress") || "";
  const report = params?.get("report") || "";
  const search = params?.get("search") || "";
  const allGrades = params?.getAll("grades");
  const getAllStudent = fetchAllStudent(
    `page=${page}&limit=${limit}&search=${search}${allGrades
      .map((el) => "&grades=" + el)
      .join("")}&sort=${
      progress === "Tertinggi" ? "DESC" : progress === "Terendah" ? "ASC" : ""
    }&progress=${
      progress === "Sudah 100%"
        ? "COMPLETED"
        : progress === "Kurang Dari 100%"
        ? "INCOMPLETED"
        : ""
    }&published=${
      report === "Diterbitkan"
        ? true
        : report === "Belum Diterbitkan"
        ? false
        : ""
    }`
  );
  return defer({
    getAllStudent,
  });
}

export default function StudentListPage() {
  const { getAllStudent } = useLoaderData();
  //   console.log(getAllStudent);

  return (
    <Suspense
      fallback={
        <ListStudentLayout dataStudent={{ data: [], totalData: 0 }} isLoading />
      }
    >
      <Await
        resolve={getAllStudent}
        errorElement={
          <div style={{ height: "100vh" }}>
            <p>error...</p>
          </div>
        }
      >
        {(value) => <ListStudentLayout dataStudent={value} />}
      </Await>
    </Suspense>
  );
}
