import Icon from "@Atom/Icon";
import useOutsideClick from "@Hooks/useOutsideClick";
import useWindowSize from "@Hooks/useWindowSize";
import Images from "@Theme/Images";
import { useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Styles from "./style.module.scss";
import Footer from "@Molecule/Footer";

export default function Sidebar({ children, type }) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const token = localStorage.getItem("accessToken");
  // const user = decryptStorageData("user", localStorage);
  const [openDropdown, setOpenDropdown] = useState(false);

  const activePath = useMemo(() => {
    const arr = pathname.split("/");
    return arr[2] || "";
  }, [pathname]);

  const menu = useMemo(() => {
    switch (type) {
      case "admin":
        return [
          {
            icon: "user-square",
            path: "/teacher",
            title: "Daftar Guru",
          },

          {
            icon: "folder",
            // path: "//instructor",
            title: "Siswa",
            path: ["student", "student-by-year"],
            withDropdown: true,
            child: [
              {
                icon: "personalcard",
                path: "student",
                title: "Seluruh Siswa",
              },
              {
                icon: "people1",
                path: "student-by-year",
                title: "Siswa Per-Tahun Ajaran",
              },
            ],
          },
          {
            icon: "book-3",
            path: "/subject-list",
            title: "Daftar Mapel",
          },
          {
            icon: "calendar-outlined",
            path: "/academic-year",
            title: "Tahun Ajaran",
          },
          // {
          //   icon: "shopping-cart-2",
          //   path: "//transaction",
          //   title: "Daftar Transaksi",
          // },
        ];
      case "student":
        return [
          {
            icon: "book-3",
            path: "/grade",
            title: "Kelas Saya",
          },
          // {
          //   icon: "shopping-cart-2",
          //   path: "//transaction",
          //   title: "Transaksi",
          // },
          // {
          //   icon: "award",
          //   path: "//certificate",
          //   title: "Sertifikat",
          // },
        ];
      case "instructor":
        return [
          {
            icon: "book-3",
            path: "/activity",
            title: "Mata Pelajaran",
          },
          {
            icon: "task-square1",
            path: "/class",
            title: "Kurikulum",
          },
          // {
          //   icon: 'money-receive',
          //   path: 'sales',
          //   title: 'Penjualan Kelas'
          // },
        ];
      case "landing":
        return [
          {
            id: "homepageSection",
            title: "Beranda",
            link: "#beranda",
          },
          {
            id: "prakerjaSection",
            title: "Prakerja",
            link: "#prakerja",
          },
          {
            id: "categorySection",
            title: "Kategori",
            link: "#categories",
          },
          {
            id: "benefitSection",
            title: "Benefit",
            link: "#benefit",
          },
          {
            id: "testimonySection",
            title: "Testimoni",
            link: "#testimonials",
          },
          {
            id: "FAQSection",
            title: "FAQ",
            link: "#faq",
          },
          // {
          //   icon: 'money-receive',
          //   path: 'sales',
          //   title: 'Penjualan Kelas'
          // },
        ];
      default:
        return [];
    }
  }, [type]);

  const settings = useMemo(() => {
    switch (type) {
      case "instructor":
        return [
          {
            icon: "setting-3",
            onClick: () => {
              navigate("profile");
            },
            isActive: activePath === "profile",
            title: "Profile",
            isDisabled: true,
          },
          {
            icon: "whatsapp-2",
            onClick: () => {
              window.open(
                "https://api.whatsapp.com/send/?phone=6285925338608&text&type=phone_number&app_absent=0"
              );
            },
            isActive: false,
            title: "Support",
          },
          {
            icon: "logout",
            onClick: () => {
              sessionStorage?.clear();
              localStorage?.clear(navigate(""));
            },
            isActive: false,
            title: "Keluar",
          },
        ];
      case "landing":
        return [];
      default:
        return [
          // {
          //   icon: 'setting-3',
          //   onClick: () => { },
          //   isActive: activePath === 'profile',
          //   title: 'Profile'
          // },
          // {
          //   icon: 'whatsapp-2',
          //   onClick: () => { },
          //   isActive: false,
          //   title: 'Support'
          // },
          {
            icon: "logout",
            onClick: () => {
              sessionStorage?.clear();
              localStorage?.clear(navigate(""));
            },
            isActive: false,
            title: "Keluar",
          },
        ];
    }
  }, [type, activePath, navigate]);

  const [showSide, setShowSide] = useState(false);

  const { width } = useWindowSize();

  const boxRef = useRef();
  const boxOutsideClick = useOutsideClick(boxRef);

  useEffect(() => {
    if (boxOutsideClick && showSide) {
      setShowSide(false);
    }
  }, [boxOutsideClick, showSide]);

  useEffect(() => {
    if (type !== "landing") {
      if (!token) {
        navigate("/");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={Styles.container}>
      {width <= 768 && (
        <button
          ref={boxRef}
          className={Styles.showButton}
          onClick={() => setShowSide(!showSide)}
        >
          <Icon icon={showSide ? "cross" : "burger-icon"} size={20} />
        </button>
      )}
      <div
        className={`${Styles.side} ${showSide ? Styles.show : ""} ${
          type === "landing" ? Styles.hideDesktop : ""
        }`}
      >
        {width <= 768 && (
          <div className={Styles.hideButton}>
            <div className={Styles.logo} onClick={() => navigate("/")}>
              <img src={Images.LOGO} alt="" />
              <span>Skills.id</span>
            </div>
            <button onClick={() => setShowSide(!showSide)}>
              <Icon icon={showSide ? "cross" : "burger-icon"} size={20} />
            </button>
          </div>
        )}
        {type !== "landing" ? (
          <div className={Styles.wrapper}>
            <div className={Styles.section}>
              <span>Menu</span>
              <div className={Styles.menus}>
                {menu?.map((m, mI) =>
                  m.withDropdown ? (
                    <div className={Styles.withDropdown} key={mI}>
                      <button
                        className={
                          m?.path.includes(activePath) || openDropdown
                            ? Styles.active
                            : ""
                        }
                        onClick={() => setOpenDropdown(!openDropdown)}
                      >
                        <Icon
                          icon={
                            m?.path.includes(activePath) || openDropdown
                              ? "open-folder"
                              : "folder"
                          }
                          size={18}
                        />
                        <span>{m.title}</span>
                        <Icon icon={"arrow-down"} size={16} />
                      </button>
                      <div
                        className={`${Styles.childMenuWrapper} ${
                          openDropdown ? Styles.show : Styles.hide
                        }`}
                      >
                        {m.child.map((child, cIdx) => (
                          <button
                            className={
                              child?.path === activePath ? Styles.active : ""
                            }
                            onClick={() => navigate(`/${type}/${child?.path}`)}
                            key={cIdx}
                          >
                            <Icon icon={child.icon} size={20} />
                            <span>{child.title}</span>
                          </button>
                        ))}
                      </div>
                    </div>
                  ) : (
                    <button
                      key={mI}
                      onClick={() => navigate(`/${type}${m?.path}`)}
                      className={
                        m?.path === activePath || m?.path.includes(activePath)
                          ? Styles.active
                          : ""
                      }
                    >
                      <Icon icon={m?.icon} size={18} />
                      <span>{m?.title}</span>
                    </button>
                  )
                )}
              </div>
            </div>
            <div className={Styles.separator} />
            <div className={Styles.section}>
              <span>Setting</span>
              <div className={Styles.menus}>
                {settings?.map((m, mI) => (
                  <button
                    key={mI}
                    onClick={m?.onClick}
                    className={`${m?.isActive ? Styles.active : ""} ${
                      m?.title === "Keluar"
                        ? Styles.out
                        : m.isDisabled
                        ? Styles.disabled
                        : ""
                    }`}
                    disabled={m.isDisabled}
                  >
                    <Icon icon={m?.icon} size={18} />
                    <span>{m?.title}</span>
                  </button>
                ))}
              </div>
            </div>
          </div>
        ) : (
          <div className={Styles.sectionLanding}>
            <div className={Styles.menus}>
              {menu?.map((m, mI) => (
                <a href={m.link} key={mI} className={Styles.navbarLink}>
                  <li>{m.title}</li>
                </a>
              ))}
            </div>
          </div>
        )}
      </div>
      <div className={Styles.contentWrapper}>
        <div className={type !== "landing" ? Styles.content : ""}>
          {children}
        </div>
        {!pathname.includes("report") && <Footer />}
      </div>
    </div>
  );
}
