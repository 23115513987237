import { API } from "@Config/index";

export const fetchAssesment = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/instructor/course/${id}?type=grading`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
};

export const fetchInstructorClassProgress = (
  courseID,
  sessionNum,
  limit,
  page,
  search
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(
        `/instructor/course/${courseID}/session/${sessionNum}/scores?limit=${limit}&page=${page}&search=${search}`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
          },
        }
      );
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
};
export const fetchInstructorClassProgressAST = (courseID, sessionNum) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(
        `/instructor/usersheet/${courseID}/session/${sessionNum}/ast`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
          },
        }
      );
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
};
export const fetchInstructorClassProgressABP = (courseID, sessionNum) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(
        `/instructor/usersheet/${courseID}/session/${sessionNum}/abp`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
          },
        }
      );
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
};
export const fetchInstructorClassProgressASTDetail = (
  courseID,
  sessionNum,
  materialNum
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(
        `/instructor/usersheet/${courseID}/session/${sessionNum}/material/${materialNum}/ast`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
          },
        }
      );
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
};

export const updateInstructorClassProgressAST = (
  userSheetId,
  sessionNum,
  materialNum,
  payload
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.patch(
        `/instructor/usersheet/${userSheetId}/session/${sessionNum}/material/${materialNum}/ast`,
        payload,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
          },
        }
      );
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
};
export const updateInstructorClassProgressABP = (
  userSheetId,
  sessionNum,
  materialNum,
  payload
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.patch(
        `/instructor/usersheet/${userSheetId}/session/${sessionNum}/material/${materialNum}/abp`,
        payload,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
          },
        }
      );
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
};

export function getClassStudentParticipants(
  courseID = 1,
  limit = 10,
  page = 1,
  search = ""
) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(
        `/instructor/course/${courseID}?type=students&limit=${limit}&page=${page}&search=${search}`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
          },
        }
      );
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export const fetchInstructorClassAssesment = (id, type, limit, page) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(
        `/instructor/classAssesment/${id}?type=${type}&limit=${limit}&page=${page}`,
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
        }
      );
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
};

export const fetchStudentAssesment = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/instructor/userSheet/${id}`, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
        },
      });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
};

export const _feedbackTpm = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.post(`/instructor/feedback/tpm`, payload, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
        },
      });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
};
export const _feedbackUk = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.post(`/instructor/feedback/uk`, payload, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
        },
      });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
};

export const _prakerjaSync = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.post(
        `/instructor/sync-user-assignment`,
        payload,
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
        }
      );
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
};


export function _fetchStudentReportList(id,semester) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(
        `/instructor/course/${id}/report/${semester}`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
          },
        }
      );
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}