import React, { useCallback, useEffect, useMemo } from "react";
import Styles from "./style.module.scss";
import Searchbar from "@Atom/Searchbar";
import { useState } from "react";
import Table from "@Molecule/Table";
// import useWindowSize from "@Hooks/useWindowSize";
import { getClassStudent } from "@Services/admin";
import { useNavigate, useParams } from "react-router-dom";
import { getClassStudentParticipants } from "@Services/instructor";
import { useDebounce } from "@Hooks/useDebounce";
import Button from "@Atom/Button";

export default function StudentListAdmin({ isAdmin = false }) {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalData, setTotalData] = useState(0);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { id: courseID } = useParams();
  const [search, setSearch] = useState("");
  const debounceSearch = useDebounce(search, 500);
  const [semesterData, setSemesterData] = useState(null);
  const navigate = useNavigate()

  const fetchDataParticipants = useCallback(async () => {
    setLoading(true);
    try {
      if (isAdmin) {
        const response = await getClassStudent(courseID, limit, page, search);
        setData(response?.data[0]?.users || []);
        setTotalData(response.totalData);
      } else {
        const response = await getClassStudentParticipants(
          courseID,
          limit,
          page,
          search
        );
        setData(response?.course?.users || []);
        setSemesterData(response?.period);
        setTotalData(response.totalData);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAdmin, courseID, limit, page, debounceSearch]);

  const semester = useMemo(() => {
    return new Date(semesterData?.secondSemester.startDate) < new Date() ? 2 : 1;
  }, [semesterData]);

  const disabledButton = useMemo(() => {
    if(semester === 1) {
      
      return (
        new Date(semesterData?.firstSemester?.reportStartDate) > new Date() &&
        new Date(semesterData?.firstSemester?.reportEndDate) < new Date()
      );
    } else {
      return (
        new Date(semesterData?.secondSemester?.reportStartDate) > new Date() &&
        new Date(semesterData?.secondSemester?.reportEndDate) < new Date()
      );

    }
  }, [semesterData,semester]);

  useEffect(() => {
    fetchDataParticipants();
  }, [fetchDataParticipants]);

  const template = useMemo(() => {
    return {
      data: data?.map((el) => ({
        ...el,
        id: el?.userID,
        name: el?.name,
        score1: el?.scores[0]?.totalScore,
        score2: el?.scores[1]?.totalScore,
        score3: el?.scores[2]?.totalScore,
        score4: el?.scores[3]?.totalScore,
      })),
      columns: [
        {
          name: "",
          title: "No",
          isNumber: true,
        },
        {
          name: "name",
          title: "Nama Murid",
          width: "220px",
        },
        {
          name: "score1",
          title: "Rata-Rata Nilai Bagian 1",
          align: "right",
          renderData: (row) => <span>{row?.score1}</span>,
          width: "180px",
        },
        {
          name: "score2",
          title: "Rata-Rata Nilai Bagian 2",
          align: "right",
          renderData: (row) => <span>{row?.score2}</span>,
          width: "180px",
        },
        {
          name: "scoreSemester1",
          title: "Rata-Rata Nilai Semester 1",
          align: "right",
          renderData: (row) => <span>{row?.scoreSemester1}</span>,
          width: "180px",
        },
        {
          name: "changedScoreSemester1",
          title: "Perubahan Nilai Rata-Rata Sem 1",
          align: "right",
          renderData: (row) => <span>{row?.changedScoreSemester1}</span>,
          width: "180px",
        },
        {
          name: "score3",
          title: "Rata-Rata Nilai Bagian 3",
          align: "right",
          renderData: (row) => <span>{row?.score3}</span>,
          width: "180px",
        },
        {
          name: "score4",
          title: "Rata-Rata Nilai Bagian 4",
          align: "right",
          renderData: (row) => <span>{row?.score4}</span>,
          width: "180px",
        },
        {
          name: "scoreSemester2",
          title: "Rata-Rata Nilai Semester 2",
          align: "right",
          renderData: (row) => <span>{row?.scoreSemester2}</span>,
          width: "180px",
        },
        {
          name: "endScore",
          title: "Nilai Akhir",
          align: "right",
          renderData: (row) => <span>{row?.endScore}</span>,
          width: "100px",
          position: "sticky",
          right: "109px",
        },
        {
          name: "",
          title: "Nilai Perubahan",
          align: "right",
          renderData: (row) => <span>-</span>,
          width: "100px",
          position: "sticky",
          right: 0,
        },
      ],
    };
  }, [data]);

  return (
    <div className={Styles.container}>
      <div className={Styles.topWrapper}>
        <Searchbar
          placeholder="Cari Murid"
          value={search}
          setValue={setSearch}
        />
        {!isAdmin && (
          <Button text={`Serahkan Nilai Sem.${semester}`} startIcon={disabledButton ? "lock" : ""} disabled={disabledButton} 
          onClick={() => navigate(`report/${semester}`)}
          />
        )}
      </div>
      <div className={Styles.tableWrapper}>
        <Table
          data={template?.data}
          totalData={totalData}
          totalPage={Math.ceil(totalData / limit) || 0}
          columns={template?.columns}
          page={page}
          setPage={setPage}
          className={`${Styles.table}`}
          limit={limit}
          setLimit={setLimit}
          withPagination
          isLoading={loading}
          isSticky={true}
          // expandComponent={(row) => (
          //   <div className={Styles.expandedInfo}>
          //     <div className={Styles.infoRow}>
          //       <span>Nama Peserta</span>
          //       <span>{row?.name}</span>
          //     </div>
          //     <div className={Styles.infoRow}>
          //       <span>Kode Voucher</span>
          //       <span>{row?.voucher}</span>
          //     </div>
          //     <div className={Styles.infoRow}>
          //       <span>Progress</span>
          //       <span>{Math.trunc(row?.progress)}%</span>
          //     </div>
          //     <div className={Styles.infoRow}>
          //       <span>Pretest</span>
          //       <span
          //         className={Styles.infoRowValue}
          //         is-done={row?.preTest !== -1 ? "true" : "false"}
          //       >
          //         {row?.preTest === -1 ? "" : row?.preTest}
          //       </span>
          //     </div>
          //     <div className={Styles.infoRow}>
          //       <span>TPM 1</span>
          //       <span
          //         className={Styles.infoRowValue}
          //         style={{
          //           background:
          //             row?.tpm1 === null
          //               ? "#9E9E9E"
          //               : row?.tpm1 === -1
          //               ? "#D42801"
          //               : "#60AA15",
          //         }}
          //       >
          //         {row?.tpm1}
          //       </span>
          //     </div>
          //     <div className={Styles.infoRow}>
          //       <span>TPM 2</span>
          //       <span
          //         className={Styles.infoRowValue}
          //         style={{
          //           background:
          //             row?.tpm2 === null
          //               ? "#9E9E9E"
          //               : row?.tpm2 === -1
          //               ? "#D42801"
          //               : "#60AA15",
          //         }}
          //       >
          //         {row?.tpm2}
          //       </span>
          //     </div>
          //     <div className={Styles.infoRow}>
          //       <span>TPM 3</span>
          //       <span
          //         className={Styles.infoRowValue}
          //         style={{
          //           background:
          //             row?.tpm3 === null
          //               ? "#9E9E9E"
          //               : row?.tpm3 === -1
          //               ? "#D42801"
          //               : "#60AA15",
          //         }}
          //       >
          //         {row?.tpm3}
          //       </span>
          //     </div>
          //     <div className={Styles.infoRow}>
          //       <span>TPM 4</span>
          //       <span
          //         className={Styles.infoRowValue}
          //         style={{
          //           background:
          //             row?.tpm4 === null
          //               ? "#9E9E9E"
          //               : row?.tpm4 === -1
          //               ? "#D42801"
          //               : "#60AA15",
          //         }}
          //       >
          //         {row?.tpm4}
          //       </span>
          //     </div>
          //     <div className={Styles.infoRow}>
          //       <span>TPM 5</span>
          //       <span
          //         className={Styles.infoRowValue}
          //         style={{
          //           background:
          //             row?.tpm5 === null
          //               ? "#9E9E9E"
          //               : row?.tpm5 === -1
          //               ? "#D42801"
          //               : "#60AA15",
          //         }}
          //       >
          //         {row?.tpm5}
          //       </span>
          //     </div>
          //     <div className={Styles.infoRow}>
          //       <span>post Test</span>
          //       <span
          //         className={Styles.infoRowValue}
          //         is-done={row?.postTest !== -1 ? "true" : "false"}
          //       >
          //         {row?.postTest === -1 ? "" : row?.postTest}
          //       </span>
          //     </div>
          //     <div className={Styles.infoRow}>
          //       <span>UK</span>
          //       <span
          //         className={Styles.infoRowValue}
          //         is-done={row?.uk !== -1 ? "true" : "false"}
          //       >
          //         {row?.uk === -1 ? "" : row?.uk}
          //       </span>
          //     </div>
          //   </div>
          // )}
        />
      </div>
    </div>
  );
}
