import React, { useMemo } from "react";
import Styles from "./style.module.scss";
import Images from "@Theme/Images";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "@Atom/Button";
import { useState } from "react";
import QuesionReviewCard from "@Molecule/QuestionReviewCard";

const tabs = ["Pilihan Ganda", "Essay"];

export default function ReviewScore() {
  const location = useLocation();
  let data = location.state;
  const [tab, setTab] = useState(0);
  const navigate = useNavigate();

  const questionsToMap = useMemo(() => {
    return data.quizzes.filter((el) => el.quizType === tabs[tab]);
  }, [data, tab]);

  const maxScore = useMemo(() => {
    return {
      pg: data.quizzes.filter((el) => el.quizType === "Pilihan Ganda").length,
      essay: data.quizzes
        .filter((el) => el.quizType === "Essay")
        .map((el) => +el.maxScore)
        .reduce((accumulator, currentValue) => {
          return accumulator + currentValue;
        }, 0),
    };
  }, [data]);

  const score = useMemo(() => {
    return {
      pg: data.quizzes
        .filter((el) => el.quizType === "Pilihan Ganda")
        .map((el) => el.score)
        .reduce((accumulator, currentValue) => {
          return accumulator + currentValue;
        }, 0),
      essay: data.quizzes
        .filter((el) => el.quizType === "Essay")
        .map((el) => el.score)
        .reduce((accumulator, currentValue) => {
          return accumulator + currentValue;
        }, 0),
    };
  }, [data]);

  return (
    <div className={Styles.container}>
      <div className={Styles.sticky}>
        <Button
          variant="text"
          text={"Kembali"}
          startIcon={"chevron-left"}
          onClick={() => navigate(-1)}
        />
        <div className={Styles.headText}>
          <img src={Images.THS_LOGO} alt="" />
          <p>Hasil Penilaian Asesmen Sumatif Teori</p>
        </div>
        <div className={Styles.headerContainer}>
          <div className={Styles.headerWrapper}>
            <div className={Styles.tabWrapper}>
              {tabs.map((el, idx) => (
                <div
                  className={Styles.tab}
                  onClick={() => setTab(idx)}
                  is-selected={String(tab === idx)}
                >
                  <p>{el}</p>
                </div>
              ))}
            </div>
            <div className={Styles.scoreWrapper}>
              <p>
                Skor PG : {score.pg}/{maxScore.pg}
              </p>
              <p>
                Skor Essay : {score.essay}/{maxScore.essay}
              </p>
              <p>Nilai : {data.materialScore}</p>
            </div>
          </div>
        </div>
      </div>
      <div className={Styles.bodyContainer}>
        <div className={Styles.bodyWrapper}>
          {questionsToMap.map((el, idx) => (
            <React.Fragment key={idx}>
              <QuesionReviewCard data={el} quizNum={idx + 1}/>
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
}
