import LoadingContent from "@Molecule/Loading";
import TeacherScoreReview from "@Organism/InstructorDashboard/TeacherScoreReview";
import { fetchInstructorClassProgressASTDetail } from "@Services/instructor";
import React, { Suspense } from "react";
import { Await, defer, useLoaderData } from "react-router-dom";

export async function loader(route) {
  const params = route.params;
  console.log(params.id);
  const getStudentAssesment = fetchInstructorClassProgressASTDetail(
    params.userSheetId,
    params.sessionNum,
    params.materialNum
  );
  return defer({
    studentAssesment: getStudentAssesment,
  });
}

export default function ScoreReview() {
    const { studentAssesment } = useLoaderData();

    return (
      <Suspense fallback={<LoadingContent/>}>
        <Await
          resolve={studentAssesment}
          errorElement={
            <div style={{ height: "100vh" }}>
              <p>error...</p>
            </div>
          }
        >
          {(value) => <TeacherScoreReview data={value?.data} />}
        </Await>
      </Suspense>
    );
}
