import React, { useMemo, useState } from "react";
import Styles from "./style.module.scss";
import Images from "@Theme/Images";
import MultipleChoiceCard from "@Molecule/MultipleChoiceCard";
import Button from "@Atom/Button";
import Icon from "@Atom/Icon";

export default function QuizModal({ dataToMap, handleSubmit }) {
  const [questionNumber, setQuestionNumber] = useState(0);
  const [slideIn, setSlideIn] = useState(false);
  //   console.log(store);
  const [selectedAnswer, setSelectedAnswer] = useState([]);
  const [showList, setShowList] = useState(false);

  const disabledSubmit = useMemo(() => {
    if (questionNumber + 1 === dataToMap.length) {
      return (
        selectedAnswer.length < dataToMap.length ||
        selectedAnswer.includes(undefined)
      );
    }
    return false;
  }, [dataToMap, questionNumber, selectedAnswer]);

  const handleNextButton = () => {
    if (questionNumber + 1 === dataToMap.length) {
      handleSubmit(selectedAnswer);
    } else {
      setQuestionNumber((prev) => prev + 1);
    }
  };

  return (
    <div className={Styles.body}>
      <div className={Styles.container}>
        <div className={Styles.leftSection}>
          <div className={Styles.header}>
            <img src={Images.THS_LOGO} alt="" />
            <p>Asesmen Sumatif Teori</p>
          </div>
          <div className={Styles.questionContainer}>
            {dataToMap.map((el, idx) => (
              <div
                className={`${Styles.questionWrapper} ${
                  idx === questionNumber ? Styles.show : Styles.hide
                }`}
                slideIn={String(!slideIn)}
                key={idx}
                visibility={idx === questionNumber ? "show" : "hide"}
              >
                <MultipleChoiceCard
                  number={idx + 1}
                  data={el}
                  setSelected={(num) => {
                    const temp = [...selectedAnswer];
                    temp[idx] = num;
                    setSelectedAnswer(temp);
                  }}
                  selected={selectedAnswer[idx]}
                />
              </div>
            ))}
          </div>
          <div className={Styles.buttonWrapper}>
            <Button
              text={"Kembali"}
              variant="text"
              startIcon={"chevron-left"}
              onClick={() => {
                setQuestionNumber((prev) => prev - 1);
                setSlideIn(true);
              }}
              disabled={questionNumber < 1}
            />
            <p>
              {questionNumber + 1}/{dataToMap.length}
            </p>
            <Button
              text={
                questionNumber + 1 === dataToMap.length
                  ? "Kumpulkan"
                  : "Selanjutnya"
              }
              variant="text"
              endIcon={"chevron-right"}
              onClick={handleNextButton}
              disabled={disabledSubmit}
            />
          </div>
        </div>
        <div
          className={`${Styles.rightSection} ${
            showList ? Styles.showList : Styles.hideList
          }`}
        >
          <div className={Styles.questionListContainer}>
            <div className={Styles.cardHeader}>
              <p className={Styles.cardTitle}>
                Soal {questionNumber + 1}/{dataToMap.length}
              </p>
              <Icon
                icon={"cross"}
                size={24}
                onClick={() => {
                  setShowList(false);
                }}
              />
            </div>
            <div className={Styles.numberWrapper}>
              {dataToMap.map((el, idx) => (
                <div
                  className={Styles.questionNumber}
                  key={idx}
                  type={
                    questionNumber === idx
                      ? "active"
                      : selectedAnswer[idx]
                      ? "finish"
                      : "false"
                  }
                  onClick={() => {
                    setQuestionNumber(idx);
                    setSlideIn(questionNumber > idx);
                  }}
                >
                  <p>{idx + 1}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className={Styles.floatMenu}>
          <Icon
            icon={"list-unordered"}
            color={"#FFFFFF"}
            size={24}
            onClick={() => setShowList(true)}
          />
        </div>
      </div>
    </div>
  );
}
