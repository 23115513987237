import LoadingContent from "@Molecule/Loading";
import TeacherStudentReport from "@Organism/InstructorDashboard/StudentReport";
import { _fetchStudentReportList } from "@Services/instructor";
import React, { Suspense } from "react";
import { Await, defer, useLoaderData } from "react-router-dom";

export function loader({ params }) {
  const { id, semester } = params;
  const getStudentList = _fetchStudentReportList(id, semester === "1" ? 1 : 0);

  return defer({
    getStudentList,
  });
}

export default function TeacherStudentReportPage() {
  const { getStudentList } = useLoaderData();

  return (
    <Suspense fallback={<LoadingContent />}>
      <Await
        resolve={getStudentList}
        errorElement={
          <div style={{ height: "100vh" }}>
            <p>error...</p>
          </div>
        }
      >
        {(value) => <TeacherStudentReport data={value.data} />}
      </Await>
    </Suspense>
  );
}
