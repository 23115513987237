import Button from "@Atom/Button";
import Card from "@Atom/Card";
import FormErrorMessage from "@Atom/FormErrorMessage";
import { doesObjectContainEmptyValue } from "@Helpers/doesObjectContainEmptyValue";
import InputField from "@Molecule/InputField";
import {
  decryptToken,
  forgotPassword,
  login,
  register,
  registerVerification,
  resetPassword,
} from "@Services/user";
import Images from "@Theme/Images";
import { useCallback, useEffect, useMemo, useState } from "react";
import Styles from "./style.module.scss";
import { useNavigate } from "react-router-dom";
import {
  decryptStorageData,
  encryptStorageData,
} from "@Helpers/encryptDecrypt";
import { translateError } from "@Helpers/translateError";
import { hideEmailText } from "@Helpers/hideEmail";
import useQuery from "@Hooks/useQuery";

export default function AuthLayout({ type = "" }) {
  const [form, setForm] = useState({});
  const [error, setError] = useState("");
  const [OTPMode, setOTPMode] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loading, setLoading] = useState(false);
  const query = useQuery();
  const hashQuery = query.get("hash") || "";
  const user = decryptStorageData("user", localStorage);

  const checkExpired = async () => {
    try {
      await decryptToken({ token: hashQuery });
    } catch (error) {
      navigate("/expired");
    }
  };

  useEffect(() => {
    if (hashQuery) {
      checkExpired();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hashQuery]);

  const navigate = useNavigate();

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError("");
      }, 5000);
    }
  }, [error, setError]);

  // const query = useQUery

  useEffect(() => {
    if (user) {
      if (user.role === "TEACHER") {
        navigate("/instructor");
      }
      if (user.role === "STUDENT") {
        navigate("/student/grade");
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (type === "login") {
      setForm({
        email: "",
        password: "",
      });
    } else if (type === "forgot-password") {
      setForm({ password: "", confirmPassword: "" });
    } else {
      setForm({
        name: "",
        email: "",
        whatsAppNumber: "",
      });
    }
  }, [type]);

  const [hash, setHash] = useState();

  const handleSubmit = useCallback(
    async (actionType, isResend) => {
      try {
        setIsSubmitting(true);
        switch (actionType) {
          case "login":
            const response = await login({
              ...form,
              emailOrIdNumber: form.email,
            });
            if (response.status) {
              encryptStorageData("user", response?.data);
              localStorage.setItem("accessToken", response?.access_token);
              if (response.data.role === "TEACHER") {
                navigate("/instructor");
              }
              if (response.data.role === "STUDENT") {
                navigate("/student/grade");
              }
              if (response.data.role === "ADMIN") {
                navigate("/admin/teacher");

              }
            }
            break;
          case "register":
            switch (OTPMode) {
              case false:
                const response = await register(
                  form?.name,
                  form?.email,
                  "62" + form?.whatsAppNumber
                );
                setHash(response?.hash);
                setOTPMode(true);
                break;
              case true:
                if (!isResend) {
                  const registerResponse = await registerVerification(hash);
                  localStorage.setItem(
                    "accessToken",
                    registerResponse?.access_token
                  );
                  encryptStorageData("user", registerResponse?.user);
                  navigate("/");
                } else {
                  const response = await register(
                    form?.name,
                    form?.email,
                    "62" + form?.whatsAppNumber
                  );
                  setHash(response?.hash);
                }
                break;
              default:
                break;
            }
            break;
          default:
            break;
        }
        setIsSubmitting(false);
      } catch (err) {
        setIsSubmitting(false);
        setError(
          translateError(err?.response.data.message[0]) ||
            "Something went wrong"
        );
      }
    },
    [OTPMode, form, hash, navigate]
  );

  const handleResetPassword = async () => {
    const dataToSend = {
      email: form.email,
    };
    try {
      setLoading(true);
      const res = await forgotPassword(dataToSend);
      if (res.statusCode === 200) {
        setOTPMode("success-forgot-password");
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError(
        translateError(err?.response.data.message[0]) || "Something went wrong"
      );
    }
  };

  const handleSubmitChangePassword = async () => {
    const dataToSend = {
      token: hashQuery,
      newPassword: form.password,
    };
    if (form.password !== form.confirmPassword) {
      setError(
        "Password dan konfirmasi password tidak cocok. Silakan coba lagi."
      );
    } else {
      try {
        setLoading(true);
        const res = await resetPassword(dataToSend);
        if (res.statusCode === 200) {
          setOTPMode("success-change-password");
        }
        setLoading(false);
      } catch (err) {
        setError(
          translateError(err?.response.data.message[0]) ||
            "Something went wrong"
        );
        setLoading(false);
      }
    }
  };

  const handleChangeForm = useCallback((name, newVal) => {
    setForm((prev) => {
      return {
        ...prev,
        [name]: newVal,
      };
    });
  }, []);

  // We can use useEffect so that when the component
  // mount the timer will start as soon as possible

  // We put empty array to act as componentDid
  // mount only
  // useEffect(() => {
  //   if (hash) {
  //     clearTimer(getDeadTime());
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [hash]);

  const template = useMemo(() => {
    switch (OTPMode) {
      case false:
        switch (type) {
          case "login":
            return {
              title: "Silakan masuk ke akun E-Learning THS",
              form: (
                <form onSubmit={(e) => e?.preventDefault()}>
                  <InputField
                    // title={'Email atau Nomor WhatsApp'}
                    title={"Email/NISN"}
                    value={form?.email}
                    setValue={(newVal) => handleChangeForm("email", newVal)}
                    placeholder="Masukkan email atau nomor NISN"
                    // remark={
                    //   <p className={Styles.remark}>
                    //     <Icon icon={'information-solid'} size={20} color={'#F5D38B'} />
                    //     <span>Untuk login menggunakan nomor Whatsapp mohon sertakan, contoh : 628**********</span>
                    //   </p>
                    // }
                    required
                  />
                  <InputField
                    // title={'Email atau Nomor WhatsApp'}
                    title={"Password"}
                    value={form?.password}
                    setValue={(newVal) => handleChangeForm("password", newVal)}
                    placeholder="Masukkan kata sandi"
                    isPassword
                    // remark={
                    //   <p className={Styles.remark}>
                    //     <Icon icon={'information-solid'} size={20} color={'#F5D38B'} />
                    //     <span>Untuk login menggunakan nomor Whatsapp mohon sertakan, contoh : 628**********</span>
                    //   </p>
                    // }
                    required
                  />
                  <div className={Styles.formFooter}>
                    <div>
                      {/* <Switch
                        isChecked={rememberMe}
                        setIsChecked={() => setRememberMe(!rememberMe)}
                      />
                      <p>Ingat Saya</p> */}
                    </div>

                    <p onClick={() => setOTPMode("forgot-password")}>
                      Lupa Password?
                    </p>
                  </div>
                  <FormErrorMessage message={error} />
                  <Button
                    text={isSubmitting ? "loading..." : "Masuk"}
                    type={"submit"}
                    disabled={doesObjectContainEmptyValue(form) || isSubmitting}
                    onClick={() => handleSubmit("login")}
                  />
                </form>
              ),
              // footerText: 'Belum punya akun?',
              // footerButton: <Button className={Styles.switchButton} variant='text' endIcon={'arrow-right-alt'} iconSize={20} text={'Daftar Sekarang'} onClick={() => navigate('/register')} />
            };
          case "forgot-password":
            return {
              title: "Ganti Kata Sandi",
              form: (
                <form onSubmit={(e) => e?.preventDefault()}>
                  <InputField
                    title={"Password"}
                    value={form?.password}
                    setValue={(newVal) => handleChangeForm("password", newVal)}
                    placeholder="Masukkan kata sandi"
                    isPassword
                    required
                  />
                  <InputField
                    title={"Konfirmasi Kata Sandi"}
                    value={form?.confirmPassword}
                    setValue={(newVal) =>
                      handleChangeForm("confirmPassword", newVal)
                    }
                    placeholder="Masukkan kata sandi"
                    isPassword
                    required
                  />
                  <FormErrorMessage message={error} />
                  <Button
                    text={loading ? "loading..." : "Ganti Kata Sandi"}
                    type={"submit"}
                    disabled={doesObjectContainEmptyValue(form) || loading}
                    onClick={handleSubmitChangePassword}
                  />
                  <Button
                    text={"Kembali ke halaman Login"}
                    className={Styles.backToLogin}
                    onClick={() => navigate("/")}
                    variant="text"
                  />
                </form>
              ),
              // footerText: 'Belum punya akun?',
              // footerButton: <Button className={Styles.switchButton} variant='text' endIcon={'arrow-right-alt'} iconSize={20} text={'Daftar Sekarang'} onClick={() => navigate('/register')} />
            };
          case "register":
            return {
              title: "Silahkan daftar akun Anda di sini",
              form: (
                <form onSubmit={(e) => e?.preventDefault()}>
                  <div className={Styles.fields}>
                    <InputField
                      title={"Nama"}
                      value={form?.name}
                      setValue={(newVal) => handleChangeForm("name", newVal)}
                      placeholder="Masukkan nama anda"
                      readOnly={isSubmitting}
                      required
                    />
                    <InputField
                      title={"Email"}
                      value={form?.email}
                      setValue={(newVal) => handleChangeForm("email", newVal)}
                      placeholder="Masukkan email anda"
                      readOnly={isSubmitting}
                      required
                    />
                    <InputField
                      title={"Nomor WhatsApp"}
                      value={form?.whatsAppNumber}
                      setValue={(newVal) =>
                        handleChangeForm("whatsAppNumber", newVal)
                      }
                      placeholder="Masukkan nomor WhatsApp anda"
                      isPhoneNumber
                      readOnly={isSubmitting}
                      required
                    />
                  </div>
                  <FormErrorMessage message={error} />

                  <Button
                    type="submit"
                    text={isSubmitting ? "Mendaftarkan..." : "Daftar"}
                    disabled={doesObjectContainEmptyValue(form) || isSubmitting}
                    onClick={() => handleSubmit("register")}
                  />
                </form>
              ),
              footerText: "Sudah punya akun?",
              footerButton: (
                <Button
                  onClick={() => navigate("")}
                  className={Styles.switchButton}
                  variant="text"
                  endIcon={"arrow-right-alt"}
                  iconSize={20}
                  text={"Masuk sekarang"}
                />
              ),
            };
          default:
            return {};
        }
      case "forgot-password":
        return {
          title: "Reset Kata Sandi",
          description: "Silahkan masukan email Anda yang telah terdaftar",
          form: (
            <form onSubmit={(e) => e.preventDefault()}>
              <InputField
                // title={'Email atau Nomor WhatsApp'}
                title={"Email "}
                value={form?.email}
                setValue={(newVal) => handleChangeForm("email", newVal)}
                placeholder="Masukkan Email"
                // remark={
                //   <p className={Styles.remark}>
                //     <Icon icon={'information-solid'} size={20} color={'#F5D38B'} />
                //     <span>Untuk login menggunakan nomor Whatsapp mohon sertakan, contoh : 628**********</span>
                //   </p>
                // }
                required
              />
              <FormErrorMessage message={error} />

              <div className={Styles.buttonWrapper}>
                <Button
                  text={loading ? "loading..." : "Reset Kata Sandi"}
                  className={Styles.requestPassButton}
                  textSize="m"
                  textWidth="semibold"
                  onClick={() => handleResetPassword()}
                  disabled={!form.email || loading}
                />
                <Button
                  text={"Kembali ke halaman Login"}
                  className={Styles.backToLogin}
                  onClick={() => setOTPMode(false)}
                  variant="text"
                />
              </div>
            </form>
          ),
        };
      case "success-forgot-password":
        return {
          title: "Berhasil Terkirim",
          description: `Kami telah mengirimkan tautan untuk reset kata sandi pada alamat email ${hideEmailText(
            form.email
          )}`,
          form: (
            <form>
              <div className={Styles.buttonWrapper}>
                <Button
                  text={"Kembali ke halaman Login"}
                  className={Styles.backToLogin}
                  onClick={() => setOTPMode(false)}
                  variant="outlined"
                />
              </div>
            </form>
          ),
        };
      case "success-change-password":
        return {
          title: "Kata Sandi Berhasil Diperbarui",
          description: `Anda telah melalukan penggantian kata sandi baru, untuk melanjutkan silahkan kembali ke halaman Login`,
          form: (
            <form>
              <div className={Styles.buttonWrapper}>
                <Button
                  text={"Kembali ke halaman Login"}
                  className={Styles.backToLogin}
                  onClick={() => {
                    navigate("/");
                    setOTPMode(false);
                  }}
                  variant="outlined"
                />
              </div>
            </form>
          ),
        };
      default:
        return {};
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    OTPMode,
    error,
    form,
    handleChangeForm,
    handleSubmit,
    isSubmitting,
    navigate,
    type,
    loading,
  ]);

  return (
    <div className={Styles.container}>
      <img src={Images.LEFT_CLAY} alt="" className={Styles.leftClay} />
      <img src={Images.RIGHT_CLAY} alt="" className={Styles.rightClay} />
      <div className={Styles.cardWrapper}>
        <Card className={Styles.card}>
          <img src={Images.THS_LOGO} alt="" />
          <div className={Styles.main}>
            {/* {OTPMode && (
              <div>
                <Button
                  variant="text"
                  text={"Kembali"}
                  startIcon={"chevron-left"}
                  style={{ padding: 0 }}
                  onClick={() => setOTPMode(false)}
                />
              </div>
            )} */}
            <div className={Styles.title}>
              <h3>{template?.title}</h3>
              {!!template?.description && <span>{template?.description}</span>}
            </div>
            {template?.form}
            <p>
              <span>{template?.footerText}</span>
              {template?.footerButton}
            </p>
          </div>
        </Card>
      </div>
    </div>
  );
}
