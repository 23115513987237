import React, { useEffect, useMemo } from "react";
import Styles from "./style.module.scss";
import Modal from "@Atom/Modal";
import { useParams } from "react-router-dom";
import Button from "@Atom/Button";
import Score from "@Atom/Score";
import ClassImage from "@Theme/ClassImage";
import modalVector from "@Assets/Icon/modal-vector.svg";
import { userCourseStore } from "store";

export default function ModalResultScore({
  isOpen = true,
  handleBack = () => {},
  headtext,
  desc,
  reviewed = false,
  score,
  openModalQuiz,
}) {
  // const score = 60;
  const { id } = useParams();
  const { fetchUserSheet } = userCourseStore();

  useEffect(() => {
    if (isOpen === true) {
      fetchUserSheet(id);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const descText = useMemo(() => {
    if (reviewed) {
      return "Asesmen kamu sedang dalam tahap penilaian oleh guru. Harap tunggu dan cek secara berkala, nilai akan muncul pada halaman asesmen bila telah diberikan nilai oleh guru kamu.";
    }
    if (score >= 70) {
      return "Selamat! Nilai kamu telah mencapai nilai minumum silahkan lanjutkan pembelajaran ke materi selanjutnya";
    }
    if (score < 70) {
      return `Nilai Kamu ${score}, silahkan lakukan remidi dengan cara mengerjakan ulang asesmen ini`;
    }
  }, [score, reviewed]);
  return (
    <Modal isOpen={isOpen}>
      <div className={Styles.body}>
        <img src={ClassImage("score-background")} alt="" />
        <section className={Styles.containerScore}>
          <h1>{reviewed ? "Menunggu Nilai" : "Nilai Akhir"}</h1>
          {reviewed || score === -1 ? (
            <img src={modalVector} alt="" className={Styles.vectorImage} />
          ) : (
            <Score score={score} />
          )}
          <p>{desc || descText}</p>
          {score < 70 && !reviewed ? (
            <div className={Styles.buttonWrapper}>
              <Button
                text={"Kerjakan Remidi Sekarang"}
                onClick={openModalQuiz}
              />
              <Button
                variant="outlined"
                text={"Kerjakan Remidi Nanti"}
                onClick={() => handleBack()}
              />
            </div>
          ) : (
            <div className={Styles.buttonWrapper}>
              <Button
                text={"Kembali Ke Halaman Kelas"}
                onClick={() => handleBack()}
              />
            </div>
          )}
        </section>
      </div>
    </Modal>
  );
}
