import React, { useState } from "react";
import Styles from "./style.module.scss";
import DoodleGradientCard from "@Atom/DoodleGradientCard";
import Avatar from "@Atom/Avatar";
import Icon from "@Atom/Icon";
import { fileBaseUrl } from "@Config/index";

export default function StudentDetailHeader({ data }) {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <div className={Styles.container}>
      <DoodleGradientCard className={Styles.header} />
      <div className={Styles.bodyWrapper}>
        <Avatar
          iconName="user"
          size="160px"
          iconSize="120px"
          iconColor="#000"
          borderRadius="50%"
          imgUrl={
            data?.profilePicture ? fileBaseUrl + data.profilePicture : ""
          }
          className={Styles.avatar}
        />
        <div className={Styles.contentWrapper}>
          <div className={Styles.contentHeader}>
            <p>Detail Siswa</p>
            <Icon icon="edit1" size={24} color={"#764A87"} />
          </div>
          <div className={Styles.content}>
            <div className={Styles.labelWrapper}>
              <p className={Styles.label}>Nama Siswa</p>
              <p className={Styles.value}>{data?.name}</p>
            </div>
            <div className={Styles.divider} />
            <div className={Styles.labelWrapper}>
              <p className={Styles.label}>NISN</p>
              <p className={Styles.value}>{data?.idNumber}</p>
            </div>
            <div className={Styles.divider} />

            <div className={Styles.labelWrapper}>
              <p className={Styles.label}>Nomor Handphone</p>
              <p className={Styles.value}>{data?.phoneNumber}</p>
            </div>
            <div className={Styles.divider} />

            <div className={Styles.labelWrapper}>
              <p className={Styles.label}>password</p>
              <div className={Styles.password}>
                <p className={Styles.value}>
                  {showPassword && data.password
                    ? data?.password
                    : data?.password?.replace(/\d/g, "*")}
                </p>
                <Icon
                  icon={showPassword ? "eye-visible" : "eye-invisible"}
                  size={16}
                  color={"#764A87"}
                  onClick={() => setShowPassword(!showPassword)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
