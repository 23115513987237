import React, { useEffect, useState } from "react";
import Styles from "./style.module.scss";
import Breadcrumbs from "@Atom/Breadcrumbs";
import { useNavigate, useParams } from "react-router-dom";
import Searchbar from "@Atom/Searchbar";
import Avatar from "@Atom/Avatar";
import Icon from "@Atom/Icon";
import Input from "@Atom/Input";
import { useMemo } from "react";
import Button from "@Atom/Button";
import { _submitStudentsReport } from "@Services/admin";
import toast from "react-hot-toast";
import { fileBaseUrl } from "@Config/index";

export default function TeacherStudentReport({ data }) {
  const { id: classID, semester } = useParams();
  const [studentList, setStudentList] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState(0);
  const [search, setSearch] = useState("");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (semester === "1") {
      setStudentList([
        ...data.map((el, idx) => ({
          ...el,
          id: idx,
          score: el.smtI.actualScore,
        })),
      ]);
    } else {
      setStudentList([
        ...data.map((el, idx) => ({
          ...el,
          id: idx,
          score: el.smtII.actualScore,
        })),
      ]);
    }
  }, [data, semester]);

  const handleSubmitReport = async (status) => {
    const dataToSend = {
      students: studentList.map((el) => ({
        score: el.score,
        competency: el.competency,
        usersheetID: el.usersheetID,
      })),
      status,
    };
    try {
      setLoading(true)
      await _submitStudentsReport(classID, semester, dataToSend);
      if (status === "DRAFT") {
        toast.success(`Nilai Berhasil disimpan sebagai draft`);
      } else {
        toast.success(`Nilai Berhasil Dikirimkan`);
      }
      setLoading(false)
      navigate(-1);
    } catch (error) {}
  };

  const handleChange = (key, child, value) => {
    let temp = [...studentList];
    if (child) {
      temp[selectedStudent][key][child] = value;
    } else {
      if (key === "score") {
        if (value < 101) temp[selectedStudent][key] = +value;
      } else {
        temp[selectedStudent][key] = value;
      }
    }
    setStudentList(temp);
  };

  const studentToView = useMemo(() => {
    return studentList.filter((el) =>
      el.name.toUpperCase().includes(search.toUpperCase())
    );
  }, [search, studentList]);

  const selectedStudentData = useMemo(() => {
    return studentList[selectedStudent];
  }, [selectedStudent, studentList]);

  return (
    <div className={Styles.container}>
      <Breadcrumbs
        data={[
          { title: "Mata Pelajaran" },
          { title: "Mata Pelajaran" },
          { title: `Penyerahan Nilai Semester ${semester}` },
        ]}
      />
      <div className={Styles.contentWrapper}>
        <div className={Styles.leftSection}>
          <div className={Styles.topHeader}>
            <p>Daftar Siswa</p>
          </div>
          <div className={Styles.searchWrapper}>
            <Searchbar
              placeholder="Cari Murid"
              width="100%"
              value={search}
              setValue={setSearch}
            />
          </div>
          <div className={Styles.studentList}>
            {studentToView.map((el, idx) => (
              <div
                className={`${Styles.studentCard} ${
                  selectedStudent === el.id && Styles.active
                }`}
                key={idx}
                onClick={() =>
                  selectedStudent !== el.id && setSelectedStudent(el.id)
                }
              >
                <Avatar
                  iconName="user"
                  size="20px"
                  iconSize="15px"
                  iconColor="#000"
                />
                <p className={Styles.studentName}>{el.name}</p>
                <Icon size={20} icon={"chevron-right"} />
              </div>
            ))}
          </div>
        </div>
        <div className={Styles.rightSection}>
          <div className={Styles.left}>
            <div className={Styles.imageWrapper}>
              {selectedStudentData?.profPic && (
                <img alt="" src={fileBaseUrl + selectedStudentData.profPic} />
              )}
            </div>
            <div>
              <div className={Styles.textWrapper}>
                <p>Nilai {semester === "1" ? "Semester 1" : "Akhir"}</p>
                <Icon icon={"message-question"} size={16} color={"#9360A8"} />
              </div>
              <Input
                value={selectedStudentData?.score}
                max={100}
                isNumber
                setValue={(e) => handleChange("score", "", e)}
              />
            </div>
          </div>
          <div className={Styles.right}>
            <div className={Styles.studentInfo}>
              <div className={Styles.textWrapper}>
                <p>Nama</p>
                <p>{selectedStudentData?.name}</p>
              </div>
              <div className={Styles.textWrapper}>
                <p>Nilai Semester 1</p>
                <p>{selectedStudentData?.smtI?.systemScore}</p>
              </div>
            </div>
            <div className={Styles.divider} />
            <div className={Styles.studentScores}>
              {selectedStudentData?.report?.map((el, idx) => (
                <React.Fragment key={idx}>
                  {idx ? <div className={Styles.divider} /> : ""}
                  <div className={Styles.score}>
                    <div className={Styles.textWrapper}>
                      <p>Nilai Bagian {idx + 1}</p>
                      <p>{el?.title}</p>
                    </div>
                    <p>{el.score}</p>
                  </div>
                </React.Fragment>
              ))}
            </div>

            <div className={Styles.inputWrapper}>
              <div>
                <p className={Styles.inputLabel}>
                  Deskripsi (Capaian Kompetensi)
                  <span style={{ color: "red" }}>*</span>
                </p>
                <div className={Styles.inputGuide}>
                  <p>Lihat Panduan Penulisan</p>
                  <Icon icon={"arrow-up-right"} size={12} color={"#9360A8"} />
                </div>
              </div>
              <Input
                isTextArea
                value={selectedStudentData?.competency?.description}
                setValue={(val) =>
                  handleChange("competency", "description", val)
                }
              />
            </div>
            <div className={Styles.inputWrapper}>
              <div>
                <p className={Styles.inputLabel}>
                  Tindakan
                  <span style={{ color: "red" }}>*</span>
                </p>
                <div className={Styles.inputGuide}>
                  <p>Lihat Panduan Penulisan</p>
                  <Icon icon={"arrow-up-right"} size={12} color={"#9360A8"} />
                </div>
              </div>
              <Input
                isTextArea
                value={selectedStudentData?.competency?.action}
                setValue={(val) => handleChange("competency", "action", val)}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={Styles.buttonWrapper}>
        <Button
          variant="outlined"
          text={loading ? "Menyimpan nilai..." :"Simpan Draft"}
          endIcon={"folder-open"}
          onClick={() => handleSubmitReport("DRAFT")}
          disabled={loading}
        />
        <Button
          text={loading ? "Mengirim nilai..." :"Kirim Nilai"}
          endIcon={"chevron-right"}
          onClick={() => handleSubmitReport("PUBLISH")}
          disabled={loading}
        />
      </div>
    </div>
  );
}
