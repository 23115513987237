import React, { useMemo } from "react";
import {
  Document,
  Page,
  View,
  Text,
  Image,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";
import Images from "@Theme/Images";
import poppinsRegular from "@Assets/fonts/Poppins-Regular.ttf";
import poppinsBold from "@Assets/fonts/Poppins-Bold.ttf";
import poppinsMedium from "@Assets/fonts/Poppins-Medium.ttf";
import poppinsSemiBold from "@Assets/fonts/Poppins-SemiBold.ttf";
import locationIcon from "@Assets/Images/location-logo.png";
import emailIcon from "@Assets/Images/mail.png";
import websiteIcon from "@Assets/Images/website.png";
import thsLogo from "@Assets/Images/ths-logo-inverted.png";
import { fileBaseUrl } from "@Config/index";

const classEnum = [
  { label: "1 SD", value: "SD Kelas 1", fase: "A", paket: "A SETARA SD" },
  { label: "2 SD", value: "SD Kelas 2", fase: "A", paket: "A SETARA SD" },
  { label: "3 SD", value: "SD Kelas 3", fase: "B", paket: "A SETARA SD" },
  { label: "4 SD", value: "SD Kelas 4", fase: "B", paket: "A SETARA SD" },
  { label: "5 SD", value: "SD Kelas 5", fase: "C", paket: "A SETARA SD" },
  { label: "6 SD", value: "SD Kelas 6", fase: "C", paket: "A SETARA SD" },
  {
    label: "7 SMP",
    value: "SMP Kelas 7",
    fase: "D",
    paket: "B SETARA SMP",
  },
  {
    label: "8 SMP",
    value: "SMP Kelas 8",
    fase: "D",
    paket: "B SETARA SMP",
  },
  {
    label: "9 SMP",
    value: "SMP Kelas 9",
    fase: "D",
    paket: "B SETARA SMP",
  },
  {
    label: "10 SMA",
    value: "SMA Kelas 10",
    fase: "E",
    paket: "C SETARA SMA",
  },
  {
    label: "11 SMA",
    value: "SMA Kelas 11",
    fase: "F",
    paket: "C SETARA SMA",
  },
  {
    label: "12 SMA",
    value: "SMA Kelas 12",
    fase: "F",
    paket: "C SETARA SMA",
  },
];

Font.register({
  family: "Poppins",
  fonts: [
    {
      src: poppinsRegular,
      fontWeight: 400,
    },
    {
      src: poppinsMedium,
      fontWeight: 500,
    },
    {
      src: poppinsSemiBold,
      fontWeight: 600,
    },
    {
      src: poppinsBold,
      fontWeight: 700,
    },
  ],
});

const styles = StyleSheet.create({
  page: {
    padding: 20,
    backgroundColor: "#F3EFFC",
  },
  container: {
    backgroundColor: "#FFFFFF",
    paddingHorizontal: 30,
    borderRadius: 10,
    minHeight: "93%",
    maxHeight: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: 24,
  },
  header: {
    textAlign: "center",
    fontFamily: "Poppins",
    fontSize: 20,
    fontWeight: 600,
    marginBottom: 80,
  },
  logo: {
    width: 147,
    height: 179,
    alignSelf: "center",
    marginBottom: 32,
  },
  textWithLogo: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "center",
    gap: 5,
  },
  section: {
    alignItems: "center",
    textAlign: "center",
    paddingTop: 4,
    paddingHorizontal: 10,
    borderBottom: "1px solid #C2C2C2",
    minWidth: 150,
    maxWidth: 400,
  },
  title: {
    fontSize: 12,
    fontWeight: "bold",
  },
  subtitle: {
    fontSize: 10,
  },
  footer: {
    marginTop: 80,
    textAlign: "center",
    fontSize: 8,
  },
  text10: {
    fontSize: 10,
    fontFamily: "Poppins",
  },
  text12: {
    fontSize: 12,
    fontFamily: "Poppins",
    lineHeight: "16px",
  },
  text14: {
    fontSize: 14,
    fontFamily: "Poppins",
  },
  text16: {
    fontSize: 16,
    fontFamily: "Poppins",
    lineHeight: "24px",
  },
  text20: {
    fontSize: 20,
    fontFamily: "Poppins",
  },
  font400: {
    fontWeight: 400,
  },
  font500: {
    fontWeight: 500,
  },
  font600: {
    fontWeight: 600,
  },
  font700: {
    fontWeight: 700,
  },
  colorGreen1: {
    color: "#415454",
  },
  colorGreen2: {
    color: "#7C8181",
  },
  textWrapper: {
    display: "flex",
    flexDirection: "row",
    gap: 10,
  },
  grayContainer: {
    backgroundColor: "#F5F5F5",
    padding: "6px 8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  whiteContainer: {
    backgroundColor: "#FFFF",
    padding: "6px 8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  borderRight: {
    borderRight: "1px solid #EDEDED",
  },
  borderLeft: {
    borderLeft: "1px solid #EDEDED",
  },
  borderTop: {
    borderTop: "1px solid #EDEDED",
  },
  borderBottom: {
    borderBottom: "1px solid #EDEDED",
  },
});

const schoolDetail = [
  { label: "Nama satuan Pendidikan", value: "PKBM TREE HOMESCHOOL" },
  { label: "NPSN", value: "P99998232" },
  { label: "Alamat", value: "Jl. Bunga Lely No.35" },
  { label: "Kelurahan", value: "Jatimulyo" },
  { label: "Kecamatan", value: "Lowokwaru" },
  { label: "Kota", value: "Malang" },
  { label: "Provinsi", value: "Jawa Timur" },
  { label: "Website", value: "www.treehomeschool.com" },
  { label: "Email", value: "tree.homeschool@gmail.com" },
];

function findLevelByValue(value) {
  return classEnum.find((level) => level.value === value) || null;
}

const ReportCard = ({
  pancasilaValue,
  studentDetail,
  studentScores,
  semester,
  academicYear,
}) => {
  const studentInfo = useMemo(() => {
    return [
      { label: "Nama Satuan Pendidikan", value: "Tree Homeschool" },
      { label: "Alamat", value: "Jl. Bunga Lely No.35" },
      { label: "Nama Peserta Didik", value: studentDetail.name },
      { label: "Nomor Induk/NISN", value: studentDetail.idNumber },
      {
        label: "Kelas",
        value: findLevelByValue(studentDetail.grade)?.label ?? "-",
      },
      { label: "Semester", value: semester },
      {
        label: "Fase",
        value: findLevelByValue(studentDetail.grade)?.fase ?? "-",
      },
      { label: "Tahun Ajaran", value: academicYear },
    ];
  }, [studentDetail, academicYear, semester]);

  const studentData = useMemo(() => {
    const guardian = studentDetail.guardians.find(
      (el) => el.role === "GUARDIAN"
    );
    const father = studentDetail.guardians.find((el) => el.role === "FATHER");
    const mother = studentDetail.guardians.find((el) => el.role === "MOTHER");
    return [
      { label: "Nama Peserta Didik", value: studentDetail.name },
      { label: "NISN/NIS", value: studentDetail.idNumber },
      { label: "Tempat, Tanggal Lahir", value: studentDetail.placeOfBirth },
      {
        label: "Jenis Kelamin",
        value: studentDetail.gender === "FEMALE" ? "Perempuan" : "Laki-laki",
      },
      { label: "Agama", value: studentDetail.religion },
      { label: "Anak ke", value: studentDetail.childOrder },
      { label: "Telepon", value: studentDetail.phoneNumber },
      { label: "Alamat Peserta Didik", value: studentDetail.address },

      {
        label: "Diterima di Sekolah ini",
        children: [
          {
            label: "Di kelas",
            value:
              findLevelByValue(studentDetail.acceptanceYear.grade)?.label ??
              "-",
          },
          {
            label: "Pada tahun",
            value: studentDetail.acceptanceYear.acceptanceYear,
          },
          { label: "Sebagai", value: "Peserta didik baru" },
        ],
      },

      {
        label: "Nama Orang Tua",
        children: [
          { label: "Ayah", value: father?.name ?? "-" },
          { label: "Ibu", value: mother?.name ?? "-" },
        ],
      },

      {
        label: "Pekerjaan Orang Tua",
        children: [
          { label: "Ayah", value: father?.occupation ?? "-" },
          { label: "Ibu", value: mother?.occupation ?? "-" },
        ],
      },

      {
        label: "Wali Peserta Didik",
        children: [
          {
            label: "Nama",
            value: guardian?.name ?? "-",
          },
          {
            label: "Pekerjaan",
            value: guardian?.occupation ?? "-",
          },
        ],
      },
    ];
  }, [studentDetail]);

  const userDetail = useMemo(() => {
    return (
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          gap: 24,
        }}
      >
        <View style={{ flex: 1, display: "flex", gap: 8 }}>
          {studentInfo.slice(0, 4).map((el, idx) => (
            <View style={[styles.textWrapper, { gap: 4 }]} key={idx}>
              <Text style={[styles.text10, styles.font500, { width: 140 }]}>
                {el.label}
              </Text>
              <Text style={[styles.text10, styles.font400]}>:</Text>
              <Text style={[styles.text10, styles.font400]}>{el.value}</Text>
            </View>
          ))}
        </View>
        <View style={{ display: "flex", gap: 8 }}>
          {studentInfo.slice(4, 8).map((el, idx) => (
            <View style={[styles.textWrapper, { gap: 4 }]} key={idx + 4}>
              <Text style={[styles.text10, styles.font500, { width: 95 }]}>
                {el.label}
              </Text>
              <Text style={[styles.text10, styles.font400]}>:</Text>
              <Text style={[styles.text10, styles.font400, { width: 88 }]}>
                {el.value}
              </Text>
            </View>
          ))}
        </View>
      </View>
    );
  }, [studentInfo]);

  // return (
  //   <div><p>test</p></div>
  // )

  return (
    <Document>
      {/* Page 1 */}
      <Page size="LEGAL" style={styles.page}>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 8,
            alignItems: "center",
            marginBottom: 20,
          }}
          fixed
        >
          <Image src={thsLogo} style={{ width: 28, height: 33 }} />
          <View style={{ display: "flex" }}>
            <Text style={[styles.text12, styles.font600, styles.colorGreen1]}>
              Tree Homeschool
            </Text>
            <Text style={[styles.text10, styles.font400, styles.colorGreen2]}>
              www.treehomeschool.sch.id
            </Text>
          </View>
        </View>
        <View style={styles.container}>
          <Text style={styles.header}>
            LAPORAN HASIL BELAJAR PESERTA DIDIK PROGRAM {findLevelByValue(studentDetail.grade)?.paket ?? "-"}
          </Text>
          <Image style={styles.logo} src={Images.THS_LOGO} />
          <View
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "12px",
              alignItems: "center",
            }}
          >
            <View style={styles.section}>
              <Text style={[styles.text20, styles.font600]}>
                Nama Peserta Didik
              </Text>
              <Text style={[styles.text14, styles.font400]}>
                {studentDetail.name}
              </Text>
            </View>
            <View style={[styles.section]}>
              <Text style={[styles.text20, styles.font600]}>NISN</Text>
              <Text style={[styles.text14, styles.font400]}>
                {studentDetail.idNumber}
              </Text>
            </View>
          </View>
          <View style={styles.footer}>
            <Text style={[styles.font600, styles.text12]}>
              YAYASAN PENDIDIKAN PRANATA SETIAWAN (YPPS)
            </Text>
            <Text style={[styles.font600, styles.text12]}>
              PUSAT KEGIATAN BELAJAR MASYARAKAT TREE HOMESCHOOL
            </Text>
            <Text style={[styles.font600, styles.text12]}>NPSN: 10000</Text>
            <View style={[styles.textWithLogo, { marginTop: 12 }]}>
              <Image src={locationIcon} style={{ width: 16, height: 16 }} />
              <Text style={[styles.text10, styles.font400]}>
                Jl. Bunga Lely No.35, Kel. Jatimulyo, Kec. Lowokwaru, Kota
                Malang, Jawa Timur
              </Text>
            </View>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 12,
                justifyContent: "center",
                alignItems: "center",
                marginTop: 4,
              }}
            >
              <View style={styles.textWithLogo}>
                <Image src={emailIcon} style={{ width: 16, height: 16 }} />
                <Text style={[styles.text10, styles.font400]}>
                  tree.homeschool@gmail.com
                </Text>
              </View>
              <View style={styles.textWithLogo}>
                <Image src={websiteIcon} style={{ width: 16, height: 16 }} />
                <Text style={[styles.text10, styles.font400]}>
                  www.treehomeschool.com
                </Text>
              </View>
            </View>
          </View>
        </View>
      </Page>

      {/* Page 2 */}
      <Page size="LEGAL" style={styles.page}>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 8,
            alignItems: "center",
            marginBottom: 20,
          }}
          fixed
        >
          <Image src={thsLogo} style={{ width: 28, height: 33 }} />
          <View style={{ display: "flex" }}>
            <Text style={[styles.text12, styles.font600, styles.colorGreen1]}>
              Tree Homeschool
            </Text>
            <Text style={[styles.text10, styles.font400, styles.colorGreen2]}>
              https://www.treehomeschool.sch.id/
            </Text>
          </View>
        </View>
        <View style={styles.container}>
          <Text style={styles.header}>IDENTITAS SEKOLAH</Text>
          <Image style={styles.logo} src={Images.THS_LOGO} />
          <View style={{ display: "flex", gap: 12, marginTop: 48 }}>
            {schoolDetail.map((el, idx) => (
              <View key={idx} style={styles.textWrapper}>
                <Text style={[styles.text12, styles.font500, { width: 200 }]}>
                  {el.label}
                </Text>
                <Text style={[styles.text12, styles.font400]}>:</Text>
                <Text style={[styles.text12, styles.font400]}>{el.value}</Text>
              </View>
            ))}
          </View>
        </View>
      </Page>

      {/* Page 3 */}
      <Page size="LEGAL" style={styles.page}>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 8,
            alignItems: "center",
            marginBottom: 20,
          }}
          fixed
        >
          <Image src={thsLogo} style={{ width: 28, height: 33 }} />
          <View style={{ display: "flex" }}>
            <Text style={[styles.text12, styles.font600, styles.colorGreen1]}>
              Tree Homeschool
            </Text>
            <Text style={[styles.text10, styles.font400, styles.colorGreen2]}>
              https://www.treehomeschool.sch.id/
            </Text>
          </View>
        </View>
        <View style={styles.container}>
          <Text style={[styles.header, { marginBottom: 0 }]}>
            IDENTITAS SEKOLAH
          </Text>
          <View style={{ display: "flex", gap: 12, marginTop: 40 }}>
            {studentData.map((el, idx) =>
              el.children ? (
                <View style={{ display: "flex", gap: 8 }} key={idx}>
                  <Text style={[styles.text12, styles.font500, { width: 192 }]}>
                    {el.label}
                  </Text>
                  {el.children.map((child, childIdx) => (
                    <View
                      key={childIdx}
                      style={[styles.textWrapper, { marginLeft: 8 }]}
                    >
                      <Text
                        style={[styles.text12, styles.font400, { width: 192 }]}
                      >
                        {child.label}
                      </Text>
                      <Text style={[styles.text12, styles.font400]}>:</Text>
                      <Text
                        style={[styles.text12, styles.font400, { width: 250 }]}
                      >
                        {child.value}
                      </Text>
                    </View>
                  ))}
                </View>
              ) : (
                <View key={idx} style={styles.textWrapper}>
                  <Text style={[styles.text12, styles.font500, { width: 200 }]}>
                    {el.label}
                  </Text>
                  {el.value && (
                    <>
                      <Text style={[styles.text12, styles.font400]}>:</Text>
                      <Text
                        style={[styles.text12, styles.font400, { width: 250 }]}
                      >
                        {el.value}
                      </Text>
                    </>
                  )}
                </View>
              )
            )}
          </View>
          <View
            style={{
              display: "flex",
              marginTop: 32,
              gap: 90,
              flexDirection: "row",
            }}
          >
            <View>
              <Image
                src={fileBaseUrl + studentDetail.profilePicture}
                style={{ height: 140 }}
              />
            </View>
            <View style={{ display: "flex", gap: 2 }}>
              <Text style={[styles.text10, styles.font400]}>
                Mataram, 14 Juni 2023
              </Text>
              <Text style={[styles.text10, styles.font400]}>
                Kepala SKB/Ketua PKBM,
              </Text>
              <Image
                src={Images.SIGNATURE}
                style={{ width: 148, height: 70 }}
              />
              <Text style={[styles.text10, styles.font500]}>
                Sugianto Simodihardjo
              </Text>
            </View>
          </View>
        </View>
      </Page>

      {/* Page 4 */}
      <Page size="LEGAL" style={styles.page}>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 8,
            alignItems: "center",
            marginBottom: 20,
          }}
          fixed
        >
          <Image src={thsLogo} style={{ width: 28, height: 33 }} />
          <View style={{ display: "flex" }}>
            <Text style={[styles.text12, styles.font600, styles.colorGreen1]}>
              Tree Homeschool
            </Text>
            <Text style={[styles.text10, styles.font400, styles.colorGreen2]}>
              https://www.treehomeschool.sch.id/
            </Text>
          </View>
        </View>
        <View style={[styles.container, { justifyContent: "flex-start" }]}>
          <View style={{ display: "flex", gap: 16 }}>
            {userDetail}
            <View style={[styles.grayContainer, { alignItems: "flex-start" }]}>
              <Text style={[styles.text14, styles.font600]}>
                A. Lembar Isi Mata Pelajaran
              </Text>
            </View>
            {/* Table */}
            <View>
              {/* Table Head */}
              {/* width : 41, 215, 70, rest */}
              <View style={{ display: "flex", flexDirection: "row" }}>
                <View
                  style={[
                    styles.grayContainer,
                    styles.borderRight,
                    { width: 41 },
                  ]}
                >
                  <Text style={[styles.font500, styles.text10]}>No</Text>
                </View>
                <View
                  style={[
                    styles.grayContainer,
                    styles.borderRight,
                    { width: 220 },
                  ]}
                >
                  <Text style={[styles.font500, styles.text10]}>
                    {`Mata Pelajaran/Program
              Pemberdayaan dan Keterampilan`}
                  </Text>
                </View>
                <View
                  style={[
                    styles.grayContainer,
                    styles.borderRight,
                    { width: 70 },
                  ]}
                >
                  <Text style={[styles.font500, styles.text10]}>
                    Nilai Akhir
                  </Text>
                </View>
                <View style={[styles.grayContainer, { flex: 1 }]}>
                  <Text style={[styles.font500, styles.text10]}>
                    Capaian Kopetensi
                  </Text>
                </View>
              </View>
              {/* Table subHead */}
              <View
                style={[
                  styles.grayContainer,
                  styles.borderTop,
                  styles.borderBottom,
                  { alignItems: "flex-start" },
                ]}
              >
                <Text style={[styles.text10, styles.font500]}>
                  Kelompok Mata Pelajaran Umum
                </Text>
              </View>
              {/* Table Body */}
              <View>
                {studentScores.map((el, idx) => (
                  <View
                    style={[
                      styles.borderBottom,
                      { display: "flex", flexDirection: "row" },
                    ]}
                    key={idx}
                    break={false}
                    wrap={false}
                  >
                    <View
                      style={[
                        styles.whiteContainer,
                        styles.borderRight,
                        { width: 41 },
                      ]}
                    >
                      <Text style={[styles.font400, styles.text10]}>
                        {idx + 1}
                      </Text>
                    </View>
                    <View
                      style={[
                        styles.whiteContainer,
                        styles.borderRight,
                        { width: 220, alignItems: "flex-start" },
                      ]}
                    >
                      <Text style={[styles.font400, styles.text10]}>
                        {el.subjectName}
                      </Text>
                    </View>
                    <View
                      style={[
                        styles.whiteContainer,
                        styles.borderRight,
                        { width: 70 },
                      ]}
                    >
                      <Text style={[styles.font400, styles.text10]}>
                        {el.score}
                      </Text>
                    </View>
                    <View style={[styles.whiteContainer, { flex: 1 }]}>
                      <Text style={[styles.font400, styles.text10]}>
                        {el.competency.description}
                      </Text>
                    </View>
                  </View>
                ))}
              </View>

              {/* Signature */}
              <View
                style={{
                  display: "flex",
                  alignItems: "flex-end",
                  paddingTop: 24,
                }}
              >
                <View style={{ display: "flex", gap: 2 }}>
                  <Text style={[styles.text10, styles.font400]}>
                    Mataram, 14 Juni 2023
                  </Text>
                  <Text style={[styles.text10, styles.font400]}>
                    Kepala SKB/Ketua PKBM,
                  </Text>
                  <Image
                    src={Images.SIGNATURE}
                    style={{ width: 148, height: 70 }}
                  />

                  <Text style={[styles.text10, styles.font500]}>
                    Sugianto Simodihardjo
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>
      </Page>

      {/* Page 5 */}
      <Page size="LEGAL" style={styles.page}>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 8,
            alignItems: "center",
            marginBottom: 20,
          }}
          fixed
        >
          <Image src={thsLogo} style={{ width: 28, height: 33 }} />
          <View style={{ display: "flex" }}>
            <Text style={[styles.text12, styles.font600, styles.colorGreen1]}>
              Tree Homeschool
            </Text>
            <Text style={[styles.text10, styles.font400, styles.colorGreen2]}>
              https://www.treehomeschool.sch.id/
            </Text>
          </View>
        </View>
        <View style={[styles.container, { justifyContent: "flex-start" }]}>
          <View style={{ display: "flex", gap: 16 }}>
            {userDetail}
            <View style={[styles.grayContainer, { alignItems: "flex-start" }]}>
              <Text style={[styles.text14, styles.font600]}>
                B. Lembar Isi Capaian Dimensi Profil Pelajar Pancasila pada
                Program Pemberdayaan dan Keterampilan
              </Text>
            </View>
            {/* Table */}
            <View>
              {/* width : 42, 333, rest */}
              {/* Table Body */}
              <View style={[styles.borderTop]}>
                {pancasilaValue.studentDimension.map((el, idx) => (
                  <View key={idx}>
                    <View
                      style={[
                        styles.borderBottom,
                        { display: "flex", flexDirection: "row" },
                      ]}
                      break={false}
                      wrap={false}
                    >
                      <View
                        style={[
                          styles.whiteContainer,
                          styles.borderRight,
                          {
                            width: 41,
                            borderTop: `${idx ? "1px solid #EDEDED" : ""}`,
                          },
                        ]}
                      >
                        <Text style={[styles.font500, styles.text10]}>
                          {idx + 1}
                        </Text>
                      </View>
                      <View
                        style={[
                          styles.whiteContainer,
                          styles.borderRight,
                          { width: 333, alignItems: "flex-start" },
                        ]}
                      >
                        <Text style={[styles.font500, styles.text10]}>
                          {el.type}
                        </Text>
                      </View>
                      <View style={[styles.whiteContainer, { flex: 1 }]}>
                        <Text style={[styles.font500, styles.text10]}>
                          Penilaian
                        </Text>
                      </View>
                    </View>
                    <View style={{ paddingLeft: 40 }}>
                      {el.items.map((item, itemIdx) => (
                        <View
                          style={[styles.borderLeft]}
                          key={itemIdx}
                          break={false}
                          wrap={false}
                        >
                          <View
                            style={[
                              styles.borderBottom,
                              { display: "flex", flexDirection: "row" },
                            ]}
                            break={false}
                            wrap={false}
                          >
                            <View
                              style={[
                                styles.grayContainer,
                                styles.borderRight,
                                { width: 333, alignItems: "flex-start" },
                              ]}
                            >
                              <Text style={[styles.font500, styles.text10]}>
                                {item.el}
                              </Text>
                            </View>
                            <View
                              style={[
                                {
                                  flex: 1,
                                  display: "flex",
                                  flexDirection: "row",
                                },
                              ]}
                            >
                              <View
                                style={[styles.whiteContainer, { flex: 1 }]}
                              >
                                <Text style={[styles.text10, styles.font500]}>
                                  MB
                                </Text>
                              </View>
                              <View
                                style={[
                                  styles.borderLeft,
                                  styles.whiteContainer,
                                  { flex: 1 },
                                ]}
                              >
                                <Text style={[styles.text10, styles.font500]}>
                                  SB
                                </Text>
                              </View>
                              <View
                                style={[
                                  styles.borderLeft,
                                  styles.whiteContainer,
                                  { flex: 1 },
                                ]}
                              >
                                <Text style={[styles.text10, styles.font500]}>
                                  BSH
                                </Text>
                              </View>
                              <View
                                style={[
                                  styles.borderLeft,
                                  styles.whiteContainer,
                                  { flex: 1 },
                                ]}
                              >
                                <Text style={[styles.text10, styles.font500]}>
                                  SAB
                                </Text>
                              </View>
                            </View>
                          </View>
                          {item.questions.map((question, questionIdx) => (
                            <View
                              style={[
                                styles.borderBottom,
                                { display: "flex", flexDirection: "row" },
                              ]}
                              key={questionIdx}
                            >
                              <View
                                style={[
                                  styles.whiteContainer,
                                  styles.borderRight,
                                  { width: 333, alignItems: "flex-start" },
                                ]}
                              >
                                <Text style={[styles.font500, styles.text10]}>
                                  {question.label}
                                </Text>
                              </View>
                              <View
                                style={[
                                  {
                                    flex: 1,
                                    display: "flex",
                                    flexDirection: "row",
                                  },
                                ]}
                              >
                                {["MB", "SB", "BSH", "SAB"].map(
                                  (option, optionIdx) => (
                                    <View
                                      style={[
                                        styles.whiteContainer,
                                        {
                                          flex: 1,
                                          borderLeft: `${
                                            optionIdx ? "1px solid #DEDEDE" : ""
                                          }`,
                                        },
                                      ]}
                                      key={optionIdx}
                                    >
                                      <Text
                                        style={[styles.text12, styles.font00]}
                                      >
                                        {option === question.option ? "V" : ""}
                                      </Text>
                                    </View>
                                  )
                                )}
                              </View>
                            </View>
                          ))}
                        </View>
                      ))}
                      <View wrap={false}>
                        <View
                          style={[
                            styles.borderBottom,
                            styles.borderLeft,

                            styles.whiteContainer,
                            {
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                              alignItems: "center",
                            },
                          ]}
                        >
                          <Text style={[styles.text10, styles.font500]}>
                            Catatan Proses
                          </Text>
                        </View>
                        <View
                          style={[
                            styles.borderBottom,
                            styles.borderLeft,
                            styles.whiteContainer,
                            { alignItems: "flex-start" },
                          ]}
                        >
                          <Text style={[styles.text10, styles.font500]}>
                            {el.remark}
                          </Text>
                        </View>
                      </View>
                    </View>
                  </View>
                ))}
              </View>

              {/* Signature */}
              <View
                style={{
                  display: "flex",
                  alignItems: "flex-end",
                  paddingTop: 24,
                }}
                wrap={false}
                break
              >
                <View style={{ display: "flex", gap: 2 }}>
                  <Text style={[styles.text10, styles.font400]}>
                    Mataram, 14 Juni 2023
                  </Text>
                  <Text style={[styles.text10, styles.font400]}>
                    Kepala SKB/Ketua PKBM,
                  </Text>
                  <Image
                    src={Images.SIGNATURE}
                    style={{ width: 148, height: 70 }}
                  />
                  <Text style={[styles.text10, styles.font500]}>
                    Sugianto Simodihardjo
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>
      </Page>

      {/* Page 6 */}
      <Page size="LEGAL" style={styles.page}>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 8,
            alignItems: "center",
            marginBottom: 20,
          }}
          fixed
        >
          <Image src={thsLogo} style={{ width: 28, height: 33 }} />
          <View style={{ display: "flex" }}>
            <Text style={[styles.text12, styles.font600, styles.colorGreen1]}>
              Tree Homeschool
            </Text>
            <Text style={[styles.text10, styles.font400, styles.colorGreen2]}>
              https://www.treehomeschool.sch.id/
            </Text>
          </View>
        </View>
        <View style={[styles.container, { justifyContent: "flex-start" }]}>
          <View style={{ display: "flex", gap: 24 }}>
            {userDetail}
            <View style={{ display: "flex", gap: 16 }}>
              {pancasilaValue.finalNote.map((el, idx) => (
                <View key={idx}>
                  <View
                    style={[
                      styles.grayContainer,
                      styles.borderTop,
                      styles.borderBottom,
                      { alignItems: "flex-start" },
                    ]}
                  >
                    <Text style={[styles.text10, styles.font500]}>
                      {el.type}
                    </Text>
                  </View>
                  <View style={[styles.borderBottom, { padding: 8 }]}>
                    <Text style={[styles.text10, styles.font400]}>
                      {el.answer}
                    </Text>
                  </View>
                </View>
              ))}
            </View>
          </View>
          <View
            style={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "space-between",
              flexDirection: "row",
              paddingTop: 40,
            }}
          >
            <View style={{ display: "flex", gap: 2, width: 140 }}>
              <Text style={[styles.text10, styles.font400]}>Mengetahui:</Text>
              <Text style={[styles.text10, styles.font400]}>
                Orang Tua/Wali
              </Text>
              <View style={{ width: 148, height: 70 }} />
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Text style={[styles.text10, styles.font500]}>(</Text>
                <Text style={[styles.text10, styles.font500]}>)</Text>
              </View>
            </View>
            <View style={{ display: "flex", gap: 2, width: 140 }}>
              <Text style={[styles.text10, styles.font400]}>Mengetahui:</Text>
              <Text style={[styles.text10, styles.font400]}>
                Kepala SKB/Ketua PKBM,
              </Text>
              <Image
                src={Images.SIGNATURE}
                style={{ width: 148, height: 70 }}
              />
              <Text style={[styles.text10, styles.font500]}>
                Sugianto Simodihardjo
              </Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};
export default ReportCard;
