import Stepper from "@Molecule/Stepper";
import React, { useEffect, useMemo, useState } from "react";
import Styles from "./style.module.scss";
import Button from "@Atom/Button";
import DoodleGradientCard from "@Atom/DoodleGradientCard";
import Avatar from "@Atom/Avatar";
import Icon from "@Atom/Icon";
import Input from "@Atom/Input";
import RadioButton from "@Atom/RadioButton";
import { pdf, PDFViewer } from "@react-pdf/renderer";
import ReportCard from "@Molecule/StudentReportPdf";
import { useNavigate, useParams } from "react-router-dom";
import {
  _publishStudentReport,
  _uploadStudentReport,
  fetchDataReport,
} from "@Services/admin";

import FileUploadSuccess from "@Molecule/FileUploadSuccessModal";
import Modal from "@Atom/Modal";
import { fileBaseUrl } from "@Config/index";

const studentAchievement = {
  dimensions: [
    {
      type: "Dimensi Beriman, Bertaqwa kepada Tuhan Yang Maha Esa, dan Berakhlak Mulia",
      items: [
        {
          el: "Elemen akhlak beragama",
          questions: [
            {
              label:
                "Terbiasa melaksanakan ibadah sesuai ajaran agama/kepercayaannya.",
              option: "",
            },
          ],
        },
        {
          el: "Elemen akhlak pribadi",
          questions: [
            {
              label:
                "Membiasakan bersikap jujur terhadap diri sendiri dan orang lain dan berani menyampaikan kebenaran atau fakta.",
              option: "",
            },
          ],
        },
        {
          el: "Elemen akhlak kepada manusia",
          questions: [
            {
              label:
                "Mengenali hal-hal yang sama dan berbeda yang dimiliki diri dan temannya dalam berbagai hal, serta memberikan respons secara positif.",
              option: "",
            },
          ],
        },
      ],
      remark: "",
    },
    {
      type: "Dimensi Mandiri",
      items: [
        {
          el: "Elemen pemahaman diri dan situasi yang dihadapi",
          questions: [
            {
              label:
                "Mengidentifikasi dan menggambarkan kemampuan, prestasi, dan ketertarikannya secara subjektif.",
              option: "",
            },
            {
              label:
                "Melakukan refleksi untuk mengidentifikasi kekuatan dan kelemahan, serta prestasi dirinya.",
              option: "",
            },
          ],
        },
        {
          el: "Elemen regulasi diri",
          questions: [
            {
              label:
                "Mengidentifikasi perbedaan emosi yang dirasakannya dan situasi-situasi yang menyebabkannya; serta mengekspresikan secara wajar.",
              option: "",
            },
          ],
        },
      ],
      remark: "",
    },
  ],
  finalNote: [
    {
      type: "Catatan Perkembangan Profil Pelajar Pancasila",
      answer: "",
    },
    {
      type: "Catatan Perkembangan Pemberdayaan",
      answer: "",
    },
    {
      type: "Catatan Perkembangan Keterampilan",
      answer: "",
    },
  ],
};

const achievementOptions = ["MB", "SB", "BSH", "SAB"];

export default function StudentReportLayout() {
  const [activeStep, setActiveStep] = useState(1);
  const [semester, setSemester] = useState(1);
  const [studentData, setStudentData] = useState(null);
  const [subjectData, setSubjectData] = useState([]);
  const [studyContinuation, setStudyContinuation] = useState([]);
  const [assessments, setAssessments] = useState(studentAchievement.dimensions);
  const [finalNotes, setFinalNotes] = useState(studentAchievement.finalNote);
  const { id, academicYear } = useParams();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [successUpload, setSuccessUpload] = useState(false);

  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await fetchDataReport(id, academicYear);
        setSubjectData(data.subjects);
        setStudentData({ ...data.user, gradeID: data._id });
        setSemester(data.semester);
        setStudyContinuation([
          {
            no: 1,
            question: `Apakah siswa atas nama ${data.user.name} naik kelas?`,
            option: "",
          },
          {
            no: 2,
            question:
              "Apakah siswa melanjutkan belajar di THS di tahun ajaran berikutnya?",
            option: "",
          },
        ]);
      } catch (error) {
        console.log(error);
      }
    };
    getData();
  }, [academicYear, id]);

  const validateStudentAchievement = () => {
    const errors = [];

    assessments.forEach((dimension, dimIndex) => {
      dimension.items.forEach((item, itemIndex) => {
        item.questions.forEach((question, qIndex) => {
          if (question.option === "") {
            errors.push(
              `${dimension.type} Elemen ${itemIndex + 1} wajib Diisi`
            );
          }
        });
      });

      if (dimension.remark === "") {
        errors.push(`Catatan Proses ${dimIndex + 1} wajib diisi`);
      }
    });

    finalNotes.forEach((note, noteIndex) => {
      if (note.answer === "") {
        errors.push(`Catatan ${note.type} wajib diisi`);
      }
    });
    return errors;
  };

  const steps = useMemo(() => {
    return [
      {
        title: "Penilaian Mata Pelajaran",
        key: 1,
        isCompleted: true,
        // isCompleted: !disableNext(0),
      },
      {
        title: "Capaian Profil Pelajar Pancasila",
        key: 2,
        isCompleted: validateStudentAchievement().length === 0,
        // isCompleted: !disableNext(1),
        // disabled: disableNext(0) ? true : false,
      },
      {
        title: "Review",
        key: 3,
        // isCompleted: !disableNext(2),
        // disabled: validateStudentAchievement().length,
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assessments, finalNotes]);
  const handleClickStep = (step) => {
    setActiveStep(step);
  };

  const updateAssessment = (updatedData) => {
    setAssessments((prev) =>
      prev.map((dim) => (dim.type === updatedData.type ? updatedData : dim))
    );
  };

  const handleChangeSubjectData = (idx, key, value, subChild) => {
    const temp = [...subjectData];
    if (subChild) {
      temp[idx][subChild][key] = value;
    } else {
      temp[idx][key] = value;
    }
    setSubjectData(temp);
  };
  const handleChangeFinalNotes = (idx, value) => {
    const temp = [...finalNotes];
    temp[idx]["answer"] = value;
    setFinalNotes(temp);
  };

  const template = useMemo(() => {
    switch (activeStep) {
      case 1:
        return (
          <div className={Styles.stepWrapper}>
            <p>A. Penilaian Mata Pelajaran</p>
            {subjectData.map((el, idx) => (
              <div className={Styles.assessmentCard} key={idx}>
                <p>
                  {idx + 1}. {el.subjectName}
                </p>
                <div className={Styles.score}>
                  <p>Nilai Siswa</p>
                  <Input
                    isNumber
                    value={el.score}
                    setValue={(val) =>
                      handleChangeSubjectData(idx, "score", val)
                    }
                  />
                  {/* <p>{el.score}</p> */}
                </div>
                <div className={Styles.inputContainer}>
                  <div className={Styles.inputWrapper}>
                    <div>
                      <p className={Styles.inputLabel}>
                        Deskripsi (Capaian Kompetensi)
                        <span style={{ color: "red" }}>*</span>
                      </p>
                      <div className={Styles.inputGuide}>
                        <p>Lihat Panduan Penulisan</p>
                        <Icon
                          icon={"arrow-up-right"}
                          size={12}
                          color={"#9360A8"}
                        />
                      </div>
                    </div>
                    <Input
                      isTextArea
                      value={el.competency.description}
                      setValue={(val) =>
                        handleChangeSubjectData(
                          idx,
                          "description",
                          val,
                          "competency"
                        )
                      }
                    />
                  </div>
                  <div className={Styles.inputWrapper}>
                    <div>
                      <p className={Styles.inputLabel}>
                        Tindakan
                        <span style={{ color: "red" }}>*</span>
                      </p>
                      <div className={Styles.inputGuide}>
                        <p>Lihat Panduan Penulisan</p>
                        <Icon
                          icon={"arrow-up-right"}
                          size={12}
                          color={"#9360A8"}
                        />
                      </div>
                    </div>
                    <Input
                      isTextArea
                      value={el.competency.action}
                      setValue={(val) =>
                        handleChangeSubjectData(
                          idx,
                          "action",
                          val,
                          "competency"
                        )
                      }
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        );
      case 2:
        return (
          <div className={Styles.stepWrapper}>
            <p>B. Capaian Profil Pelajar Pancasila</p>
            {assessments.map((dimension, index) => (
              <AssessmentCard
                key={index}
                data={dimension}
                updateAssessment={updateAssessment}
              />
            ))}
            <div className={Styles.assessmentCard}>
              <p>Catatan Akhir</p>
              {finalNotes.map((el, idx) => (
                <div className={Styles.inputWrapper}>
                  <div>
                    <p className={Styles.inputLabel}>
                      {el.type}
                      <span style={{ color: "red" }}>*</span>
                    </p>
                  </div>
                  <Input
                    isTextArea
                    value={el.answer}
                    setValue={(e) => handleChangeFinalNotes(idx, e)}
                  />
                </div>
              ))}
            </div>
            {semester === "smtII" && (
              <div className={Styles.stepWrapper}>
                <p>C. Kelanjutan Studi Siswa</p>
                <StudyContinuationCard
                  data={studyContinuation}
                  setStudyContinuation={setStudyContinuation}
                />
              </div>
            )}
          </div>
        );
      default:
        return (
          <div>
            <PDFViewer width="100%" height="600">
              <ReportCard
                studentDetail={studentData}
                studentScores={subjectData}
                pancasilaValue={{
                  studentDimension: assessments,
                  finalNote: finalNotes,
                }}
                semester={semester === "smtI" ? 1 : 2}
                academicYear={academicYear.split("-").join("/")}
              />
            </PDFViewer>
          </div>
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    activeStep,
    assessments,
    subjectData,
    finalNotes,
    studentData,
    studyContinuation,
  ]);

  useEffect(() => {
    window.scrollTo({ behavior: "smooth", top: 0 });
  }, [activeStep]);

  const publishReport = async () => {
    let dataToSend = {
      finalNote: finalNotes,
      studentDimension: assessments,
      competency: subjectData.map((el) => ({
        subject: el.subjectName,
        action: el.competency.action,
        description: el.competency.description,
        updatedAverage: el.score,
      })),
      semester: semester,
      studyContinuation,
    };
    try {
      setLoading(true);
      await _publishStudentReport(studentData.gradeID, dataToSend);

      const blob = await pdf(
        <ReportCard
          studentDetail={studentData}
          studentScores={subjectData}
          pancasilaValue={{
            studentDimension: assessments,
            finalNote: finalNotes,
          }}
          semester={semester === "smtI" ? 1 : 2}
          academicYear={academicYear.split("-").join("/")}
        />
      ).toBlob();

      const file = new File([blob], "document.pdf", {
        type: "application/pdf",
      });

      await _uploadStudentReport(studentData.gradeID, semester, file);
      setLoading(false);
      navigate(-1);
      setSuccessUpload(true);
    } catch (error) {
      setLoading(false);
      setError(error.response.data.message);
      console.log(error.response.data.message);
    }
  };

  const handleNext = () => {
    if (activeStep === 1) {
      setActiveStep(activeStep + 1);
    }
    if (activeStep === 2) {
      const errors = validateStudentAchievement();
      if (errors.length > 0) {
        setError(errors[0]);
      } else {
        setActiveStep(activeStep + 1);
      }
    }
    if (activeStep === 3) {
      publishReport();
    }
  };

  useEffect(() => {
    setError("");
  }, [activeStep, subjectData, assessments, finalNotes, studyContinuation]);

  return (
    <div className={Styles.container}>
      <div className={Styles.stepperWrapper}>
        <Stepper
          arr={steps?.map((obj) => {
            return {
              ...obj,
              isActive: obj?.key === activeStep,
              onClick: () => handleClickStep(obj?.key),
            };
          })}
        />
      </div>
      <div className={Styles.bodyWrapper}>
        <Button
          text={"Keluar"}
          variant="text"
          startIcon={"chevron-left"}
          onClick={() => navigate(-1)}
        />
        <div className={Styles.contentWrapper}>
          <div className={Styles.content}>
            {activeStep !== 3 && (
              <React.Fragment>
                <div className={Styles.containerStudent}>
                  <DoodleGradientCard className={Styles.header} />
                  <div className={Styles.bodyWrapper}>
                    <Avatar
                      iconName="user"
                      size="160px"
                      iconSize="120px"
                      iconColor="#000"
                      borderRadius="50%"
                      imgUrl={
                        studentData?.profilePicture
                          ? fileBaseUrl + studentData?.profilePicture
                          : ""
                      }
                      className={Styles.avatar}
                    />
                    <div className={Styles.contentWrapper}>
                      <div className={Styles.contentHeader}>
                        <p>Detail Siswa</p>
                      </div>
                      <div className={Styles.content}>
                        <div className={Styles.labelWrapper}>
                          <p className={Styles.label}>Nama Siswa</p>
                          <p className={Styles.value}>{studentData?.name}</p>
                        </div>
                        <div className={Styles.divider} />
                        <div className={Styles.labelWrapper}>
                          <p className={Styles.label}>NISN</p>
                          <p className={Styles.value}>
                            {studentData?.idNumber}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={Styles.divider} />
              </React.Fragment>
            )}
            {template}
            <div>
              {error && (
                <div className={Styles.errorText}>
                  <p>*{error}</p>
                </div>
              )}
              <div className={Styles.contentActions}>
                <Button
                  text={"Kembali"}
                  variant="text"
                  startIcon={"chevron-left"}
                  iconSize={"10px"}
                  disabled={activeStep === 1}
                  onClick={() => setActiveStep(activeStep - 1)}
                />
                <Button
                  text={activeStep === 3 ? "Terbitkan Rapor" : "Selanjutnya"}
                  disabled={loading}
                  endIcon={"chevron-right"}
                  iconSize={"10px"}
                  onClick={handleNext}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal isOpen={successUpload}>
        <FileUploadSuccess
          status="success"
          text={`Rapor Siswa ${studentData?.name} Telah Berhasil Diterbitkan`}
          onClose={() => navigate(-1)}
          headText="Rapor Berhasil Diterbitkan"
        />
      </Modal>
    </div>
  );
}

const AssessmentCard = ({ data, updateAssessment }) => {
  const handleUpdate = (itemsIndex, subElementIndex, value) => {
    const updatedData = { ...data };
    updatedData["items"][itemsIndex]["questions"][subElementIndex]["option"] =
      value;
    updateAssessment(updatedData);
  };

  const handleProcessNotesUpdate = (value) => {
    updateAssessment({ ...data, remark: value });
  };

  return (
    <div className={Styles.assessmentCard}>
      <p>{data.type}</p>

      {data.items.map((element, itemsIndex) => (
        <div key={itemsIndex} className={Styles.achievementElementWrapper}>
          <p>
            {itemsIndex + 1}. {element.el}{" "}
            <span style={{ color: "red" }}>*</span>
          </p>

          {element.questions.map((subElement, questionsIdx) => (
            <div key={questionsIdx} className={Styles.achievementElement}>
              <p>{subElement.label}</p>

              <div className={Styles.optionWrapper}>
                {achievementOptions.map((option, optionIndex) => (
                  <div
                    key={optionIndex}
                    className={Styles.option}
                    onClick={() =>
                      handleUpdate(itemsIndex, questionsIdx, option)
                    }
                  >
                    <RadioButton isSelected={subElement.option === option} />
                    <p>{option}</p>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      ))}

      <div className={Styles.inputContainer}>
        <div className={Styles.inputWrapper}>
          <div>
            <p className={Styles.inputLabel}>
              Catatan Proses <span style={{ color: "red" }}>*</span>
            </p>
          </div>
          <Input
            isTextArea
            value={data.remark}
            setValue={(e) => handleProcessNotesUpdate(e)}
          />
        </div>
      </div>
    </div>
  );
};

const StudyContinuationCard = ({ data, setStudyContinuation }) => {
  const handleUpdate = (itemsIndex, value) => {
    const updatedData = [...data];
    updatedData[itemsIndex]["option"] = value;
    setStudyContinuation(updatedData);
  };

  return (
    <div className={Styles.assessmentCard}>
      <p>{data.type}</p>
      {data.map((element, itemsIndex) => (
        <div key={itemsIndex} className={Styles.achievementElementWrapper}>
          <p>
            {itemsIndex + 1}. {element.question}
            <span style={{ color: "red" }}>*</span>
          </p>
          <div className={Styles.achievementElement}>
            <div className={Styles.optionWrapper}>
              {["Ya", "Tidak"].map((option, optionIndex) => (
                <div
                  key={optionIndex}
                  className={Styles.option}
                  onClick={() => handleUpdate(itemsIndex, option)}
                >
                  <RadioButton isSelected={element.option === option} />
                  <p>{option}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
