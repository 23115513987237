import React from "react";
import Styles from "./style.module.scss";
import AccordionAdmin from "@Molecule/AccordionAdmin";
import { fileBaseUrl } from "@Config/index";
import Icon from "@Atom/Icon";
import { useNavigate } from "react-router-dom";
import QuizCard from "@Atom/QuizCard";

const convertToMb = (toMb) => (toMb / (1024 * 1024)).toFixed(1);

const materialTypeFormat = (type) => {
  switch (type) {
    case "MATERIAL":
      return "Materi";
    case "AST":
      return "Asesmen Sumatif Teori";
    case "ABP":
      return "Asesmen Berbasis Projek";
    default:
      return "QUIZ";
  }
};

export default function AdminDetailClass({ data }) {
  const navigate = useNavigate();
  const classDetailList = [
    {
      label: "Level",
      value: data?.grades,
    },
    {
      label: "Platform",
      value: data?.platform,
    },
    {
      label: "Tahun Ajaran",
      value: data?.academicYear,
    },
    {
      label: "Kode Kelas",
      value: data?.code,
      withStartIcon: true,
    },
  ];

  return (
    <div className={Styles.container}>
      <div className={Styles.classHeader}>
        <div className={Styles.thumbnail}>
          <img
            src={
              data?.asset.thumbnail.url.includes("https")
                ? data?.asset.thumbnail.url
                : fileBaseUrl + data?.asset.thumbnail.url
            }
            alt="thumbnail"
          />
        </div>
        <div className={Styles.classDetailCard}>
          <div className={Styles.cardHead}>
            <div className={Styles.title}>
              <p>{data?.subjectName}</p>
            </div>
            <div className={Styles.settings}>
              <div>
                <div className={Styles.status}>
                  <p>Aktif</p>
                </div>
              </div>
              <Icon
                icon={"setting-21"}
                size={20}
                onClick={() =>
                  navigate(
                    `/classes/edit/${data?.platform.toLowerCase()}/${data?._id}`
                  )
                }
              />
            </div>
          </div>
          <div className={Styles.divider} />
          <div className={Styles.cardBody}>
            {classDetailList.map((el, idx) => (
              <div className={Styles.label} key={idx}>
                <p>{el.label}</p>
                {el.withStartIcon ? (
                  <div className={Styles.labelWithIcon}>
                    <p>{el.value}</p>
                    <Icon
                      icon={"copy"}
                      size={16}
                      color={"#9360A8"}
                      onClick={() =>
                        navigator.clipboard.writeText(el.value)
                      }
                    />
                  </div>
                ) : (
                  <p>{el.value}</p>
                )}
              </div>
            ))}
          </div>
          {/* <div className={Styles.cardBody}>
            {classDetailList.slice(3).map((el, idx) => (
              <div className={Styles.label} key={idx}>
                <p>{el.label}</p>
                <p>{el.value}</p>
              </div>
            ))}
          </div> */}
        </div>
      </div>
      <div className={Styles.section}>
        <p>Tujuan Pembelajaran</p>
        <p>{data?.generalPurpose}</p>
      </div>
      <div className={Styles.section}>
        <p>Aturan Kelas</p>
        <div
          dangerouslySetInnerHTML={{ __html: data?.regulationHTML|| data?.regulation.regulationHTML }}
          className={Styles.classRules}
        />
      </div>
      <div className={Styles.section}>
        <p>Kurikulum</p>
        <div className={Styles.accordionList}>
          {data?.sessions.map((el, idx) => (
            <div key={idx}>
              <AccordionAdmin
                head={
                  <div className={Styles.accordionHead}>
                    <div>
                      <Icon icon={"book2"} size={20} color={"#9360A8"} />
                    </div>
                    <p>Bagian {idx + 1}</p>
                  </div>
                }
                body={
                  <div className={Styles.sessionBody} key={idx}>
                    <div className={Styles.divider} />
                    <div className={Styles.sessionContainer}>
                      <div className={Styles.sessionDesc}>
                        <div className={Styles.labelDesc}>
                          <p>Judul Sesi</p>
                          <p>:</p>
                          <p>{el.title}</p>
                        </div>
                        <div className={Styles.labelDesc}>
                          <p>Deskripsi</p>
                          <p>:</p>
                          <p>{el.description}</p>
                        </div>
                      </div>
                      {el.materials.map((subject, subIdx) => (
                        <div key={subIdx}>
                          <div className={Styles.divider} />

                          {subject.materialType === "MATERIAL" && (
                            <div className={Styles.subject}>
                              <p>
                                {materialTypeFormat(subject.materialType)}{" "}
                                {/* {subIdx + 1} */}
                              </p>
                              <div className={Styles.matterDetail}>
                                <div className={Styles.labelDesc}>
                                  <p>Judul Materi</p>
                                  <p>:</p>
                                  <p>{subject.title}</p>
                                </div>
                                <div className={Styles.labelDesc}>
                                  <p>Video materi {subIdx + 1}</p>
                                  <p>:</p>
                                  <div className={Styles.subjectFileDesc}>
                                    <Icon
                                      icon={"link-2"}
                                      size={20}
                                      color={"#9360A8"}
                                    />
                                    <div className={Styles.rightSection}>
                                      <p>Link Video</p>
                                      <p
                                        onClick={() =>
                                          window.open(
                                            subject.content.url.includes(
                                              "https"
                                            )
                                              ? subject.content.url
                                              : fileBaseUrl +
                                                  subject.content.url
                                          )
                                        }
                                        className={Styles.link}
                                      >
                                        {subject.content.url.includes("https")
                                          ? subject.content.url
                                          : fileBaseUrl + subject.content.url}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                {/* {subject?.popUpQuiz && (
                                  <div className={Styles.labelDesc}>
                                    <p>Pop Up Kuis</p>
                                    <p>:</p>
                                    <div className={Styles.subjectFileDesc}>
                                      <Icon
                                        icon={"link-2"}
                                        size={20}
                                        color={"#9360A8"}
                                      />
                                      <div className={Styles.rightSection}>
                                        <p>Pertanyaan</p>
                                        <div
                                          dangerouslySetInnerHTML={{
                                            __html:
                                              subject?.popUpQuiz?.contentHTML,
                                          }}
                                        ></div>
                                      </div>
                                    </div>
                                  </div>
                                )} */}
                              </div>
                            </div>
                          )}
                          {subject.materialType === "ABP" && (
                            <div className={Styles.subject}>
                              <p>{materialTypeFormat(subject.materialType)}</p>
                              <div className={Styles.matterDetail}>
                                <div className={Styles.labelDesc}>
                                  <p>Panduan asesmen</p>
                                  <p>:</p>
                                  <div className={Styles.subjectFile}>
                                    <Icon icon={"file-pdf-box"} size={20} />
                                    <div className={Styles.rightSection}>
                                      <p>{subject.content.url}</p>
                                      <p>
                                        {convertToMb(
                                          subject.content.contentSize
                                        )}
                                        Mb
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className={Styles.labelDesc}>
                                  <p>Deskripsi</p>
                                  <p>:</p>
                                  <p>{subject.description}</p>
                                </div>
                              </div>
                            </div>
                          )}
                          {subject.materialType === "AST" && (
                            <div className={Styles.subject}>
                              <p>
                                {materialTypeFormat(subject.materialType)}{" "}
                                {/* {subIdx + 1} */}
                              </p>
                              <QuizCard
                                data={{
                                  questions: subject.quizzes.map((quiz) => ({
                                    ...quiz,
                                    question: quiz.contentHTML,
                                    image: quiz.contentIMG,
                                    options: quiz?.options?.map((option) => ({
                                      image: option.optionIMG,
                                      option: option.optionValue,
                                      isCorrect: option.isCorrect,
                                    })),
                                  })),
                                }}
                              />
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                }
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
