import AdminStudentClassDetail from "@Organism/Admin/StudentDetail";
import {fetchStudentDetail } from "@Services/admin";
import React, { Suspense } from "react";
import { Await, defer, useLoaderData } from "react-router-dom";

export async function loader({params}) {
  return defer({
    getStudentDetail : fetchStudentDetail(params.studentId),
  });
}

export default function AdminStudentDetailPage() {
  const { getStudentDetail } = useLoaderData();
  //   console.log(getStudentDetail);

  return (
    <Suspense
      fallback={
        <AdminStudentClassDetail data={[]} isLoading />
      }
    >
      <Await
        resolve={getStudentDetail}
        errorElement={
          <div style={{ height: "100vh" }}>
            <p>error...</p>
          </div>
        }
      >
        {(value) => <AdminStudentClassDetail data={value} />}
      </Await>
    </Suspense>
  );
}
