import bcaIcon from "@Assets/Images/bca.png";
import documentUpload from "@Assets/Images/document-upload.png";
import exampleImage from "@Assets/Images/exampleImageClass.png";
import iconPrakerja from "@Assets/Images/prakerja.png";
import DoodleGradientCard from "@Atom/DoodleGradientCard";
import Icon from "@Atom/Icon";
import Modal from "@Atom/Modal";
import TabHeader from "@Atom/TabHeader";
import { useRef, useState } from "react";
import Styles from "./style.module.scss";
import { useTimeDeadlineTransaction } from "@Hooks/timeDeadlineTransaction";
import moment from "moment";
import { decryptStorageData } from "@Helpers/encryptDecrypt";

export default function Transaction({
  onClick = () => {},
  titleClass = "Merancang Strategi Branding untuk Spesialisasi dan Pemasaran Digital Diera Globalisasi dan Tech Winter",
  typeClass = "Webinar",
  level = "Beginner",
  isPrakerja = true,
  image = exampleImage,
  typePayment = "IHB918DBHUSAD8",
  isVoucher = true,
  datePayment = "2024-01-11",
  invoice = "INV/122023/SKILLS/00000048",
  isPending = false,
  date = "2024-02-24",
  paymentMethode = "Transfer bank",
  discount = 0.1,
  time = "23:09:40",
  // value,
  price = 1250000,
}) {
  const [tabs, setTabs] = useState(1);
  const tabOptions = [
    "Transaksi Berhasil",
    "Menunggu Pembayaran",
    "Transaksi Tidak Berhasil",
  ];
  const [isOpen, setIsOpen] = useState(false);
  const handleOpenModal = () => {
    setIsOpen(!isOpen);
  };
  const ref = useRef();
  const [imagePayment, setImagePayment] = useState();
  const handlePreviewImage = (event) => {
    const selectedPreview = event.target.files[0];
    if (selectedPreview) {
      setImagePayment(URL.createObjectURL(selectedPreview));
    } else {
      setImagePayment(null);
    }
  };
  const totalDiscount = price * discount;
  const totalPrice = price - totalDiscount;
  const { timeLeft } = useTimeDeadlineTransaction("Fri Mar 08 2024 19:20:26");
  // console.log("transaksi di batalkan :", isTimeUp);
  const user = decryptStorageData("user", localStorage);
  return (
    <div>
      <div className={Styles.layout}>
        <section className={Styles.doodle}>
          <DoodleGradientCard>
            <div className={Styles.topCard}>
              <div className={Styles.leftSection}>
                <div>
                  <p className={Styles.title}>
                    Selamat Datang <span>{user?.name}</span>
                  </p>
                  <p className={Styles.desc}>
                    Berikut daftar transaksimu di Skills.id
                  </p>
                </div>
              </div>
            </div>
          </DoodleGradientCard>
        </section>
        <section className={Styles.tabsHeader}>
          <TabHeader tabs={tabs} setTabs={setTabs} tabOptions={tabOptions} />
          <div className={Styles.wrapperCard}>
            {[1, 2, 3, 4].map((_, idx) => (
              <div className={Styles.card}>
                <div className={Styles.invoice}>
                  <p>{invoice}</p>
                </div>
                <img src={image} className={Styles.images} alt="images" />
                <div className={Styles.describeClass}>
                  <div className={Styles.classDescription}>
                    <div className={Styles.text}>
                      <div className={Styles.typeClass}>
                        <div>
                          <span>{level}</span>
                        </div>
                        <div>
                          <span>{typeClass}</span>
                        </div>
                        {isPrakerja && (
                          <img
                            src={iconPrakerja}
                            alt="prakerja"
                            width={"43.269px"}
                            height={"13.823px"}
                          />
                        )}
                      </div>
                      <div className={Styles.textEllipsis}>
                        <p>{titleClass}</p>
                      </div>
                      <p>
                        Rp.{" " + totalPrice.toLocaleString("id")}
                        {" | "}{" "}
                        {isVoucher ? "Voucher : " + typePayment : typePayment}{" "}
                      </p>
                    </div>
                    <div
                      className={Styles.buttonCard}
                      isPending={tabs === 2 && isPending.toString()}
                      onClick={
                        isPending
                          ? undefined
                          : isPending === false && tabs === 2
                          ? handleOpenModal
                          : onClick
                      }
                      tabs={tabs === 2 ? "2" : tabs === 3 ? "3" : ""}
                    >
                      {tabs === 1 ? (
                        <span>
                          {" "}
                          {isVoucher ? "Ditukar " : "Terbayar "}Pada{" "}
                          {moment(datePayment).format("DD MMM YYYY")}
                        </span>
                      ) : tabs === 2 ? (
                        <span>
                          {" "}
                          {isPending
                            ? "Sedang Dalam Proses Verifikasi"
                            : "Bayar Sekarang"}{" "}
                        </span>
                      ) : (
                        <span>Transaksi Dibatalkan</span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </section>
        <Modal isOpen={isOpen}>
          <div style={{ paddingBlock: "90px 20px" }}>
            <div className={Styles.containerPayment}>
              <div className={Styles.relative}>
                <div className={Styles.invoice}>
                  <h1>{invoice}</h1>
                  <span>•</span>
                  <p className={Styles.date}>
                    {moment(date).format("MMMM Do, YYYY")}
                  </p>
                  <p className={Styles.time}>
                    {timeLeft.hours}:{timeLeft.minutes}:{timeLeft.seconds}
                  </p>
                  <Icon
                    icon={"cross"}
                    color={"#232323"}
                    size={"24"}
                    className={Styles.iconClose}
                    onClick={handleOpenModal}
                  />
                </div>
                <div className={Styles.detailPayment}>
                  <h1>Detail Pembayaran</h1>
                  <div className={Styles.titleClass}>
                    <div>{titleClass}</div>
                    <p>Rp {price.toLocaleString("id")}</p>
                  </div>
                  <div className={Styles.discount}>
                    <p>
                      Discount <span>({(discount * 100).toFixed()}%)</span>{" "}
                    </p>
                    <p>-Rp {totalDiscount.toLocaleString("id")}</p>
                  </div>
                  <div className={Styles.total}>
                    <div>
                      <h1>Total:</h1>
                      <h1>Rp {totalPrice.toLocaleString("id")}</h1>
                    </div>
                  </div>
                </div>
                <div className={Styles.lineSeperator}></div>
                <div className={Styles.paymentMethode}>
                  <div>
                    Metode Pembayaran : <span>{paymentMethode}</span>
                  </div>
                  <div className={Styles.typeMethod}>
                    <img src={bcaIcon} alt="" />
                    <span>•</span>

                    <div>
                      <span>Transfer Ke Rekening BCA</span>
                      <span>3790801293 - A.N Skills.ID</span>
                    </div>
                  </div>
                </div>
                <div className={Styles.upload}>
                  <h1>Unggah Bukti Pembayaran</h1>
                  <input
                    type="file"
                    ref={ref}
                    hidden
                    onChange={handlePreviewImage}
                    accept=".png,.jpg,.jpeg"
                  />
                  {imagePayment ? (
                    <div className={Styles.fileUpload} isChoose={"true"}>
                      <img
                        src={imagePayment}
                        onClick={() => ref.current?.click()}
                        alt="payment-images"
                      />
                    </div>
                  ) : (
                    <div className={Styles.fileUpload}>
                      <img src={documentUpload} alt="" />
                      <h1>Unggah bukti pembayaran Anda disini</h1>
                      <div
                        className={Styles.button}
                        onClick={() => ref.current?.click()}
                      >
                        <label>Pilih File</label>
                      </div>
                    </div>
                  )}
                </div>
                <div className={Styles.buttonCard} onClick={onClick}>
                  <span>Simpan</span>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
      {/* <Footer /> */}
    </div>
  );
}
