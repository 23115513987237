import React, { useEffect, useMemo, useState } from "react";
import Styles from "./style.module.scss";
import Breadcrumbs from "@Atom/Breadcrumbs";
import Button from "@Atom/Button";
import Searchbar from "@Atom/Searchbar";
import Table from "@Molecule/Table";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDebounce } from "@Hooks/useDebounce";
import useQuery from "@Hooks/useQuery";

const legend = [
  {
    type: "Sudah Dinilai",
    color: "#60AA15",
  },
  {
    type: "Belum Dinilai",
    color: "#B22A12",
  },
  {
    type: "Belum Tersedia",
    color: "#C2C2C2",
  },
];

export default function AssessmentDetail({ data, isLoading,classData }) {
  const query = useQuery();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(5);
  const [table, setTable] = useState(0);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const pageQuery = query?.get("page");
  const limitQuery = query?.get("limit");
  const searchQuery = query?.get("search");
  const classSubject = query?.get("classSubject") || "";
  const debounceSearch = useDebounce(search, 500);
  const abpCount = data?.data[0]?.ABP || [];
  const astCount = data?.data[0]?.AST || [];
  const {sessionNum} = useParams()

  useEffect(() => {
    setPage(+pageQuery || 1);
    setLimit(+limitQuery || 10);
    setSearch(searchQuery || "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limitQuery, pageQuery, searchQuery]);

  useEffect(() => {
    if (debounceSearch?.length > 1) {
      setLoading(true);
    }
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, [debounceSearch]);

  useEffect(() => {
    navigate(
      `${pathname}?s=1&limit=${limit}&page=${page}&search=${debounceSearch}&classSubject=${classSubject}`,
      {
        replace: true,
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounceSearch, limit, page]);

  const template = useMemo(() => {
    return {
      data: data?.data,
      columns: [
        {
          name: "name",
          title: "Nama Murid",
          renderData: (row) => (
            <span
              style={{ cursor: "pointer" }}
              onClick={() =>
                navigate(`ast/${row._id}`, { state: { name: row.user.name } })
              }
            >
              {row.user.name}
            </span>
          ),
        },
        ...astCount.map((el, idx) => ({
          name: "",
          title: "AST " + (idx + 1),
          renderData: (row) => (
            <div
              className={Styles.scoreTag}
              status={
                row.AST[idx].status === "empty"
                  ? "none"
                  : row.AST[idx].status === "filled"
                  ? "false"
                  : "true"
              }
            >
              <div>
                <p>{row?.AST[idx].materialScore || "100"}</p>
              </div>
            </div>
          ),
          alignItems: "center",
        })),
      ],
      columns1: [
        {
          name: "name",
          title: "Nama Murid",
          renderData: (row) => (
            <span
              style={{ cursor: "pointer" }}
              onClick={() =>
                navigate(`abp/${row._id}`, { state: { name: row.user.name } })
              }
            >
              {row.user.name}
            </span>
          ),
        },
        ...abpCount.map((el, idx) => ({
          name: "",
          title: "ABP " + (idx + 1),
          renderData: (row) => (
            <div
              className={Styles.scoreTag}
              status={
                row.ABP[idx].answers.length < 1
                  ? "none"
                  : row.ABP[idx].materialScore === -1
                  ? "false"
                  : "true"
              }
            >
              <div>
                <p>{row?.ABP[idx].materialScore || "100"}</p>
              </div>
            </div>
          ),
        })),
      ],
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const tableTab = useMemo(() => {
    return [
      {
        type: 0,
        text: "Asesmen Sumatif Teori (AST)",
      },
      {
        type: 1,
        text: "Asesmen Berbasis Proyek (ABP)",
      },
    ];
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);



  return (
    <div className={Styles.container}>
      <Breadcrumbs
        data={[
          { title: "Mata Pelajaran" },
          { title: "Detail Mata Pelajaran" },
          { title: classSubject.toLowerCase() },
          { title: "Penilaian Tugas Bagian " + sessionNum },
        ]}
      />
      <div className={Styles.content}>
        <div className={Styles.mainTable}>
          <div className={Styles.tableTabWrapper}>
            {tableTab.map((el, idx) => (
              <Button
                variant="table-tab"
                text={el.text}
                isActive={el.type === table}
                onClick={() => setTable(el.type)}
                key={idx}
              />
            ))}
          </div>
          <div className={Styles.searchbarWrapper}>
            <Searchbar
              placeholder="Cari Murid"
              value={search}
              setValue={setSearch}
            />
          </div>
          <div className={Styles.tableSection}>
            <div className={Styles.legendWrapper}>
              {legend.map((el, idx) => (
                <div className={Styles.legend} key={idx}>
                  <div
                    className={Styles.icon}
                    style={{ backgroundColor: el.color }}
                  />
                  <p>{el.type}</p>
                </div>
              ))}
            </div>
          </div>
          <Table
            data={template?.data}
            totalData={data?.totalData}
            totalPage={Math.ceil(data?.totalData / limit)}
            columns={table ? template?.columns1 : template?.columns}
            page={page}
            setPage={setPage}
            className={`${Styles.table} ${
              !template?.data?.length ? Styles.grow : ""
            }`}
            limit={limit}
            setLimit={setLimit}
            withPagination
            withNumbering
            isLoading={isLoading || loading}
          />
        </div>
      </div>
    </div>
  );
}
