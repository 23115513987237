import React from "react";
import Styles from "./style.module.scss";
import { useNavigate } from "react-router-dom";
import { fileBaseUrl } from "@Config/index";

export default function ClassProgressCard({ data }) {
  const navigate = useNavigate();
  const countPercentage = () => {
    let percentage = Math.round(
      (data.completedMaterials / data.allMaterials) * 100
    );
    if (percentage > 100) {
      percentage = 100;
    }
    return percentage;
  };

  return (
    <div className={Styles.classCard}>
      <div className={Styles.cardImage}>
        <div className={Styles.imageOverlay}>
          <p>{data.subjectName}</p>
        </div>
        <img src={fileBaseUrl + data.thumbnail} alt="" />
      </div>
      <div className={Styles.cardBody}>
        <div className={Styles.describeClass}>
          <div className={Styles.percentage}>
            <div percentage={countPercentage()} className={Styles.indicator}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="53"
                height="53"
                viewBox="0 0 53 54"
                fill="none"
              >
                <circle
                  cx="26.5"
                  cy="27"
                  r="25"
                  stroke="white"
                  strokeWidth="4"
                  fill="none"
                />
                <circle
                  cx="26.5"
                  cy="27"
                  r="25"
                  stroke="#c2d6fc"
                  strokeWidth="4"
                  fill="none"
                  strokeDasharray="157 157"
                  strokeDashoffset={157 * (1 - countPercentage() / 100)}
                  transform="rotate(-90 26.5 27)"
                />
              </svg>
            </div>
            <div>
              <p>{`${data.completedMaterials} / ${data.allMaterials}`}</p>
              <p>Materi Selesai</p>
            </div>
          </div>
          <div className={Styles.classDescription}>
            <div className={Styles.text}>
              <div className={Styles.typeClass}>
                <div>
                  <span>{data.academicYear}</span>
                </div>
              </div>
              <div className={Styles.textEllipsis}>
                <p>{data.subjectName}</p>
              </div>
              <p>{data.teacher.name}</p>
            </div>
            <div
              className={Styles.buttonCard}
              onClick={() => navigate(`/classes/learn/${data._id}/rules`)}
            >
              <span> Lanjutkan Belajar</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
