import TeacherListLayout from "@Organism/Admin/TeacherList";
import { fetchAllTeacher } from "@Services/admin";
import React, { Suspense } from "react";
import { Await, defer, useLoaderData } from "react-router-dom";

export async function loader(route) {
  const params = new URLSearchParams(route?.request?.url);
  const page = params?.get("page") || 1;
  const limit = params?.get("limit") || 10;
  const search = params?.get("search") || "";
  const getAllTeacher = fetchAllTeacher(
    `page=${page}&limit=${limit}&search=${search}`
  );
  return defer({
    getAllTeacher,
  });
}

export default function TeacherListPage() {
  const { getAllTeacher } = useLoaderData();
  //   console.log(getAllStudent);

  return (
    <Suspense
      fallback={
        <TeacherListLayout data={{ data: [], totalData: 0 }} loading />
      }
    >
      <Await
        resolve={getAllTeacher}
        errorElement={
          <div style={{ height: "100vh" }}>
            <p>error...</p>
          </div>
        }
      >
        {(value) => <TeacherListLayout loading={false} data={value} />}
      </Await>
    </Suspense>
  );
}
